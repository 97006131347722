import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { Button, Label, Row, Col } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getUser, getRoles, updateUser, createUser, reset } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { ROLES } from 'app/config/constants';
import Select from "react-dropdown-select";
import { IUser } from 'app/shared/model/user.model';
import { toast } from 'react-toastify';

export interface IUserManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> { }

export const UserManagementCreate = (props: IUserManagementUpdateProps) => {
  const [selected, setSelected] = useState([]);
  const { user, loading, updating, roles } = props;
  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/admin/user-management');
    }
  }, [props.updateSuccess])
  const handleSaveUser = (event, values) => {
    if (values.password !== values.password_confirm) {
      toast.error("Mật khẩu không trùng nhau");
      return;
    }
    const userCreate: IUser = {
      login: values.login,
      first_name: values.first_name,
      last_name: values.last_name,
      activated: values.activated,
      authorities: selected,
      email: values.email,
      lang_key: null,
      password: values.password
    }
    props.createUser(userCreate);
  };
  const handleOnclick = (selecteds) => {
    const listitem = [];
    selecteds.map((data, key) => {
      if (data.value !== "ROLE_ADMIN") {
        listitem.push(data.value)
      }
    });
    if (user.authorities.includes("ROLE_ADMIN")) {
      listitem.push("ROLE_ADMIN")
    }
    setSelected(listitem)
  }
  const handleValueDefaultDropdown = () => {
    const values = []
    ROLES.map((role: any) => {
      if (user.authorities && user.authorities.includes(role.value)) {
        values.push(role)
      }
    })
    return values;
  }
  const contentRenderer = ({ state }) => {
    return (
      <div>
        {state.values.length === 0 ? "Không quyền nào được chọn" : `${state.values.length} quyền được chọn`}
      </div>
    );
  };
  const isInvalid = false;
  
  return (
    <div>
      <p><Link to="/admin/user-management"> {`< Quay về trang danh sách`}</Link></p>
      <div className="update-create-user-box">
      <div className="title-header">
          <h4>Thêm mới người dùng</h4>
      </div>
        <div className="form-user-create">
          <AvForm onValidSubmit={handleSaveUser}>
            <AvGroup>
              <Label for="login">Tài khoản</Label><Label className="star">*</Label>
              <AvField
                type="text"
                className="form-control"
                name="login"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Tài khoản không được để trống"
                  },
                  maxLength: {
                    value: 50,
                    errorMessage: 'số ký tự phải nhỏ hơn 50.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="last_name">Họ</Label>
              <AvField
                type="text"
                className="form-control"
                name="last_name"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: 'số ký tự phải nhỏ hơn 50.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="first_name">Tên</Label>
              <AvField
                type="text"
                className="form-control"
                name="first_name"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: 'số ký tự phải nhỏ hơn 50.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="first_name">Email</Label><Label className="star">*</Label>
              <AvField
                type="email"
                className="form-control"
                name="email"
                errorMessage="Vui lòng nhập đúng định dạng email"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Email không được để trống"
                  },
                  minLength: {
                    value: 5,
                    errorMessage: "Số ký tự phải lớn hơn hoặc bằng 5"
                  },
                  maxLength: {
                    value: 254,
                    errorMessage: 'Số ký tự phải nhỏ hơn 254.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup check>
              <Label>
                  <AvInput type="checkbox" name="activated" /> Kích hoạt
              </Label>
            </AvGroup>
            <AvGroup>
              <Label for="password">Mật khẩu</Label><Label className="star">*</Label>
              <AvField
                type="password"
                className="form-control"
                name="password"
                autoComplete="new-password"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Mật khẩu không được để trống"
                  },
                  minLength: {
                    value: 4,
                    errorMessage: 'Mật khẩu phải lớn hơn hoặc bằng 4 ký tự.'
                  },
                  maxLength: {
                    value: 100,
                    errorMessage: 'Mật khẩu phải nhỏ hơn 100 ký tự.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="password_confirm">Xác nhận Mật khẩu</Label><Label className="star">*</Label>
              <AvField
                type="password"
                className="form-control"
                name="password_confirm"
                validate={{
                  required: {
                    value: true,
                    errorMessage: "Mật khẩu không được để trống"
                  },
                  minLength: {
                    value: 4,
                    errorMessage: 'Mật khẩu phải lớn hơn hoặc bằng 4 ký tự.'
                  },
                  maxLength: {
                    value: 100,
                    errorMessage: 'Mật khẩu phải nhỏ hơn 100 ký tự.'
                  },
                  match: {
                    value: 'password',
                    errorMessage: 'Mật khẩu bạn vừa nhập không khớp'
                  }
                }}
              />
            </AvGroup>
            <AvGroup className="form-group" >
              <Label for="authorities">Phân quyền</Label>
              <Select
                values={selected}
                options={ROLES}
                multi={true}
                labelField={"value"}
                onChange={handleOnclick}
                placeholder="Chọn ít nhất một quyền"
                contentRenderer={contentRenderer}
              />
            </AvGroup>
            <div className="btn-pl">
              <Button color="primary" type="submit" disabled={isInvalid || updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp; Lưu
              </Button>
            </div>
          </AvForm>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  roles: storeState.userManagement.authorities,
  loading: storeState.userManagement.loading,
  updating: storeState.userManagement.updating,
  updateSuccess: storeState.userManagement.updateSuccess,
});

const mapDispatchToProps = { getUser, getRoles, updateUser, createUser, reset };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementCreate);
