import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMinus } from '@fortawesome/free-solid-svg-icons';
import './checkbox.scss';
import classNames from 'classnames';

export enum CHECKBOX_MODE {
  All,
  SEMI
}

interface CheckboxProps {
  itemId: string | number;
  label?: string;
  labelHtml?: boolean;
  checked?: boolean;
  mode?: CHECKBOX_MODE;
  onChange?: any;
  onClick?: any;
  className?: string;
}
export default function Checkbox(props: CheckboxProps) {
  const { itemId, label, labelHtml, checked, mode = CHECKBOX_MODE.All, onChange = () => {}, onClick = () => {}, className } = props;
  const checkboxClasses = classNames('sapo-noti-checkbox', className);
  return (
    <div className={checkboxClasses}>
      <label onClick={onClick}>
        <input type="checkbox" id={itemId + ''} onChange={() => onChange(itemId)} checked={checked}></input>
        <span className="checkmark">
          {mode === CHECKBOX_MODE.All ? (
            <FontAwesomeIcon icon={faCheck} className="check" size="1x" />
          ) : (
            <FontAwesomeIcon icon={faMinus} className="minus" size="1x" />
          )}
        </span>
        {!labelHtml ? <span>{label}</span> : <span dangerouslySetInnerHTML={{ __html: label }} />}
      </label>
    </div>
  );
}
