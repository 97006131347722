import axios from 'axios';
import { getBasePath, Storage } from 'react-jhipster';

import { SERVER_API_URL, EXPIRED, AUTH_TOKEN_KEY } from 'app/config/constants';
import Axios from 'axios';

const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;
const URL_REFRESH = '/auth/refresh';
const instanceAxios = Axios.create();
const setupAxiosInterceptors = onUnauthenticated => {
  const onRequestSuccess = config => {
    const token = Storage.local.get(AUTH_TOKEN_KEY) || Storage.session.get(AUTH_TOKEN_KEY);
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  };
  const onResponseSuccess = response => response;
  const onResponseError = err => {
    const status = err.status || (err.response ? err.response.status : 0);
    let refreshSuccess = false;
    if (status === 401 && !err.config._retry) {
      err.config._retry = true;
      return instanceAxios
        .post(URL_REFRESH)
        .then((result: any) => {
          refreshSuccess = true;
          Storage.local.set(AUTH_TOKEN_KEY, result.data.access_token);
          return axios(err.config);
        })
        .catch(function(error) {
          if (!refreshSuccess) {
            onUnauthenticated();
            return Promise.reject(err);
          }
          return Promise.reject(error);
        });
    }
    return Promise.reject(err);
  };
  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;
