import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction, translate } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { Itemplate } from 'app/shared/model/template.model';

export const ACTION_TYPES = {
  FETCH_TEMPLATE: 'template/FETCH_TEMPLATE',
  FETCH_TEMPLATES: 'template/FETCH_TEMPLATES',
  CREATE_TEMPLATE: 'template/CREATE_TEMPLATE',
  UPDATE_TEMPLATE: 'template/UPDATE_TEMPLATE',
  DELETE_TEMPLATE: 'template/DELETE_TEMPLATE',
  RESET: 'template/RESET',
  CHANGESTATEBOX: 'template/CHANGESTATEBOX',
  REDIRECT: 'template/REDIRECT',
  COUNT_TEMPLATES: 'template/COUNT_TEMPLATES',
  ACTIVE_PAGE: 'template/ACTIVE_PAGE'
};

const initialState = {
  loading: false,
  errorMessage: null,
  templates: [] as ReadonlyArray<Itemplate>,
  authorities: [] as any[],
  template: {} as Readonly<Itemplate>,
  updating: false,
  updateSuccess: false,
  stateBox: false,
  totalItems: 0,
  activePage: 1
};
export type TemplateState = Readonly<typeof initialState>;
export default (state: TemplateState = initialState, action): TemplateState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_TEMPLATES):
    case REQUEST(ACTION_TYPES.FETCH_TEMPLATE):
    case REQUEST(ACTION_TYPES.CREATE_TEMPLATE):
    case REQUEST(ACTION_TYPES.UPDATE_TEMPLATE):
    case REQUEST(ACTION_TYPES.COUNT_TEMPLATES):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_TEMPLATE):
    case FAILURE(ACTION_TYPES.CREATE_TEMPLATE):
    case FAILURE(ACTION_TYPES.FETCH_TEMPLATE):
    case FAILURE(ACTION_TYPES.FETCH_TEMPLATES):
    case FAILURE(ACTION_TYPES.COUNT_TEMPLATES):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_TEMPLATES):
      return {
        ...state,
        loading: false,
        templates: action.payload.data.templates,
        template: null
      };
    case SUCCESS(ACTION_TYPES.FETCH_TEMPLATE):
      return {
        ...state,
        loading: false,
        template: action.payload.data.template
      };
    case SUCCESS(ACTION_TYPES.UPDATE_TEMPLATE):
    case SUCCESS(ACTION_TYPES.CREATE_TEMPLATE):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        template: action.payload.data.template
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.CHANGESTATEBOX:
      return {
        ...state,
        stateBox: action.payload
      };
    case SUCCESS(ACTION_TYPES.COUNT_TEMPLATES):
      return {
        ...state,
        totalItems: action.payload.data.count
      };
    case ACTION_TYPES.ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };
    default:
      return state;
  }
};
export const count = () => ({
  type: ACTION_TYPES.COUNT_TEMPLATES,
  payload: axios.get('api/templates/count?type=NOTI')
});
export const getTemplates = (page: number) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_TEMPLATES,
    payload: axios.get<Itemplate>(`/api/templates?page=${page}&type=NOTI`)
  });
  dispatch(count());
  return result;
};
export const createTemplate: ICrudPutAction<any> = template => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_TEMPLATE,
    payload: axios.post('/api/templates', { template }),
    meta: {
      successMessage: 'Lưu thành công'
    }
  });
  dispatch(getTemplates(1));
  return result;
};

export const getTemplate: ICrudDeleteAction<Itemplate> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_TEMPLATE,
    payload: axios.get<Itemplate>(`/api/templates/${id}`)
  });
  return result;
};
export const updateTemplate = (id, template) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_TEMPLATE,
    payload: axios.put(`api/templates?id=${id}&type=NOTI`, { template }),
    meta: {
      successMessage: 'Lưu thành công'
    }
  });
  return result;
};
export const deleteTemplate: ICrudDeleteAction<Itemplate> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_TEMPLATE,
    payload: axios.delete(`api/templates/${id}`),
    meta: {
      successMessage: 'Xóa thành công'
    }
  });
  dispatch(getTemplates(1));
  return result;
};
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
export const changeDisplayBox = (b: boolean) => ({
  type: ACTION_TYPES.CHANGESTATEBOX,
  payload: b
});
export const updatePageActive = page => ({
  type: ACTION_TYPES.ACTIVE_PAGE,
  payload: page
});
