const config = {
  VERSION: process.env.VERSION
};

export default config;

export const SERVER_API_URL = process.env.SERVER_API_URL;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
  CAMPAIGN_READ: 'ROLE_CAMPAIGN_READ',
  CAMPAIGN_WRITE: 'ROLE_CAMPAIGN_WRITE',
  CONTACT_READ: 'ROLE_CONTACT_READ',
  CONTACT_WRITE: 'ROLE_CONTACT_WRITE',
  INTERNAL_PARTNER: 'ROLE_INTERNAL_PARTNER',
  LISTENER_TOPIC_READ: 'ROLE_LISTENER_TOPIC_READ',
  LISTENER_TOPIC_WRITE: 'ROLE_LISTENER_TOPIC_WRITE',
  MOBILE_DEVICE_READ: 'ROLE_MOBILE_DEVICE_READ',
  MOBILE_DEVICE_WRITE: 'ROLE_MOBILE_DEVICE_WRITE',
  TEMPLATE_READ: 'ROLE_TEMPLATE_READ',
  TEMPLATE_WRITE: 'ROLE_TEMPLATE_WRITE'
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error'
};

export const STATUS_CAMPAIGN = {
  ACTIVE: 'ACTIVE',
  CLOSED: 'CLOSED'
};
export const APP_DATE_FORMAT = 'DD/MM/YYYY HH:mm';
export const APP_TIMESTAMP_FORMAT = 'DD/MM/YY HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'DD/MM/YYYY';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';

export const EXPIRED = 'expired_token';

export const AUTH_TOKEN_KEY = 'jhi-authenticationToken';
export const ROLES: Array<{}> = [
  { value: 'ROLE_ADMIN', disabled: 'true' },
  { value: 'ROLE_USER' },
  { value: 'ROLE_CAMPAIGN_READ' },
  { value: 'ROLE_CAMPAIGN_WRITE' },
  { value: 'ROLE_CONTACT_READ' },
  { value: 'ROLE_CONTACT_WRITE' },
  { value: 'ROLE_INTERNAL_PARTNER' },
  { value: 'ROLE_LISTENER_TOPIC_READ' },
  { value: 'ROLE_LISTENER_TOPIC_WRITE' },
  { value: 'ROLE_MOBILE_DEVICE_READ' },
  { value: 'ROLE_MOBILE_DEVICE_WRITE' },
  { value: 'ROLE_TEMPLATE_READ' },
  { value: 'ROLE_TEMPLATE_WRITE' },
  { value: 'ROLE_CAMPAIGN_ACTIVE' }
];
