import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { AvForm, AvField, AvGroup, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Col, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import React, { useState } from 'react';
import './create-script-fnb.scss';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
const breadcrumb: IBreadcrumbs = {
  link: '/admin/script-fnb',
  title: 'Quay lại danh sách kịch bản thông báo'
};

import { RouteComponentProps, useHistory } from 'react-router-dom';
import scriptNotiFnb from 'app/services/script-noti-fnb';
import notiFnb from '../../../services/noti-fnb';

interface IModel {
  campaign: IData;
}
export interface IData {
  actor_name?: string;
  data?: string;
  content?: number;
  category?: string;
  app?: string;
  screen_type?: string;
  target_screen?: string;
  type?: string;
  os?: string[];
}

interface CreateScriptFnbProps extends StateProps, RouteComponentProps<{}> {}

export function CreateScriptFnb() {
  const history = useHistory();

  const [modelValue] = useState<IData>({
    category: 'System',
    app: null,
    screen_type: null,
    target_screen: 'noti_default',
    os: ['pc', 'android', 'ios', 'website']
  });

  const buttons: IButton[] = [
    {
      text: 'Lưu',
      color: 'primary',
      type: 'submit'
    }
  ];

  const onSubmit = (event, values) => {
    let check = false;
    notiFnb
      .getContent(values.content_id)
      .then(re => {
        values.content = re.data.template.content;
        values.icon = re.data.template.thumb_url;
        values.creator = re.data.template.created_by;
        values.subject = re.data.template.subject;
        if (re.data.template.meta_fields) {
          const a = [];
          re.data.template.meta_fields.map(b => {
            if (b.meta_key === 'html_content') {
              b.data_type = 'html';
            } else {
              b.data_type = 'text';
            }

            if (b.meta_value) {
              a.push(b);
            }
          });
          values.data = a;
        }
        check = true;
      })
      .catch(e => {
        toast.error('Nội dung gửi không đúng');
      });
    values.type = 'all';

    const a: IModel = {
      campaign: values
    };
    const getErrorMessage = errorData => {
      let message = "";
      if (errorData.field_errors) {
        errorData.field_errors.forEach(fErr => {
          message = fErr.message;
        });
      }
      return message;
    };
    setTimeout(() => {
      if (check) {
        scriptNotiFnb.createScriptNoti(a).then(re => {
          toast.success('Tạo kịch bản thành công');
          history.push('/admin/script-fnb');
        }).catch(error => {
          if (error && error.response && error.response.data) {
            const message = getErrorMessage(error.response.data);
            if(message !== "" && message === "Template not found"){
              toast.error("Nội dung thông báo không tồn tại")
            }else{
              toast.error("Có lỗi xảy ra")
            }
          }
        });
      }
    }, 500);
  };

  return (
    <div className="update-box" id="create-script-fnb">
      <AvForm onValidSubmit={onSubmit} model={modelValue}>
        <SWHeader title="Thêm mới kịch bản thông báo" breadcrumb={breadcrumb} button={buttons} />
        <div className="form-discount-campaign">
          <div className="campaign-box-title">
            <AvGroup row>
              <Label sm={10}>
                Tên kịch bản <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="text"
                  name="name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tên kịch bản không được để trống.'
                    },
                    maxLength: {
                      value: 255,
                      errorMessage: 'Số ký tự không được vượt quá 255.'
                    }
                  }}
                />
              </Col>
            </AvGroup>
          </div>
          <div className="notification-box">
            <AvGroup row>
              <Label for="template_id" sm={10}>
                Nội dung gửi <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="number"
                  name="content_id"
                  onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Nội dung gửi không được để trống.'
                    },
                    min: {
                      value: 0,
                      errorMessage: 'Mã nội dung phải lớn hơn 0'
                    }
                  }}
                  id="template_id"
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Danh mục <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField type="select" className="form-control select" name="category" value="system">
                  <option value="system">System</option>
                  <option value="onboarding">Onboarding</option>
                  <option value="new">New</option>
                  <option value="advertise">Advertise</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                App nhận thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField type="select" className="form-control select" name="app" value="">
                  <option value="">Tất cả</option>
                  <option value="admin">App Admin</option>
                  <option value="staff">App Staff</option>
                  <option value="pos">App Pos</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Phạm vi hiển thị thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField type="select" className="form-control select" name="screen_type">
                  <option value="">Tất cả</option>
                  <option value="in_app">Danh sách thông báo </option>
                  <option value="system_ui">Thông báo hệ thống</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="target_screen" sm={10}>
                Màn hình chi tiết thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField type="select" className="form-control select" name="target_screen" value="noti_default">
                  <option value="noti_default">Noti default</option>
                  <option value="noti_detail">Noti detail</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvCheckboxGroup
              inline
              name="os"
              label={
                <div>
                  Hệ điều hành <span className="star">(*)</span>
                </div>
              }
            >
              <div className="d-flex justify-content-between">
                <AvCheckbox label="Android" value="android" />
                <AvCheckbox label="IOS" value="ios" />
                <AvCheckbox label="PC Windows" value="pc" />
                <AvCheckbox label="Website" value="website" />
              </div>
            </AvCheckboxGroup>
          </div>
        </div>
      </AvForm>
    </div>
  );
}

const mapStateToProps = (storeState: IRootState) => ({
  discountCampaign: storeState.discountCampaign.discountCampaign
});

type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps)(CreateScriptFnb);
