import { IRootState } from 'app/shared/reducers';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Alert, Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { activeCampaign, createDicountCampaign, getDiscountCampaign, reset } from './DiscountCampaign.reducer';
import './DiscountCampaign.scss';
export interface IDIscountCampaign extends StateProps, DispatchProps, RouteComponentProps<{}> {}
const DiscountCampaignUpdate = (props: IDIscountCampaign) => {
  const { discountCampaign } = props;
  const [displayBox, setDisplayBox] = useState<boolean>(false);
  const [restrictOperatingSystems, setRestrict] = useState(['IOS', 'ANDROID']);
  const [discountType, setDiscountType] = useState<string>('PERCENTAGE');
  const [, setContentSc] = useState<string>(null);
  const [modal, setModal] = useState(false);
  const [shippingProviders, setShippingProviders] = useState(
    new Set([
      'GHN Express',
      'Ninja Van',
      'BEST Express',
      'J&T Express',
      'DHL eCommerce',
      'Ship60',
      'Snappy Express (Shippo)',
      'Viettel Post'
    ])
  );
  const [selectAllProviders, setSelectAllProviders] = useState<boolean>(true);

  const [finalShippingProviders, setFinalShippingProviders] = useState(
    new Set([
      'GHN Express',
      'Ninja Van',
      'BEST Express',
      'J&T Express',
      'DHL eCommerce',
      'Ship60',
      'Snappy Express (Shippo)',
      'Viettel Post'
    ])
  );

  const saveShippingProviders = () => {
    setModal(!modal);
    setFinalShippingProviders(shippingProviders);
  };

  const cancelShippingProviders = () => {
    setModal(!modal);
    setShippingProviders(finalShippingProviders);
  };
  const toggle = () => {
    setModal(!modal);
    setShippingProviders(finalShippingProviders);
  };
  useEffect(() => {
    props.reset();
    return () => props.reset();
  }, []);
  useEffect(() => {
    if (props.updateSuccess) {
      props.history.push('/admin/discount-campaign');
    }
  }, [props.updateSuccess]);
  const handleDiscounttype = e => {
    setDiscountType(e.target.value);
  };

  const onClickDisplayChange = () => {
    setDisplayBox(!displayBox);
  };
  const handleSaveCampaign = (event, values) => {
    let priceRule = null;
    let discount = null;
    if (values.usage_limit && values.usage_in_hours && values.discount_values) {
      priceRule = {
        usage_limit: values.usage_limit,
        usage_in_hours: values.usage_in_hours,
        discount_type: values.discount_type,
        discount_value: values.discount_values,
        discount_maximum_amount: values.discount_maximum_amount,
        multiple_time_enabled: false,
        shipping_providers: selectAllProviders ? [] : [...finalShippingProviders]
      };
    }
    discount = {
      title: values.title,
      price_rule: priceRule,
      mobile_notification: {
        template_id: values.template_id,
        category: values.category,
        content_screen: values.content_screen === '' ? null : values.content_screen,
        restrict_app_ids: [values.restrict_app_ids],
        restrict_operating_systems: restrictOperatingSystems === [] ? null : restrictOperatingSystems,
        visibility: values.visibility
      }
    };

    props.createDicountCampaign(discount);
    // console.log(discount);
  };
  const handleCategoryCase = e => {
    switch (e.target.value) {
      case 'Gift':
        setContentSc('vouchers');
        break;
      case 'System':
        setContentSc('reports');
        break;
      default:
        return null;
    }
  };

  const handleSystemsChecked = e => {
    const listSystems = restrictOperatingSystems;
    if (e.target.checked && !listSystems.includes(e.target.name)) {
      listSystems.push(e.target.name);
    } else {
      const index = listSystems.findIndex(ch => ch === e.target.name);
      listSystems.splice(index, 1);
    }
    setRestrict(listSystems);
  };

  const chooseShippingProviders = evt => {
    setModal(true);
    return evt.key === 'e' && evt.preventDefault();
  };

  const changeSelectAllProviders = () => setSelectAllProviders(!selectAllProviders);

  const changeShippingProvider = provider => {
    const providers = new Set(shippingProviders);
    if (shippingProviders.has(provider)) {
      if (shippingProviders.size < 2) return;
      providers.delete(provider);
    } else {
      providers.add(provider);
    }
    setShippingProviders(providers);
  };

  const buttons: IButton[] = [
    {
      text: 'Lưu',
      color: 'primary',
      type: 'submit'
    }
  ];

  const breadcrumb: IBreadcrumbs = {
    link: '/admin/discount-campaign',
    title: 'Quay về trang danh sách'
  };

  return (
    <div className="active-box">
      <AvForm onValidSubmit={handleSaveCampaign}>
        <SWHeader title="Tạo kịch bản thông báo" breadcrumb={breadcrumb} button={buttons} />
        <div className="form-discount-campaign d-flex">
          <div className="box-campaign-left">
            <div className="notification-box">
              <AvGroup row>
                <Label for="name" sm={10}>
                  Tên kịch bản <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField
                    type="text"
                    name="title"
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Tên kịch bản không được để trống.'
                      },
                      maxLength: {
                        value: 255,
                        errorMessage: 'Số ký tự không được vượt quá 255.'
                      }
                    }}
                    value={discountCampaign.title}
                    id="title"
                  />
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="template_id" sm={10}>
                  Nội dung gửi <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField
                    type="number"
                    name="template_id"
                    onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                    validate={{
                      required: {
                        value: true,
                        errorMessage: 'Nội dung gửi không được để trống.'
                      },
                      min: {
                        value: 0,
                        errorMessage: 'Mã nội dung phải lớn hơn 0'
                      }
                    }}
                    id="template_id"
                  />
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="multiple_times_enabled" sm={12}>
                  Danh mục <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField
                    type="select"
                    onChange={handleCategoryCase}
                    className="form-control select"
                    id="category"
                    name="category"
                    value="System"
                  >
                    <option value="Onboarding">Onboarding</option>
                    <option value="System">System</option>
                    <option value="News">News</option>
                    <option value="Advertise">Advertise</option>
                    <option value="Gift">Gift</option>
                  </AvField>
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="multiple_times_enabled" sm={12}>
                  App nhận thông báo <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField type="select" className="form-control select" id="restrict_app_ids" name="restrict_app_ids" value="Sapo Admin">
                    <option value="Sapo Admin">Sapo Admin</option>
                  </AvField>
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="content_screen" sm={10}>
                  Màn hình chi tiết thông báo <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField type="select" className="form-control select" value={'dashboard'} id="content_screen" name="content_screen">
                    <option value="dashboard">dashboard</option>
                    <option value="order_dashboard">order_dashboard</option>
                    <option value="add_product">add_product</option>
                    <option value="order_detail">order_detail</option>
                    <option value="orders">orders</option>
                    <option value="shipments">shipments</option>
                    <option value="reports">reports</option>
                    <option value="webview">webview</option>
                    <option value="daily_revenue_report">daily_revenue_report</option>
                    <option value="upgrade_package">upgrade_package</option>
                    <option value="vouchers">vouchers</option>
                    <option value="noti_detail">noti_detail</option>
                    <option value="create_order">create order</option>
                  </AvField>
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="multiple_times_enabled" sm={12}>
                  Phạm vi hiển thị thông báo <Label className="star">*</Label>
                </Label>
                <Col sm={12}>
                  <AvField type="select" className="form-control select" id="visibility" value="ALL" name="visibility">
                    <option value="ALL">Tất cả</option>
                    <option value="ALERT_ONLY">Ngoài màn hình</option>
                    <option value="PERSISTENT_ONLY">Trong app</option>
                  </AvField>
                </Col>
              </AvGroup>

              <AvGroup className="check-restrict">
                Hệ điều hành <Label className="star">*</Label>
                <div className="d-flex justify-content-around">
                  <div className="d-inline-flex">
                    <input onChange={handleSystemsChecked} defaultChecked={true} type="checkbox" name="IOS" id="ios" />
                    <Label for="ios" style={{ marginLeft: '10px' }}>
                      IOS
                    </Label>
                  </div>
                  <div className="d-inline-flex">
                    <input
                      onChange={handleSystemsChecked}
                      className="lb"
                      id="android"
                      defaultChecked={true}
                      type="checkbox"
                      name="ANDROID"
                    />
                    <Label for="android" style={{ marginLeft: '10px' }}>
                      ANDROID
                    </Label>
                  </div>
                </div>
              </AvGroup>
            </div>
          </div>
          <div className="box-right-price-rule">
            <div className="price-rule-box">
              <h5 className="d-inline-flex">
                Cấu hình khuyến mại <input onChange={onClickDisplayChange} type="checkbox" checked={displayBox} className="ml-2" />
              </h5>
              {displayBox ? (
                <Fragment>
                  <AvGroup row>
                    <Label for="usage_limit" sm={10}>
                      Số lần sử dụng <Label className="star">*</Label>
                    </Label>
                    <Col sm={12}>
                      <AvField
                        type="number"
                        name="usage_limit"
                        onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Số lần sử dụng không được để trống.'
                          },
                          min: {
                            value: 1,
                            errorMessage: 'Số lần sử dụng tối thiểu là 1'
                          },
                          max: {
                            value: 100,
                            errorMessage: 'Số lần sử dụng không được vượt quá 100'
                          }
                        }}
                        id="usage_limit"
                      />
                    </Col>
                  </AvGroup>
                  <AvGroup row>
                    <Label for="usage_in_hours" sm={10}>
                      Thời gian sử dụng (giờ) <Label className="star">*</Label>
                    </Label>
                    <Col sm={12}>
                      <AvField
                        type="number"
                        name="usage_in_hours"
                        onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Thời gian sử dụng không được để trống.'
                          },
                          min: {
                            value: 1,
                            errorMessage: 'Thời gian sử dụng tối thiểu là 1 giờ'
                          },
                          max: {
                            value: 8760,
                            errorMessage: 'Số lần sử dụng không được vượt quá 8760 giờ (1 năm)'
                          }
                        }}
                        id="usage_in_hours"
                      />
                    </Col>
                  </AvGroup>
                  <AvGroup row>
                    <Label for="discount_type" sm={10}>
                      Loại khuyến mại <Label className="star">*</Label>
                    </Label>
                    <Col sm={12}>
                      <AvField
                        type="select"
                        className="form-control select"
                        onChange={handleDiscounttype}
                        value="PERCENTAGE"
                        id="discount_type"
                        name="discount_type"
                      >
                        <option value="FIX_AMOUNT">Theo số tiền</option>
                        <option value="PERCENTAGE">Theo phần trăm</option>
                      </AvField>
                    </Col>
                  </AvGroup>
                  <AvGroup row>
                    <Label for="discount_values" sm={10}>
                      Giá trị khuyến mại {discountType === 'PERCENTAGE' ? <Label>(%) </Label> : <Label>(đồng) </Label>}
                      <Label className="star">*</Label>
                    </Label>
                    <Col sm={12}>
                      <AvField
                        type="number"
                        name="discount_values"
                        onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: 'Giá trị không được để trống.'
                          },
                          min: {
                            value: 1,
                            errorMessage: 'Giá trị khuyến mại tối thiểu là 1 '
                          },
                          max: {
                            value: discountType === 'PERCENTAGE' ? 100 : 1000000,
                            errorMessage: `Giá trị tối đa là ${discountType === 'PERCENTAGE' ? 100 : 1000000}`
                          }
                        }}
                        value="0"
                        id="discount_values"
                      />
                    </Col>
                  </AvGroup>
                  {discountType === 'PERCENTAGE' ? (
                    <AvGroup row>
                      <Label for="discount_maximum_amount" sm={10}>
                        Số tiền giảm tối đa
                      </Label>
                      <Col sm={12}>
                        <AvField
                          type="number"
                          name="discount_maximum_amount"
                          onKeyDown={evt => evt.key === 'e' && evt.preventDefault()}
                          validate={{
                            min: {
                              value: 0,
                              errorMessage: 'Số tiền tối thiểu là 0 đồng'
                            },
                            max: {
                              value: 1000000,
                              errorMessage: 'Số tiền tối tối đa là 1.000.000 đông'
                            }
                          }}
                          id="discount_maximum_amount"
                        />
                      </Col>
                    </AvGroup>
                  ) : null}
                  <Row>
                    <Label for="shipping_providers" sm={12}>
                      Đơn vị vận chuyển
                    </Label>
                    <Col style={{ display: 'flex', alignItems: 'center', marginBottom: 5 }}>
                      <input id="select-all-providers" type="checkbox" onChange={changeSelectAllProviders} checked={selectAllProviders} />
                      <Label for="select-all-providers" sm={10} style={{ cursor: 'pointer' }}>
                        Chọn tất cả các đơn vị vận chuyển
                      </Label>
                    </Col>

                    <Col sm={12}>
                      {!selectAllProviders && (
                        <Button
                          onClick={chooseShippingProviders}
                          style={{ backgroundColor: '#0088FF', border: 'none', fontSize: 16 }}
                          size="lg"
                          block
                        >
                          Chọn đơn vị vận chuyển
                        </Button>
                      )}

                      <Alert color="primary" className="mt-3" style={{ backgroundColor: '#E0F1FF', border: 'none' }}>
                        <b>Lưu ý:</b> Khi tích chọn hệ thống sẽ mặc định chọn tất cả các đơn vị vận chuyển kể cả các đơn vị chưa kết nối
                      </Alert>
                      <Modal isOpen={modal} toggle={toggle} centered={true}>
                        <ModalHeader
                          toggle={toggle}
                          style={{ backgroundColor: '#0088FF', border: 'none', color: 'white', paddingLeft: 30 }}
                        >
                          Danh sách đối tác vận chuyển
                        </ModalHeader>
                        <ModalBody style={{ border: 'none' }}>
                          <Form>
                            <Row>
                              <Col sm={6} style={{ paddingLeft: 30 }}>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('GHN Express')}
                                      checked={shippingProviders.has('GHN Express')}
                                    />
                                    GHN Express
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('Ninja Van')}
                                      checked={shippingProviders.has('Ninja Van')}
                                    />
                                    Ninja Van
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('BEST Express')}
                                      checked={shippingProviders.has('BEST Express')}
                                    />
                                    BEST Express
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('J&T Express')}
                                      checked={shippingProviders.has('J&T Express')}
                                    />
                                    J&T Express
                                  </Label>
                                </FormGroup>
                              </Col>
                              <Col sm={6} className="pl-0">
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('DHL eCommerce')}
                                      checked={shippingProviders.has('DHL eCommerce')}
                                    />
                                    DHL eCommerce
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('Ship60')}
                                      checked={shippingProviders.has('Ship60')}
                                    />
                                    Ship60
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('Snappy Express (Shippo)')}
                                      checked={shippingProviders.has('Snappy Express (Shippo)')}
                                    />
                                    Snappy Express (Shippo)
                                  </Label>
                                </FormGroup>
                                <FormGroup check inline>
                                  <Label check>
                                    <Input
                                      type="checkbox"
                                      onChange={() => changeShippingProvider('Viettel Post')}
                                      checked={shippingProviders.has('Viettel Post')}
                                    />
                                    Viettel Post
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                        <ModalFooter style={{ border: 'none' }}>
                          <Button
                            color="secondary"
                            onClick={cancelShippingProviders}
                            style={{ color: 'black', backgroundColor: 'white', border: '1px solid #C4CDD5', borderRadius: 3 }}
                          >
                            Hủy
                          </Button>
                          <Button
                            color="primary"
                            onClick={saveShippingProviders}
                            style={{ color: 'white', backgroundColor: 'rgb(0, 136, 255)', border: 'none', borderRadius: 3 }}
                          >
                            Đồng ý
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </Col>
                  </Row>
                </Fragment>
              ) : null}
            </div>
          </div>
        </div>
      </AvForm>
    </div>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  discountCampaign: storeState.discountCampaign.discountCampaign,
  loading: storeState.discountCampaign.loading,
  updateSuccess: storeState.discountCampaign.updateSuccess
});
const mapDispatchToProps = { createDicountCampaign, getDiscountCampaign, reset, activeCampaign };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DiscountCampaignUpdate);
