import { combineReducers } from 'redux';
import { loadingBarReducer as loadingBar } from 'react-redux-loading-bar';

import authentication, { AuthenticationState } from './authentication';
import applicationProfile, { ApplicationProfileState } from './application-profile';

import administration, { AdministrationState } from 'app/modules/administration/administration.reducer';
import userManagement, { UserManagementState } from 'app/modules/administration/user-management/user-management.reducer';
import template, { TemplateState } from 'app/entities/template/Template.reducer';
import discountCampaign, { DiscountCampaignState } from 'app/entities/DiscountCampaign/DiscountCampaign.reducer';
import register, { RegisterState } from 'app/modules/account/register/register.reducer';
import scheduldDiscountCampaign, {
  scheduledDiscountCampaignState
} from 'app/entities/DiscountCampaign/ScheduledDiscountCampaign/ScheduledDiscountCampaign.reducer';
import activate, { ActivateState } from 'app/modules/account/activate/activate.reducer';
import password, { PasswordState } from 'app/modules/account/password/password.reducer';
import settings, { SettingsState } from 'app/modules/account/settings/settings.reducer';
import passwordReset, { PasswordResetState } from 'app/modules/account/password-reset/password-reset.reducer';
import { REQUEST } from './action-type.util';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

export interface IRootState {
  readonly authentication: AuthenticationState;
  readonly applicationProfile: ApplicationProfileState;
  readonly administration: AdministrationState;
  readonly userManagement: UserManagementState;
  readonly register: RegisterState;
  readonly scheduldDiscountCampaign: scheduledDiscountCampaignState;
  readonly activate: ActivateState;
  readonly discountCampaign: DiscountCampaignState;
  readonly template: TemplateState;
  readonly passwordReset: PasswordResetState;
  readonly password: PasswordState;
  readonly settings: SettingsState;
  /* jhipster-needle-add-reducer-type - JHipster will add reducer type here */
  readonly loadingBar: any;
}

const appReducer = combineReducers<IRootState>({
  authentication,
  applicationProfile,
  administration,
  userManagement,
  template,
  register,
  scheduldDiscountCampaign,
  activate,
  passwordReset,
  discountCampaign,
  password,
  settings,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
  loadingBar
});
const rootReducer = (state, action) => {
  if (action.type === REQUEST('authentication/LOGOUT')) {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
