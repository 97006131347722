import React, { useEffect, useState } from 'react';
import { Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvField, AvFeedback, AvRadio } from 'availity-reactstrap-validation';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import SWCheckbox from 'app/shared/SWCheckbox/sw-checkbox';
import { IData } from 'app/entities/script-fnb/update-script-fnb/update-script-fnb';
import SWebButton from 'app/shared/SWebButton/SWebButton';
import '../../script-fnb/update-script-fnb/update-script-fnb.scss';
import { toast } from 'react-toastify';

import historyFnb from 'app/services/history-fnb';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import SWRadio from 'app/shared/SWRadio/sw-radio';
const breadcrumb: IBreadcrumbs = {
  link: '/admin/history-noti-fnb',
  title: 'Quay lại danh sách kịch bản thông báo'
};

// eslint-disable-next-line complexity
export default function UpdateHistoryFnb(props) {
  const [modelValue, setModelValue] = useState<IData>();
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [buttons, setButtons] = useState<IButton[]>([]);

  const history = useHistory();

  useEffect(() => {
    historyFnb.getOne(props.match.params.id).then(re => {
      setModelValue(re.data.notification);
    });
  }, []);

  const checkBoxOs = (str: string) => {
    let a = false;
    if (str && modelValue && modelValue.os) {
      modelValue.os.map(o => {
        if (o === str) {
          a = true;
        }
      });
    } else {
      a = false;
    }
    return a;
  };

  const checkBoxPackageName = (str: string) => {
    let a = false;
    if (str && modelValue && modelValue.package_name) {
      modelValue.package_name.map(o => {
        if (o === str) {
          a = true;
        }
      });
    } else {
      a = false;
    }
    return a;
  };

  const handleActiveModal = () => {
    setActiveModal(!activeModal);
  };
  const pustAction = (str: string) => {
    if (str === 'error' || str === 'cancelled') {
      historyFnb.actionNoti(props.match.params.id, 'active').then(re => {
        toast.success('Gửi lại thành công');
        history.push('/admin/history-noti-fnb');
      });
    } else if (str === 'scheduling') {
      historyFnb.actionNoti(props.match.params.id, 'inactive').then(re => {
        toast.success('Hủy gửi thành công');
        history.push('/admin/history-noti-fnb');
      });
    }
  };

  const onSubmit = () => {
    setActiveModal(!activeModal);
  };

  const switchButton = (str: string) => {
    switch (str) {
      case 'error':
      case 'cancelled':
        setButtons([
          {
            text: 'Gửi lại',
            color: 'primary',
            onClick: onSubmit
          }
        ]);
        return;
      case 'scheduling':
        setButtons([
          {
            text: 'Hủy gửi',
            color: 'danger',
            onClick: onSubmit
          }
        ]);
        return;

      default:
        return [];
    }
  };

  useEffect(() => {
    if (modelValue) {
      switchButton(modelValue.status);
    }
  }, [modelValue]);

  return (
    <div id="sw-update-script-fnb">
      <SWHeader title="Chi tiết kịch bản thông báo" breadcrumb={breadcrumb} button={buttons} />
      <AvForm>
        <div className="content-update-script-fnb">
          <div className="box-left">
            <AvGroup row>
              <Label for="name" sm={10}>
                Tên kịch bản <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="textarea"
                  name="name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tên kịch bản không được để trống.'
                    },
                    maxLength: {
                      value: 255,
                      errorMessage: 'Số ký tự không được vượt quá 255.'
                    }
                  }}
                  value={modelValue ? (modelValue.name ? modelValue.name : '') : ''}
                  id="title"
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="template_id" sm={10}>
                Nội dung gửi <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="number"
                  name="content_id"
                  value={modelValue ? (modelValue.content_id ? modelValue.content_id : 0) : 0}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Nội dung gửi không được để trống.'
                    },
                    min: {
                      value: 0,
                      errorMessage: 'Mã nội dung phải lớn hơn 0'
                    }
                  }}
                  id="template_id"
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Danh mục <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  //   onChange={handleCategoryCase}
                  className="form-control select"
                  value={modelValue ? (modelValue.category ? modelValue.category : '--') : '--'}
                  name="category"
                >
                  <option value="--">{modelValue ? (modelValue.category ? modelValue.category : '--') : '--'}</option>
                  <option value="system">System</option>
                  <option value="onboarding">Onboarding</option>
                  <option value="new=">New</option>
                  <option value="advertise">Advertise</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                App nhận thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="app"
                  value={modelValue ? (modelValue.app ? modelValue.app : null) : null}
                >
                  <option value={null}>Tất cả</option>
                  <option value="admin">App Admin</option>
                  <option value="staff">App Staff</option>
                  <option value="pos">App Pos</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Phạm vi hiển thị thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="screen_type"
                  value={modelValue ? (modelValue.screen_type ? modelValue.screen_type : null) : null}
                >
                  <option value={null}>Tất cả</option>
                  <option value="in_app">Trong app</option>
                  <option value="system">Ngoài màn hình</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="content_screen" sm={10}>
                Màn hình chi tiết thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="content_screen"
                  value={modelValue ? (modelValue.target_screen ? modelValue.target_screen : '--') : '--'}
                >
                  <option value="--">{modelValue ? (modelValue.target_screen ? modelValue.target_screen : '--') : '--'}</option>
                  <option value="noti_default">Noti default</option>
                  <option value="noti_detail">Noti detail</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvCheckboxGroup
              inline
              name="os"
              label={
                <div className="mb-2">
                  Hệ điều hành <span className="star">(*)</span>
                </div>
              }
              value={modelValue ? (modelValue.os ? modelValue.os : '--') : '--'}
            >
              <div className="d-flex justify-content-between">
                <AvCheckbox disabled label="Android" checked={checkBoxOs('android')} />
                <AvCheckbox disabled label="IOS" checked={checkBoxOs('ios')} />
                <AvCheckbox disabled label="PC Windows" checked={checkBoxOs('pc')} />
                <AvCheckbox disabled label="Website" checked={checkBoxOs('website')} />
              </div>
            </AvCheckboxGroup>
          </div>
          <div className="box-right">
            <div className="box-1">
              <InputGroup>
                <Label for="subject" sm={10}>
                  Chủ đề
                </Label>
                <Col sm={12}>
                  <Input
                    type="textarea"
                    name="subject"
                    value={modelValue ? (modelValue.subject ? modelValue.subject : '') : ''}
                    style={{ resize: 'none' }}
                    disabled
                  />
                </Col>
              </InputGroup>
              <InputGroup>
                <Label for="content" sm={10}>
                  Nội dung
                </Label>
                <Col sm={12}>
                  <Input
                    type="textarea"
                    rows={10}
                    name="content"
                    disabled
                    style={{ resize: 'none' }}
                    value={modelValue ? (modelValue.content ? modelValue.content : '') : ''}
                  />
                </Col>
              </InputGroup>
            </div>
            <div className="box-2">
              <div className="title-time mb-2">
                <SWCheckbox checked={modelValue ? (modelValue.start_time ? true : false) : false} label="Lên lịch gửi" disabled/>
              </div>
              {modelValue ? (
                modelValue.start_time ? (
                  <AvGroup>
                    <AvField
                      disabled
                      name="start_time"
                      label="Cài đặt giờ hẹn"
                      type="datetime-local"
                      value={moment.unix(modelValue.start_time).format('YYYY-MM-DDTHH:MM')}
                    />
                  </AvGroup>
                ) : null
              ) : null}
              <div className="d-flex justify-content-between">
                <SWRadio
                  defaultChecked={modelValue ? (modelValue.csv_file_name ? true : false) : false}
                  disabled
                  name="rdo-type-upload"
                  value="file"
                  label="Up file excel"
                />
                <SWRadio
                  disabled
                  defaultChecked={modelValue ? (modelValue.package_name ? true : false) : false}
                  name="rdo-type-upload"
                  value="criteria"
                  label="Lọc theo contact"
                />
              </div>

              {modelValue ? (
                modelValue.csv_file_name ? (
                  <AvGroup className="mt-4">
                    <AvInput disabled hidden type="file" id="file" name="path_csv" />
                    <Label for="file" style={{ width: '100%' }}>
                      <InputGroup>
                        <InputGroupAddon required className="w-100" addonType="prepend">
                          <InputGroupText required className="choose-file disabled-file">
                            {modelValue.csv_file_name}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </AvGroup>
                ) : null
              ) : null}

              {modelValue ? (
                modelValue.package_name ? (
                  <div>
                    
                    <AvCheckboxGroup inline name="package_name">
                      <p>Gói dịch vụ</p>
                      <div className="d-flex justify-content-around" style={{ marginLeft: '1%' }}>
                        <SWCheckbox disabled checked={checkBoxPackageName('trial')} label="Trial" value="trial" />
                        <SWCheckbox disabled label="Basic" value="basic" checked={checkBoxPackageName('basic')} />
                        <SWCheckbox disabled label="Pro" value="pro" checked={checkBoxPackageName('pro')} />
                      </div>
                    </AvCheckboxGroup>
                  </div>
                ) : null
              ) : null}
            </div>
          </div>
        </div>
        <Modal isOpen={activeModal} toggle={handleActiveModal} id="modal-list-package-name">
          <ModalHeader
            toggle={handleActiveModal}
            className={`${modelValue ? (modelValue.status === 'scheduling' ? 'header-danger' : 'header-primary') : 'header-primary'}`}
          >
            Thông báo
          </ModalHeader>
          <ModalBody>Bạn có chắc muốn {modelValue ? (modelValue.status === 'scheduling' ? 'hủy gửi' : 'gửi lại') : ''} noti?</ModalBody>
          <ModalFooter className="footer-modal">
            <SWebButton minor outLine onClick={handleActiveModal}>
              Hủy
            </SWebButton>
            <SWebButton
              onClick={() => pustAction(modelValue ? modelValue.status : '')}
              color={`${modelValue ? (modelValue.status === 'scheduling' ? 'danger' : 'primary') : 'primary'}`}
            >
              {modelValue ? (modelValue.status === 'scheduling' ? 'Hủy gửi' : 'Gửi lại') : ''}
            </SWebButton>
          </ModalFooter>
        </Modal>
      </AvForm>
    </div>
  );
}
