import React from 'react';
import { Link } from 'react-router-dom';
import BackIcon from '../../../content/images/back-icon.svg';
import './sw-breadcrumbs.scss';
export interface IBreadcrumbs {
  link: string;
  title: string;
}

interface SWBreadcrumbsProps {
  data: IBreadcrumbs;
}
export default function SWBreadcrumbs(props: SWBreadcrumbsProps) {
  const { data } = props;
  return (
    <div id="sw-breadcrumbs">
      <Link to={data.link}>
        <BackIcon className="icon" /> {data.title}
      </Link>
    </div>
  );
}
