import axios from 'axios';

class HistoryFnb {
  getList(page: number) {
    return axios.get(`/api/notification_fnb?size=20&page=${page}`);
  }
  getOne(id: string) {
    return axios.get(`/api/notification_fnb/${id}.json`);
  }
  actionNoti(id: string, action: string) {
    return axios.put(`/api/notification_fnb/${id}/${action}.json`);
  }
}
export default new HistoryFnb();
