import { DataTBSort } from 'app/entities/content-fnb';
import { IRootState } from 'app/shared/reducers';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import SWHeader from 'app/shared/SWHeader/sw-header';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import { getScheduledDiscounts, updatePageActive } from './ScheduledDiscountCampaign.reducer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const dataTB: DataTBSort[] = [
  {
    width: '18%',
    title: 'Mã kịch bản',
    field: 'discount_campaign_id'
  },
  {
    width: '12.5%',
    title: 'Tiều đề',
    field: 'title'
  },
  {
    width: '12.5%',
    title: 'Số tên miền gửi',
    field: 'total_domain'
  },
  {
    width: '6.5%',
    title: 'Lượt gửi',
    field: 'total_sent'
  },
  {
    width: '6.5%',
    title: 'Lượt đọc',
    field: 'total_seen'
  },
  {
    width: '6%',
    title: 'Tỷ lệ đọc',
    field: 'total_seen_rate'
  },
  {
    width: '7%',
    title: 'Trạng thái',
    field: 'discount_campaign_status'
  },
  {
    width: '10%',
    title: 'Ngày gửi',
    field: 'scheduled_date'
  },
  {
    width: '10%',
    title: 'Ngày tạo',
    field: 'created_date'
  }
];

export interface IScheduledDiscountCapaignProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}
const ScheduledDiscountCampaign = (props: IScheduledDiscountCapaignProps) => {
  const { ScheduledDiscountCampaigns, match, activePage, totalItems } = props;
  const [orderBy, setOrderBy] = useState<string>('created_date');
  const [orderByDesc, setOrderByDesc] = useState<boolean>(true);
  const onOrder = field => {
    if (field === 'discount_campaign_status') {
      return;
    }
    if (orderBy === field) {
      setOrderByDesc(!orderByDesc);
    } else {
      setOrderBy(field);
    }
    props.updatePageActive(1);
  };
  useEffect(() => {
    props.getScheduledDiscounts(activePage - 1, orderBy, orderByDesc);
    window.scrollTo(0, 0);
  }, [activePage, orderBy, orderByDesc]);
  const handleStatus = (status, pushed, scheduledDate) => {
    const currentTime = new Date().getTime();
    const date = new Date(scheduledDate).getTime();
    if (status === 'ACTIVE') {
      if (pushed) {
        return <div className="bt-status-scheduled success">Đã gửi</div>;
      } else if (date > currentTime) {
        return <div className="bt-status-scheduled watting">Chờ gửi</div>;
      } else {
        return <div className="bt-status-scheduled cancel">Hủy</div>;
      }
    } else {
      if (pushed) {
        return <div className="bt-status-scheduled success">Đã gửi</div>;
      } else {
        return <div className="bt-status-scheduled cancel">Hủy</div>;
      }
    }
  };
  return (
    <div className="scheduled-discount-campaign-list">
      <SWHeader title="Lịch sử gửi thông báo" />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th
                    key={i}
                    style={{ width: d.width }}
                    onClick={() => onOrder(d.field)}
                    className={d.field !== 'discount_campaign_status' ? 'sort-able' : ''}
                  >
                    {d.title}
                    {d.field === orderBy &&
                      (orderByDesc ? (
                        <FontAwesomeIcon icon={faCaretDown} className="ml-2" />
                      ) : (
                        <FontAwesomeIcon icon={faCaretUp} className="ml-2" />
                      ))}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {ScheduledDiscountCampaigns.map((d, i) => (
                <tr key={i}>
                  <td>{<Link to={`/admin/discount-campaign/detail/${d.discount_campaign_id}`}>{d.discount_campaign_id}</Link>}</td>
                  <td>{d.title}</td>
                  <td>{d.total_domain}</td>
                  <td>{d.total_sent}</td>
                  <td>{d.total_seen}</td>
                  <td>{Math.round(d.total_seen_rate * 10000) / 100 + '%'}</td>
                  <td>{handleStatus(d.discount_campaign_status, d.pushed, d.scheduled_date)}</td>
                  <td>{convertDateTimeFromServer(d.scheduled_date)}</td>
                  <td>{convertDateTimeFromServer(d.created_date)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {ScheduledDiscountCampaigns.length <= 0 ? null : (
          <SWebPagination page={activePage} totalItem={totalItems} setPage={props.updatePageActive} itemPerPage={ITEMS_PER_PAGE} />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  ScheduledDiscountCampaigns: storeState.scheduldDiscountCampaign.scheduledDiscountCampaigns,
  activePage: storeState.scheduldDiscountCampaign.activePage,
  totalItems: storeState.scheduldDiscountCampaign.totalItems
});
const mapDispatchToProps = { getScheduledDiscounts, updatePageActive };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ScheduledDiscountCampaign);
