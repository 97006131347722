import React, { Fragment } from 'react';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { ListGroup, ListGroupItem, Container, Row, Col } from 'reactstrap';
import './menu.scss';
import AccountMenu from './account';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { actionMenu } from '../../../shared/reducers/authentication';
import More from '../../../../content/images/more.svg';

interface MenuProps extends StateProps, DispatchProps {}

const Menu = (props: MenuProps) => {
  const { menuAction } = props;

  const onClick = () => {
    props.actionMenu(!menuAction);
  };

  return (
    <React.Fragment>
      {/* <div className="menu-component">
                <div className="Logo-sapo">

                </div>
                <ul className="main-menu">
                    <ListGroup flush>
                        <ListGroupItem><NavLink activeStyle={{ backgroundColor: "#4697fe" }} to="/admin/template">&nbsp; <FontAwesomeIcon icon="align-left" />&nbsp;Quản lý nội dung</NavLink></ListGroupItem>
                        <ListGroupItem><NavLink activeStyle={{ backgroundColor: "#4697fe" }} to="/admin/discount-campaign">&nbsp;&nbsp;<FontAwesomeIcon icon="mobile-alt" />&nbsp;Kịch bản thông báo</NavLink></ListGroupItem>
                        <ListGroupItem><NavLink activeStyle={{ backgroundColor: "#4697fe" }} to="/admin/scheduled-discount-campaign">&nbsp;<FontAwesomeIcon icon="history" />&nbsp;Lịch sử thông báo</NavLink></ListGroupItem>
                        {
                            props.account.authorities && props.account.authorities.includes("ROLE_ADMIN") ? <ListGroupItem><NavLink activeStyle={{ backgroundColor: "#4697fe" }} to="/admin/user-management"> &nbsp;<FontAwesomeIcon icon="user" />&nbsp; Quản lý người dùng</NavLink></ListGroupItem> : null
                        }
                    </ListGroup>
                </ul>
                <div className="account-bx">
                    <AccountMenu isAuthenticated={props.isAuthenticated} account={props.account} />
                </div>
            </div> */}
      <div>
        <Container id="menu-left" className="menu-component">
          <Row className="Logo-sapo">
            <Col>{menuAction ? <img src="content/images/sapo.svg" /> : ''}</Col>
            {/* <More onClick={onClick} className="icon-menu more-action" /> */}
          </Row>
          <Row className="main-menu">
            <Col className="menu-list">
              <ListGroup>
                <ListGroupItem>
                  <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/template">
                    <img src="content/images/noti-fnb-icon.svg" className="menu-icon" />
                    <span>Quản lý nội dung</span>
                  </NavLink>
                </ListGroupItem>
                <ListGroupItem>
                  <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/discount-campaign">
                    <img src="content/images/script-noti.svg" className="menu-icon" />
                    <span>Kịch bản thông báo</span>
                  </NavLink>
                </ListGroupItem>
                <ListGroupItem>
                  <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/scheduled-discount-campaign">
                    <img src="content/images/history-noti.svg" className="menu-icon" />
                    <span>Lịch sử thông báo</span>
                  </NavLink>
                </ListGroupItem>
                <ListGroupItem>
                  <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/content-fnb">
                    <img src="content/images/noti-fnb-icon.svg" className="menu-icon" />
                    <span>Quản lý nội dung FnB</span>
                  </NavLink>
                </ListGroupItem>
                <ListGroupItem>
                  <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/script-fnb">
                    <img src="content/images/text-icon.svg" className="menu-icon" />
                    <span>Kịch bản thông báo FnB</span>
                  </NavLink>
                </ListGroupItem>
                <ListGroupItem>
                <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/history-noti-fnb">
                    <img src="content/images/history-noti.svg" className="menu-icon" />
                    <span>Lịch sử thông báo FnB</span>
                  </NavLink>
                </ListGroupItem>
                {props.account.authorities && props.account.authorities.includes('ROLE_ADMIN') ? (
                  <ListGroupItem>
                    <NavLink activeStyle={{ backgroundColor: '#4697fe' }} to="/admin/user-management">
                      <img src="content/images/noti-fnb-icon.svg" className="menu-icon" />
                      <span>Quản lý người dùng</span>
                    </NavLink>
                  </ListGroupItem>
                ) : null}
              </ListGroup>
            </Col>
          </Row>
          <Row className="account-bx">
            <AccountMenu isAuthenticated={props.isAuthenticated} account={props.account} />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  menuAction: authentication.menuAction
});

const mapDispatchToProps = {
  actionMenu
};
type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
