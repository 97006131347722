import React, { useState, useEffect, Fragment } from 'react';

import { connect } from 'react-redux';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, Row, Col } from 'reactstrap';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import PasswordStrengthBar from 'app/shared/layout/password/password-strength-bar';
import { savePassword, reset, changeShowModal } from './password.reducer';
import { RouteComponentProps } from 'react-router-dom';
import { logout } from '../../../shared/reducers/authentication'
export interface IUserPasswordProps extends StateProps, DispatchProps {
}
const PasswordPage = (props: IUserPasswordProps) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    props.reset();
    return () => props.reset();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      props.logout();
    }
  }, [props.updateSuccess])

  const handleValidSubmit = (event, values) => {
    props.savePassword(values.currentPassword, values.newPassword);
  };
  const updatePassword = event => setPassword(event.target.value);
  const { showModal } = props;
  return (
    <Fragment>
      <Modal isOpen={showModal} toggle={props.changeShowModal} backdrop="static" id="login-page" autoFocus={false}>
        <AvForm onValidSubmit={handleValidSubmit}>
          <ModalHeader id="login-title">
            Thay đổi mật khẩu
          </ModalHeader>
          <ModalBody>
            <Row className="justify-content-center">
              <Col md="12">
                <AvField
                  name="currentPassword"
                  label="Mật khẩu cũ"
                  style={{ border: "1px solid #444" }}
                  type="password"
                  validate={{
                    required: { value: true, errorMessage: 'Trường này không được để trống' }
                  }}
                />
                <AvField
                  name="newPassword"
                  label="Mật khẩu mới"
                  type="password"
                  style={{ border: "1px solid #444" }}
                  validate={{
                    required: { value: true, errorMessage: 'Trường này không được để trống' },
                    minLength: { value: 4, errorMessage: 'Mật khẩu yêu cầu tối thiểu 4 ký tự' },
                    maxLength: { value: 50, errorMessage: 'Mật khẩu yêu cầu tối đa 100 ký tự' }
                  }}
                  onChange={updatePassword}
                />
                <AvField
                  name="confirmPassword"
                  label="Nhập lại mật khẩu"
                  type="password"
                  style={{ border: "1px solid #444" }}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Trường này không được để trống'
                    },
                    minLength: {
                      value: 4,
                      errorMessage: 'Mật khẩu yêu cầu tối thiểu 4 ký tự'
                    },
                    maxLength: {
                      value: 50,
                      errorMessage: 'Mật khẩu yêu cầu tối đa 100 ký tự.'
                    },
                    match: {
                      value: 'newPassword',
                      errorMessage: 'Mật khẩu bạn vừa nhập không khớp'
                    }
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button type="button" onClick={props.changeShowModal}>
              Hủy
            </Button>
            <Button color="primary" disabled={props.loading} type="submit">
              Thay đổi
            </Button>
          </ModalFooter>
        </AvForm>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = ({ authentication, password }: IRootState) => ({
  account: authentication.account,
  isAuthenticated: authentication.isAuthenticated,
  showModal: password.showModal,
  loading: password.loading,
  updateSuccess: password.updateSuccess,
  updateFailure: password.updateFailure
});

const mapDispatchToProps = { getSession, savePassword, reset, changeShowModal, logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PasswordPage);
