import React from 'react';
import './sw-checkbox.scss';

interface SWCheckboxProps {
  label?: string;
  onChange?: any;
  defaultChecked?: boolean;
  name?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
  checked?: boolean;
}
export default function SWCheckbox(props: SWCheckboxProps) {
  const { label, onChange, defaultChecked, name, value, id, disabled, checked } = props;
  return (
    <div id="sw-checkbox">
      <label className={`container ${disabled ? 'disabled' : ''}`}>
        {label}
        <input
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          type="checkbox"
          defaultChecked={defaultChecked}
          name={name}
          value={value}
          id={id}
        />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}
