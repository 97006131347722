import { IRootState } from 'app/shared/reducers';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import classnames from 'classnames';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Col, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import '../../../content/ckeditor5/styles.css';
import '../content-fnb/create-content-fnb/style.scss';
import LanguageForm from './LanguageForm';
import { changeDisplayBox, createTemplate, deleteTemplate, getTemplate, reset, updateTemplate } from './Template.reducer';
import { TemplateModel } from './TemplateModel';

const breadcrumb: IBreadcrumbs = {
  link: '/admin/template',
  title: 'Quay về trang danh sách'
};

export interface ITemplateUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id }> {}
const TemplateUpdate = (props: ITemplateUpdateProps) => {
  const [languageActive, setLanguageActive] = useState('vn');
  const [ListLanguage, setListLanguage] = useState<Array<string>>([]);
  const [templates, setTemplates] = useState<Array<TemplateModel>>(null);
  const [dropList, setDropList] = useState(['vn', 'th', 'en']);

  const toggle = tab => {
    if (languageActive !== tab) setLanguageActive(tab);
  };

  useEffect(() => {
    sessionStorage.clear();
  }, [templates]);

  useEffect(() => {
    props.getTemplate(props.match.params.id);
  }, []);

  const checkChildNode = node => {
    if (!node) {
      return false;
    }
    const doc = node.parentNode;
    let check = false;
    let notes = null;
    for (let i = 0; i < doc.childNodes.length; i++) {
      if (doc.childNodes[i].classList.contains('ck')) {
        notes = doc.childNodes[i];
        check = true;
        break;
      }
    }
    return check;
  };
  const hanldeValidateLanugage = l => {
    const subject = document.getElementById('subject_' + l).textContent;
    const content = document.getElementById('content_' + l).textContent;
    if (subject === '' || !subject || subject === undefined || content === '' || !content || content === undefined) {
      setLanguageActive(l);
      return false;
    }
    return true;
  };
  const validateForm = () => {
    let isValid = true;
    if (ListLanguage.includes('vn') && !hanldeValidateLanugage('vn')) {
      isValid = false;
    }
    if (ListLanguage.includes('en') && !hanldeValidateLanugage('en')) {
      isValid = false;
    }
    if (ListLanguage.includes('th') && !hanldeValidateLanugage('th')) {
      isValid = false;
    }
    if (!isValid) {
      toast.error('Bạn chưa nhập đủ nội dung thông báo');
    }
  };
  const buttons: IButton[] = [
    {
      text: 'Lưu',
      color: 'primary',
      type: 'submit',
      onClick: validateForm
    }
  ];

  useEffect(() => {
    if (templates) {
      const temp = [];
      templates.map((v, key) => {
        if (v.language === 'VIETNAMESE') temp.push('vn');
        if (v.language === 'THAI') temp.push('th');
        if (v.language === 'ENGLISH') temp.push('en');
      });
      setListLanguage(temp);
    }
  }, [templates]);

  useLayoutEffect(() => {
    const script2 = document.createElement('script');
    if (ListLanguage.includes('vn') && document.querySelector('.editor_vn') && !checkChildNode(document.querySelector('.editor_vn'))) {
      script2.text =
        'ClassicEditor.create(document.querySelector(".editor_vn"),{heading:{options:[{model:"heading1",view:{name:"p",styles:{"font-size":"20px","font-weight":"bold","line-height":"24px"}},title:"Tiêu đề"},{model:"heading2",view:{name:"p",styles:{"font-size":"16px","line-height":"20px"}},title:"Nội dung"},{model:"heading3",view:{name:"p",styles:{"font-size":"14px ","line-height":"20px"}},title:"Nội dung phụ"}]},toolbar:{items:["heading","|","imageInsert","|","fontColor","|","bold","italic","link","bulletedList","numberedList","|","fontBackgroundColor","fontSize","removeFormat","|","indent","outdent","|","blockQuote","undo","redo"]},language:"vi",image:{toolbar:["imageResize","|","imageTextAlternative","linkImage"],styles:["alignLeft","alignCenter","alignRight"],resizeOptions:[{name:"imageResize:original",label:"Original",value:null},{name:"imageResize:25",label:"25%",value:"25"},{name:"imageResize:50",label:"50%",value:"50"},{name:"imageResize:75",label:"75%",value:"75"}]},fontSize:{options:[14,20,28,36,50]},fontColor:{colors:[{color:"hsl(226, 12%, 23%)",label:"Đen"},{color:"hsl(231, 4%, 58%)",label:"Xám"},{color:" hsl(208, 100%, 50%)",label:"Xanh dương"}]},licenseKey:""}).then(e=>{window.editor_vn=e,editor_vn.model.document.on("change:data",e=>{sessionStorage.setItem("data_ckeditor_vn",editor_vn.getData())})}).catch(e=>{console.error("Oops, something went wrong!"),console.error("Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:"),console.warn("Build id: hdzm8em2f6q7-v1jpqlu9572o"),console.error(e)});';
      document.body.appendChild(script2);
    }
    const script3 = document.createElement('script');
    if (ListLanguage.includes('th') && document.querySelector('.editor_th') && !checkChildNode(document.querySelector('.editor_th'))) {
      script3.text =
        'ClassicEditor.create(document.querySelector(".editor_th"),{heading:{options:[{model:"heading1",view:{name:"p",styles:{"font-size":"20px","font-weight":"bold","line-height":"24px"}},title:"Tiêu đề"},{model:"heading2",view:{name:"p",styles:{"font-size":"16px","line-height":"20px"}},title:"Nội dung"},{model:"heading3",view:{name:"p",styles:{"font-size":"14px ","line-height":"20px"}},title:"Nội dung phụ"}]},toolbar:{items:["heading","|","imageInsert","|","fontColor","|","bold","italic","link","bulletedList","numberedList","|","fontBackgroundColor","fontSize","removeFormat","|","indent","outdent","|","blockQuote","undo","redo"]},language:"vi",image:{toolbar:["imageResize","|","imageTextAlternative","linkImage"],styles:["alignLeft","alignCenter","alignRight"],resizeOptions:[{name:"imageResize:original",label:"Original",value:null},{name:"imageResize:25",label:"25%",value:"25"},{name:"imageResize:50",label:"50%",value:"50"},{name:"imageResize:75",label:"75%",value:"75"}]},fontSize:{options:[14,20,28,36,50]},fontColor:{colors:[{color:"hsl(226, 12%, 23%)",label:"Đen"},{color:"hsl(231, 4%, 58%)",label:"Xám"},{color:" hsl(208, 100%, 50%)",label:"Xanh dương"}]},licenseKey:""}).then(e=>{window.editor_th=e,editor_th.model.document.on("change:data",e=>{sessionStorage.setItem("data_ckeditor_th",editor_th.getData())})}).catch(e=>{console.error("Oops, something went wrong!"),console.error("Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:"),console.warn("Build id: hdzm8em2f6q7-v1jpqlu9572o"),console.error(e)});';
      document.body.appendChild(script3);
    }
    const script4 = document.createElement('script');
    if (ListLanguage.includes('en') && document.querySelector('.editor_en') && !checkChildNode(document.querySelector('.editor_en'))) {
      script4.text =
        'ClassicEditor.create(document.querySelector(".editor_en"),{heading:{options:[{model:"heading1",view:{name:"p",styles:{"font-size":"20px","font-weight":"bold","line-height":"24px"}},title:"Tiêu đề"},{model:"heading2",view:{name:"p",styles:{"font-size":"16px","line-height":"20px"}},title:"Nội dung"},{model:"heading3",view:{name:"p",styles:{"font-size":"14px ","line-height":"20px"}},title:"Nội dung phụ"}]},toolbar:{items:["heading","|","imageInsert","|","fontColor","|","bold","italic","link","bulletedList","numberedList","|","fontBackgroundColor","fontSize","removeFormat","|","indent","outdent","|","blockQuote","undo","redo"]},language:"vi",image:{toolbar:["imageResize","|","imageTextAlternative","linkImage"],styles:["alignLeft","alignCenter","alignRight"],resizeOptions:[{name:"imageResize:original",label:"Original",value:null},{name:"imageResize:25",label:"25%",value:"25"},{name:"imageResize:50",label:"50%",value:"50"},{name:"imageResize:75",label:"75%",value:"75"}]},fontSize:{options:[14,20,28,36,50]},fontColor:{colors:[{color:"hsl(226, 12%, 23%)",label:"Đen"},{color:"hsl(231, 4%, 58%)",label:"Xám"},{color:" hsl(208, 100%, 50%)",label:"Xanh dương"}]},licenseKey:""}).then(e=>{window.editor_en=e,editor_en.model.document.on("change:data",e=>{sessionStorage.setItem("data_ckeditor_en",editor_en.getData())})}).catch(e=>{console.error("Oops, something went wrong!"),console.error("Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:"),console.warn("Build id: hdzm8em2f6q7-v1jpqlu9572o"),console.error(e)});';
      document.body.appendChild(script4);
    }
  }, [ListLanguage]);
  useEffect(() => {
    const temp = [];
    let active = false;
    if (props.template) {
      temp.push(temp);
      if (props.template.vi) {
        temp.push(props.template.vi);
        !active && setLanguageActive('vn');
        active = true;
      }
      if (props.template.th) {
        temp.push(props.template.th);
        !active && setLanguageActive('th');
        active = true;
      }
      if (props.template.en) {
        temp.push(props.template.en);
        !active && setLanguageActive('en');
        active = true;
      }
      if (!props.template.vi && !props.template.th && !props.template.en) {
        temp.push(props.template);
      }
    }

    setTemplates(temp);
  }, [props.template]);

  const handleCheckFile = type => {
    if (!type.endsWith('.jpg') && !type.endsWith('.jpeg') && !type.endsWith('.gif') && !type.endsWith('.png')) {
      return false;
    }
    return true;
  };
  const validate = request => {
    if (request.thumb_url && !handleCheckFile(request.thumb_url)) {
      toast.error('Ảnh giao diện thông báo không đúng định dạng');
      return false;
    }
    return true;
  };
  const hanldegetCK = language => {
    const valueCkeditor = sessionStorage.getItem('data_ckeditor_' + language);
    let ck5 = '';
    if (valueCkeditor) {
      const meta = '<meta name="viewport" content="width=device-width, initial-scale=1.0">';
      ck5 =
        '<div style="margin: 8px"> ' +
        valueCkeditor
          .replace(/<img /g, '<img style="width:100%;display: block;margin-left: auto; margin-right: auto;" ')
          .replace(/<figure /g, '<figure style="width:100%; margin: 1em 0" ') +
        meta +
        ' </div>';
    }
    return ck5;
  };
  const saveTemplate = (event, values, language): TemplateModel => {
    const metaFields = [];
    const html = '<div style="margin: 8px"> <p>123123</p><meta name="viewport" content="width=device-width, initial-scale=1.0"> </div>';

    if (values['url_content_' + language]) {
      metaFields.push({
        multiple: false,
        data_type: 'STRING',
        meta_key: 'url_content',
        meta_value: values['url_content_' + language],
        meta_values: []
      });
    }

    if (values['external_url_' + language]) {
      metaFields.push({
        multiple: false,
        data_type: 'STRING',
        meta_key: 'external_url',
        meta_value: values['external_url_' + language],
        meta_values: []
      });
    }

    if (values['content_screen_' + language]) {
      metaFields.push({
        multiple: false,
        data_type: 'STRING',
        meta_key: 'content_screen_2',
        meta_value: values['content_screen_' + language],
        meta_values: []
      });
    }

    if (hanldegetCK(language) && hanldegetCK(language) !== html) {
      metaFields.push({
        multiple: false,
        data_type: 'STRING',
        meta_key: 'html_content',
        meta_value: hanldegetCK(language),
        meta_values: []
      });
    }

    const temp = {
      name: values['name'],
      target_channel: 'MOBILE_NOTIFICATION',
      subject: values['subject_' + language],
      content: values['content_' + language],
      meta_fields: metaFields,
      language: language === 'vn' ? 'VIETNAMESE' : language === 'th' ? 'THAI' : 'ENGLISH',
      thumb_url: values['thumb_url_' + language] ? values['thumb_url_' + language] : null,
      type: 'NOTI'
    };

    if (!validate(temp)) {
      return null;
    }

    return temp;
  };

  const removePropty = values => {
    const rs = {
      name: values['name'],
      target_channel: values.target_channel,
      subject: values['subject'],
      content: values['content'],
      meta_fields: values['meta_fields'],
      language: values.language,
      thumb_url: values.thumb_url,
      type: 'NOTI'
    };
    return rs;
  };

  const hanldeSaveTemp = (e, v) => {
    let root = true;
    let templateUp = null;
    const vnTem = saveTemplate(e, v, 'vn');
    const thTem = saveTemplate(e, v, 'th');
    const enTem = saveTemplate(e, v, 'en');
    if (root && vnTem !== null && ListLanguage.includes('vn')) {
      vnTem.vi = ListLanguage.includes('vn') ? removePropty(vnTem) : null;
      vnTem.th = ListLanguage.includes('th') ? removePropty(thTem) : null;
      vnTem.en = ListLanguage.includes('en') ? removePropty(enTem) : null;
      root = false;
      templateUp = vnTem;
    } else if (root && thTem !== null && ListLanguage.includes('th')) {
      thTem.vi = ListLanguage.includes('vn') ? removePropty(vnTem) : null;
      thTem.th = ListLanguage.includes('th') ? removePropty(thTem) : null;
      thTem.en = ListLanguage.includes('en') ? removePropty(enTem) : null;
      root = false;
      templateUp = thTem;
    } else if (root && enTem !== null && ListLanguage.includes('en')) {
      enTem.vi = ListLanguage.includes('vn') ? removePropty(vnTem) : null;
      enTem.th = ListLanguage.includes('th') ? removePropty(thTem) : null;
      enTem.en = ListLanguage.includes('en') ? removePropty(enTem) : null;
      templateUp = enTem;
    }

    props.updateTemplate(props.match.params.id, templateUp);
  };

  const removeLanguage = (e, v) => {
    const filter = ListLanguage.filter(f => {
      return f !== v;
    });
    setListLanguage(filter);
    setLanguageActive(filter[0]);
  };

  const addLanguage = e => {
    /* eslint-disable no-debugger, no-console */
    const temp = ListLanguage;
    if (e.target.value !== '') {
      if (!temp.includes(e.target.value)) {
        temp.push(e.target.value);
      }
    }
    setListLanguage([...temp]);
    setLanguageActive(e.target.value);
  };

  // const handleDelete = () => {
  //   props.deleteTemplate(match.params.id);
  //   handleonModalClick();
  //   props.history.push('/admin/template');
  // };
  return (
    <div id="template-content-sw">
      {ListLanguage && (
        <AvForm onValidSubmit={hanldeSaveTemp}>
          <SWHeader title="Thêm mới nội dung thông báo" breadcrumb={breadcrumb} button={buttons} />
          <div className="top-content">
            <AvGroup row>
              <Label for="name" sm={10}>
                Tên thông báo<Label className="star">*</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="text"
                  name="name"
                  id="name"
                  value={props.template !== null && props.template !== undefined ? (props.template.name ? props.template.name : '') : ''}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tên thông báo không được để trống'
                    },
                    maxLength: {
                      value: 255,
                      errorMessage: 'số ký tự phải nhỏ hơn 255.'
                    }
                  }}
                />
              </Col>
            </AvGroup>
          </div>
          <div>
            <div className="create-template-sw">
              <div className="tab-choose-language">
                <Nav tabs className="">
                  <div className="nav-group">
                    {ListLanguage.map((v, key) => {
                      return (
                        <NavItem key={key}>
                          <NavLink
                            className={classnames({ active: languageActive === v })}
                            onClick={() => {
                              toggle(v);
                            }}
                          >
                            {v === 'vn' ? 'Tiếng Việt' : v === 'th' ? 'Tiếng Thái' : 'Tiếng Anh'}
                          </NavLink>
                        </NavItem>
                      );
                    })}
                  </div>
                  {ListLanguage && ListLanguage.length < 3 && (
                    <NavItem className="select-box">
                      <div>
                        <select className="select-language" defaultValue="" onChange={addLanguage}>
                          <option value="" hidden>
                            Thêm ngôn ngữ
                          </option>
                          {dropList.map((e, index) => {
                            if (!ListLanguage.includes(e)) {
                              if (e === 'vn') return <option value="vn">Tiếng Việt</option>;
                              if (e === 'en') return <option value="en">Tiếng Anh</option>;
                              if (e === 'th') return <option value="th">Tiếng Thái</option>;
                            }
                          })}
                        </select>
                      </div>
                    </NavItem>
                  )}
                </Nav>
                <TabContent activeTab={languageActive}>
                  {ListLanguage.map(v => {
                    return (
                      <TabPane key={v} tabId={v}>
                        <Row>
                          <Col sm="12">
                            <LanguageForm type={v} remove={removeLanguage} listLanguage={ListLanguage} data={templates} />
                          </Col>
                        </Row>
                      </TabPane>
                    );
                  })}
                </TabContent>
              </div>
            </div>
          </div>

          {/**/}
        </AvForm>
      )}
    </div>
  );
};

const mapDispatchToProps = { createTemplate, getTemplate, updateTemplate, deleteTemplate, reset, changeDisplayBox };
const mapStateToProps = (storeState: IRootState) => ({
  template: storeState.template.template
});

type DispatchProps = typeof mapDispatchToProps;
type StateProps = ReturnType<typeof mapStateToProps>;
export default connect(mapStateToProps, mapDispatchToProps)(TemplateUpdate);
