/* eslint-disable */
export const errorVN = message => {
  switch (message) {
    case 'Template not found':
      return 'Nội dung thông báo không tồn tại';
    case 'The campaign has already closed':
      return 'Kịch bản này đã bị đóng';
    case 'The campaign has already active':
      return 'Kịch bản này đã hoạt động';
    case 'The scheduled date is too far':
      return 'Lịch hẹn noti không được gửi quá 1 tuần';
    case 'Email is already in use!':
      return 'Email này đã được sử dụng!';
    case 'Login name already used!':
      return 'Tên tài khoản đã bị trùng';
    case `must match \"^[_.@A-Za-z0-9-]*$\"`:
      return 'Tên tài khoản không hợp lệ';
    case 'Invalid credentials':
      return 'Tài khoản đăng nhập không chính xác';
    case 'The account has been locked':
      return 'Tài khoản của bạn đã bị khóa';
    default:
      return null;
  }
};
