import { DataTB, IListNotiFnb } from ".";

export interface IDataCreateFnb {
    
}

 export const dataTB: DataTB[] = [
    {
      width: '10%',
      title: 'ID'
    },
    {
      width: '30%',
      title: 'Tiêu đề gửi'
    },
    {
      width: '30%',
      title: 'Tên mẫu'
    },
    {
      width: '15%',
      title: 'Ngày tạo'
    },
    {
      width: '15%',
      title: 'Chỉnh sửa cuối'
    }
  ];