import React from 'react';

import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, Row, Col } from 'reactstrap';
import { AvForm, AvField, AvGroup, AvInput } from 'availity-reactstrap-validation';
import './login.scss'

export interface ILoginModalProps {
  loginError: boolean;
  handleLogin: Function;
}

class LoginModal extends React.Component<ILoginModalProps> {

  handleSubmit = (event, errors, { username, password, rememberMe }) => {
    const { handleLogin } = this.props;
    handleLogin(username, password, rememberMe);
  };

  render() {
    return (
      <div className="login-page">
        <div className="login-form">
          <AvForm onSubmit={this.handleSubmit}>
            <h5>Đăng nhập</h5>
            <Row>
              <Col md="12">
                <AvField
                  style={{ border: "1px solid #444" }}
                  name="username"
                  label="Tài khoản"
                  required
                  errorMessage="Tài khoản không được để trống!"
                  autoFocus
                />
                <AvField
                  style={{ border: "1px solid #444" }}
                  name="password"
                  type="password"
                  label="Mật khẩu"
                  required
                  errorMessage="Mật khẩu không được để trống!"
                />
                <AvGroup check inline>
                  <AvInput type="checkbox" name="rememberMe" />
                  <Label className="form-check-label">Lưu đăng nhập</Label>
                </AvGroup>
              </Col>
            </Row>

            <Button className="float-right" color="primary" type="submit">
              Đăng nhập
            </Button>
          </AvForm>
        </div>
      </div>
    );
  }
}

export default LoginModal;
