import SWebButton from 'app/shared/SWebButton/SWebButton';
import React, { ReactElement, useEffect, useState } from 'react';
import './style.scss';

import { dataTB } from './data';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import { Link, useHistory } from 'react-router-dom';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import notiFnb from 'app/services/noti-fnb';
import { formatDateHHMM } from 'app/shared/util/date-utils';

export interface DataTB {
  width: string;
  title: string | ReactElement;
}

export interface DataTBSort {
  width: string;
  title: string;
  field: string;
}

export interface IListNotiFnb {
  id: number;
  subject: string;
  name: string;
  created_date: string;
  last_modified_date: string;
}

export default function ContentFnb() {
  const [page, setPage] = useState<number>(1);
  const [listPage, setlistPage] = useState<number>(7);
  const [totalItem, settotalItem] = useState<number>(0);

  const history = useHistory();
  const [list, setList] = useState<IListNotiFnb[]>([]);

  useEffect(() => {
    notiFnb.getList(page - 1).then(re => {
      setList(re.data.templates);
    });
  }, [page]);

  useEffect(() => {
    notiFnb.page().then(re => {
      settotalItem(re.data.count);
    });
  }, [page]);

  const handleClick = () => {
    history.push('content-fnb/create');
  };
  const buttons: IButton[] = [
    {
      text: '+ Thêm nội dung',
      color: 'primary',
      onClick: handleClick
    }
  ];

  return (
    <div id="sWeb-noti-fnb">
      <SWHeader title="Quản lý nội dung thông báo FnB" button={buttons} />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th key={i} style={{ width: d.width }}>
                    {d.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {list.map((d, i) => (
                <tr key={i}>
                  <td>{d.id ? <Link to={`/admin/content-fnb/${d.id}`}>{d.id}</Link> : '--'}</td>
                  <td>{d.name ? d.name : '--'}</td>
                  <td>{d.subject ? d.subject : '--'}</td>
                  <td>{d.created_date ? formatDateHHMM(d.created_date) : '--'}</td>
                  <td>{d.last_modified_date ? formatDateHHMM(d.last_modified_date) : '--'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SWebPagination page={page} totalItem={totalItem} itemPerPage={20} setPage={setPage} />
      </div>
    </div>
  );
}
