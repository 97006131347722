import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Table } from 'reactstrap';
import { getSortState } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './user-management.scss';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { getUsers, updateUser, getUser, updatePageActive } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { convertDateTimeFromServer } from 'app/shared/util/date-utils';
import Pagination from 'react-js-pagination';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { DataTB } from 'app/entities/content-fnb';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';

const dataTB: DataTB[] = [
  {
    width: '25%',
    title: 'Tài khoản'
  },
  {
    width: '25%',
    title: 'Email'
  },
  {
    width: '20%',
    title: 'Trạng thái'
  },
  {
    width: '15%',
    title: 'Ngày tạo'
  },
  {
    width: '15%',
    title: 'Người tạo'
  }
];

export interface IUserManagementProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

const UserManagement = (props: IUserManagementProps) => {
  const { users, activePage, totalItems } = props;

  const [page, setpage] = useState<number>(0);
  

  useEffect(() => {
    props.getUsers(activePage - 1);
    window.scrollTo(0, 0);
  }, [activePage, totalItems]);

  const history = useHistory();

  const handleClick = () => {
    history.push('/admin/user-management/create');
  };

  const buttons: IButton[] = [
    {
      text: '+ Thêm mới',
      color: 'primary',
      onClick: handleClick
    }
  ];
  return (
    <div>
      <SWHeader title="Người dùng" button={buttons} />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th key={i} style={{ width: d.width }}>
                    {d.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users.map((d, i) => (
                <tr key={i}>
                  <td>{<Link to={`/admin/user-management/update/${d.login}`}>{d.login}</Link>}</td>
                  <td>{d.email}</td>
                  <td>
                    {d.activated ? (
                      <div className="status-user-active">Đang hoạt động</div>
                    ) : (
                      <div className="status-user-close">Đã khóa</div>
                    )}
                  </td>
                  <td>{d.created_date ? convertDateTimeFromServer(d.created_date) : '-'}</td>
                  <td>{d.created_by}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {/* <Table responsive >
          <thead>
            <tr>
              <th className="hand" > Tài khoản </th>
              <th className="hand">Email</th>
              <th className="hand">Trạng thái </th>
              <th className="hand">Ngày tạo </th>
              <th className="hand"> Người tạo </th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, i) => (
              <tr id={user.login} key={`user-${i}`}>
                <td ><Link to={`/admin/user-management/update/${user.login}`}>{user.login}</Link></td>
                <td >{user.email}</td>
                <td className="center-text">
                  {
                    user.activated ? <div className="status-user-active">Đang hoạt động</div> : <div className="status-user-close">Đã khóa</div>
                  }
                </td>
                <td className="center-text">{user.created_date ? convertDateTimeFromServer(user.created_date) : "-"}</td>
                <td className="center-text">{user.created_by}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
        {users.length <= 0 ? null : (
          <>
            <SWebPagination page={page} totalItem={totalItems} setPage={setpage} itemPerPage={ITEMS_PER_PAGE} />
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  users: storeState.userManagement.users,
  totalItems: storeState.userManagement.totalItems,
  account: storeState.authentication.account,
  activePage: storeState.userManagement.activePage
});

const mapDispatchToProps = { getUsers, updateUser, getUser, updatePageActive };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
