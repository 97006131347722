import { DiscountCampaignState } from 'app/entities/DiscountCampaign/DiscountCampaign.reducer';
import { metaFields } from './template.model';

export type IPriceRule = {
  usage_limit: number;
  usage_in_hours: number;
  discount_type: string;
  discount_value: number;
  discount_maximum_amount: number;
  multiple_time_enabled: boolean;
  shipping_providers: Array<string>;
};
export type IMobileNotification = {
  template_id?: string;
  subject: string;
  content: string;
  meta_fields: Array<metaFields>;
  category: string;
  content_screen: string;
  restrict_operating_systems: Array<string>;
  restrict_app_ids: Array<string>;
  visibility: string;
  thumb_url: string;
};
export interface IDiscountCampaign {
  id: string;
  title: string;
  price_rule?: IPriceRule;
  mobile_notification?: IMobileNotification;
  status: string;
  created_by: string;
  created_date: Date;
  last_modified_by: string;
  last_modified_date: Date;
  discount_campaign_histories: Array<DiscountCampaignHistory>;
}
export const MobileNotificationDefaultValue: IMobileNotification = {
  template_id: '',
  subject: '',
  content: '',
  meta_fields: null,
  category: '',
  content_screen: '',
  restrict_operating_systems: null,
  restrict_app_ids: null,
  visibility: '',
  thumb_url: ''
};
export const defaultValue: IDiscountCampaign = {
  id: '',
  title: '',
  price_rule: null,
  mobile_notification: MobileNotificationDefaultValue,
  status: '',
  created_by: '',
  created_date: null,
  last_modified_by: '',
  last_modified_date: null,
  discount_campaign_histories: []
};

type CampaignHistoryId = {
  discount_campaign_id: string;
  time_label: number;
};

export type DiscountCampaignHistory = {
  campaign_history_id: CampaignHistoryId;
  total_seen: number;
};
