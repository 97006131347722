import React, { Fragment } from 'react';

const forbidden=()=>{
    return(
        <Fragment>
            <div >
                <div className="forbidden-st forb-box">
                <img src="content/images/ac.svg"/>
                </div>
                <div className="forbidden-st">
                    <p>Bạn không có quyền truy cập vào trang này</p>
                </div>
            </div>
        </Fragment>
    )
}
export default forbidden;