import React, { Fragment } from 'react';
import { DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink as Link } from 'react-router-dom';
import './menu.scss';

import { NavDropdown } from './menu-components';
import { connect } from 'react-redux';
import { IRootState } from 'app/shared/reducers';
import { logout, login } from '../../reducers/authentication';
import {changeShowModal} from '../../../modules/account/password/password.reducer';
import PasswordPage from '../../../modules/account/password/password'




const AccountMenu = (props) => {
  const accountMenuItemsAuthenticated = (
    <>
       {/* <MenuItem icon="wrench" to="/account/settings">
        Settings
      </MenuItem> */}
      <DropdownItem onClick={props.changeShowModal}  >
        <FontAwesomeIcon icon="book" fixedWidth />
        Đổi Mật khẩu
      </DropdownItem>
      <DropdownItem tag={Link} to="/logout"  >
        <FontAwesomeIcon icon="sign-out-alt" fixedWidth />
        Đăng xuất
      </DropdownItem>
    </>
  );
  const accountMenuItems = (
    <>
      <DropdownItem tag={Link} to="/login"  >
        <FontAwesomeIcon icon="sign-out-alt" fixedWidth />
        Đăng nhập
      </DropdownItem>
    </>
  );
  return (
  <Fragment>
    <NavDropdown icon="user" name={props.account.first_name} id="account-menu">
      {props.isAuthenticated ? accountMenuItemsAuthenticated : accountMenuItems}
    </NavDropdown>
    <PasswordPage/>
  </Fragment>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
})

const mapDispatchToProps = { logout, login,changeShowModal };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
