import historyFnb from 'app/services/history-fnb';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import SWHeader from 'app/shared/SWHeader/sw-header';
import { formatDateTSHHMM } from 'app/shared/util/date-utils';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { DataTB } from '../content-fnb';
import { IDataScript } from '../script-fnb/data-script-fnb';
import './history-noti-fnb.scss';
const dataTB: DataTB[] = [
  {
    width: '25%',
    title: 'Mã lịch sử thông báo'
  },
  {
    width: '15%',
    title: 'Tên kịch bản'
  },
  {
    width: '15%',
    title: 'Tên thông báo'
  },
  {
    width: '15%',
    title: 'Trạng thái'
  },
  {
    width: '15%',
    title: 'Ngày tạo'
  },
  {
    width: '15%',
    title: 'Ngày gửi '
  }
];
export default function HistoryFnb() {
  const [page, setPage] = useState<number>(1);
  const [totalItem, setTotalItem] = useState<number>(0);
  const [data, setdata] = useState<IDataScript[]>([]);

  useEffect(() => {
    historyFnb.getList(page).then(re => {
      setdata(re.data.notifications);

      setTotalItem(re.data.metadata.total);
    });
  }, [page]);

  const switchStatus = (str: string) => {
    switch (str) {
      case 'completed':
        return <td className="completed">Hoàn thành</td>;
      case 'sending':
        return <td className="sending">Đang gửi</td>;
      case 'error':
        return <td className="error">Gửi lỗi</td>;
      case 'scheduling':
        return <td className="scheduling">Lập lịch gửi</td>;
      case 'cancelled':
        return <td className="cancelled">Hủy lịch gửi</td>;
      default:
        return '--';
    }
  };

  return (
    <div id="noti-history-fnb">
      <SWHeader title="Lịch sử thông báo FnB" />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th key={i} style={{ width: d.width }}>
                    {d.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i}>
                  <td>
                    <Link to={`/admin/history-noti-fnb/${d.client_id}`}>{d.client_id}</Link>
                  </td>
                  <td>{d.name ? d.name : '--'}</td>
                  <td>{d.subject ? d.subject : '--'}</td>
                  {switchStatus(d.status)}
                  <td>{d.created_on ? formatDateTSHHMM(d.modified_on) : '--'}</td>
                  <td>{d.modified_on ? formatDateTSHHMM(d.start_time) : '--'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SWebPagination page={page} itemPerPage={20} totalItem={totalItem} setPage={setPage} />
      </div>
    </div>
  );
}
