import axios from 'axios';
import { Storage } from 'react-jhipster';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { EXPIRED, AUTH_TOKEN_KEY } from 'app/config/constants';

export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  REFRESH_TOKEN: 'authentication/REFRESH_TOKEN',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  ACTION_MENU: 'authentication/ACTION_MENU'
};

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  account: {} as any,
  errorMessage: null as string, // Errors returned from server side
  redirectMessage: null as string,
  sessionHasBeenFetched: false,
  idToken: null as string,
  logoutUrl: null as string,
  menuAction: true as boolean
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer

export default (state: AuthenticationState = initialState, action): AuthenticationState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.REFRESH_TOKEN):
    case REQUEST(ACTION_TYPES.LOGIN):
    case REQUEST(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: true
      };
    case FAILURE(ACTION_TYPES.LOGIN):
      return {
        ...initialState,
        errorMessage: action.payload,
        loginError: true
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        errorMessage: action.payload
      };
    case FAILURE(ACTION_TYPES.REFRESH_TOKEN):
      return {
        ...initialState,
        errorMessage: action.payload,
        loginError: true
      };
    case SUCCESS(ACTION_TYPES.LOGIN):
      return {
        ...state,
        // idToken:action.payload.data.access-token,
        loading: false,
        loginError: false,
        loginSuccess: true
      };
    case SUCCESS(ACTION_TYPES.REFRESH_TOKEN):
      return {
        ...state
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        isAuthenticated: false
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      // const isAuthenticated = action.payload && action.payload.data && action.payload.data.activated;
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        sessionHasBeenFetched: true,
        account: action.payload.data
      };
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        redirectMessage: action.message
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        isAuthenticated: false
      };
    case ACTION_TYPES.ACTION_MENU:
      return {
        ...state,
        menuAction: action.action
      };
    default:
      return state;
  }
};

export const displayAuthError = message => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession = () => (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get('services/authentication/api/account')
  });
};

export const actionMenu = (action: boolean) => ({
  type: ACTION_TYPES.ACTION_MENU,
  action
});

export const login = (username, password, rememberMe) => async dispatch => {
  localStorage.clear();
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post('auth/login', { username, password, rememberMe })
  });
  Storage.local.set(AUTH_TOKEN_KEY, result.value.data.access_token);

  await dispatch(getSession());
};
export const clearAuthentication = () => ({
  type: ACTION_TYPES.CLEAR_AUTH
});
export const logout = () => async dispatch => {
  localStorage.clear();
  return await dispatch({
    type: ACTION_TYPES.LOGOUT,
    payload: axios.post('auth/logout')
  });
};
