/* eslint-disable no-useless-escape */
import { useCallback, useEffect } from 'react';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export function ignoreSpaces(str: string) {
  if (str) {
    const newData = str.trim();
    return newData.replace(/\s+/g, ' ');
  }
}

export function isEmptyString(str: string) {
  if (str && str.trim()) {
    return false;
  } else {
    return true;
  }
}

export function replaceSpecialCharacterToSpace(str: string) {
  return str.replace(/[^\w\s]/gi, ' ');
}

export const useOnClickOutside = (ref, handler, clas: string) => {
  const listener = event => {
    if (!ref.current || ref.current.contains(event.target) || event.path.filter(i => i.className === clas).length !== 0) {
      return;
    }
    handler(event);
  };
  useEffect(() => {
    document.addEventListener('mousedown', listener);
    return () => {
      document.removeEventListener('mousedown', listener);
    };
  }, [ref, handler]);
};

export const useDebouncedEffect = (effect, delay, deps) => {
  const callback = useCallback(effect, deps);

  useEffect(() => {
    const handler = setTimeout(() => {
      callback();
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [callback, delay]);
};

export function cleanData(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined || obj[propName] === 0) {
      delete obj[propName];
    }
  }
}

export function isObjEqual(object1, object2) {
  return object1 === object2;
}

export function removeAccents(str: string) {
  const AccentsMap = [
    'aàảãáạăằẳẵắặâầẩẫấậ',
    'AÀẢÃÁẠĂẰẲẴẮẶÂẦẨẪẤẬ',
    'dđ',
    'DĐ',
    'eèẻẽéẹêềểễếệ',
    'EÈẺẼÉẸÊỀỂỄẾỆ',
    'iìỉĩíị',
    'IÌỈĨÍỊ',
    'oòỏõóọôồổỗốộơờởỡớợ',
    'OÒỎÕÓỌÔỒỔỖỐỘƠỜỞỠỚỢ',
    'uùủũúụưừửữứự',
    'UÙỦŨÚỤƯỪỬỮỨỰ',
    'yỳỷỹýỵ',
    'YỲỶỸÝỴ'
  ];
  for (let i = 0; i < AccentsMap.length; i++) {
    const re = new RegExp('[' + AccentsMap[i].substr(1) + ']', 'g');
    const char = AccentsMap[i][0];
    str = str.replace(re, char);
  }
  return str;
}

export function removeVietnameseTones(str: string, allowPath?: boolean) {
  if (str) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a');
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e');
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i');
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o');
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u');
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y');
    str = str.replace(/đ/g, 'd');
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, 'A');
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, 'E');
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, 'I');
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, 'O');
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, 'U');
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, 'Y');
    str = str.replace(/Đ/g, 'D');
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ''); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ''); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, ' ');
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    if (allowPath) {
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    } else {
      str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, ' ');
    }
  }

  return str;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function params(prmeters) {
  const query = Object.keys(prmeters)
    .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(prmeters[k]))
    .join('&');
  return query;
}

export function trimChar(str: string, ch: string) {
  let start = 0,
    end = str.length;

  while (start < end && str[start] === ch) ++start;

  while (end > start && str[end - 1] === ch) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

export function convertSlugs(str: string, allowPath?: boolean) {
  if (str) {
    return trimChar(
      removeVietnameseTones(str, allowPath)
        .split(/\s+/)
        .join('-'),
      '-'
    );
  } else {
    return '';
  }
}

export const classDivisionCate = (listCategory: any[]) => {
  const map = {};
  let node;

  for (let i = 0; i < listCategory.length; i++) {
    map[listCategory[i].id] = i;
    listCategory[i].kids = [];
  }
  for (let i = 0; i < listCategory.length; i++) {
    node = listCategory[i];
    if (node.parent_id !== null) {
      if (listCategory[map[node.parent_id]]?.kids !== null && listCategory[map[node.parent_id]]?.kids !== undefined) {
        listCategory[map[node.parent_id]].kids.push(node);
      } else {
        if (map[node.parent_id]) {
          listCategory[map[node.parent_id]].kids = [];
          listCategory[map[node.parent_id]]?.kids.push(node);
        }
      }
    }
  }
  return listCategory.filter(x => !x.parent_id);
};

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export function randstr(prefix: string) {
  return Math.random()
    .toString(36)
    .replace('0.', prefix || '');
}

export function dataBeautification(obj: {}) {
  const data = JSON.parse(JSON.stringify(obj));
  for (const propName in obj) {
    if (obj[propName] && typeof obj[propName] === 'string') {
      data[propName] = ignoreSpaces(obj[propName]);
    }
  }
  return data;
}

export const copyToClipboard = (str: string) => {
  const textField = document.createElement('textarea');
  textField.innerText = str;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const genRole = (role: number) => {
  switch (role) {
    case 1:
      return 'Editor';
    case 2:
      return 'ADMIN';
    case 3:
      return 'Owner';
    default:
      return '';
  }
};

export const regexEmailSapo = (str: string) => {
  const email = /^(([^<>()[\]\.,;:\s@\"\'\*\!\+\=\%]+(\.[^<>()[\]\.,;:\s@\"\']+)*)|(\".+\"))(@)((sapo)|(gmail))(\.)((vn)|(com))$/i;
  const regex = new RegExp(email);
  return regex.test(str);
};

const expression = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g;
export const regexUrl = new RegExp(expression);

export function convertHTMLEncode(str: string) {
  str = str.replace(/&/g, '&amp;');
  str = str.replace(/>/g, '&gt;');
  str = str.replace(/</g, '&lt;');
  str = str.replace(/"/g, '&quot;');
  str = str.replace(/'/g, '&#039;');
  return str;
}

export const exportToCSV = (csvData: {}[], fileName: string) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export enum EXPORT_MODE {
  ANY,
  ALL
}
