import { isPromise } from 'react-jhipster';
import { toast } from 'react-toastify';
import { errorVN } from 'app/shared/util/error-vn';
/* eslint no-console: off */
/* eslint complexity: ["error",43]*/

const addErrorAlert = (message, key?, data?) => {
  toast.error(message, key);
};
export default () => next => action => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then(response => {
      if (action.meta && action.meta.successMessage) {
        toast.success(action.meta.successMessage);
      } else if (response && response.action && response.action.payload && response.action.payload.headers) {
        const headers = response.action.payload.headers;
        let alert: string = null;
        Object.entries(headers).forEach(([k, v]: [string, string]) => {
          if (k.toLowerCase().endsWith('app-alert')) {
            alert = v;
          }
        });
        if (alert) {
          toast.success(alert);
        }
      }
      return Promise.resolve(response);
    })
    .catch(error => {
      const response = error.response;
      if (response.status === 401 && response.data && response.data.path && response.data.path.includes('/auth/login')) {
        const ms = errorVN(response.data.detail);
        addErrorAlert(ms);
      } else if (response.status !== 401) {
        if (action.meta && action.meta.errorMessage) {
          toast.error(action.meta.errorMessage);
        } else if (error && error.response) {
          const data = response.data;
          if (!(response.status === 401 && (error.message === '' || (data && data.path && data.path.includes('/api/account'))))) {
            if (data && data.path && data.path.includes('/auth/refresh')) {
              return;
            }
            let i;
            switch (response.status) {
              // connection refused, server not reachable
              case 0:
                addErrorAlert('Server not reachable', 'error.server.not.reachable');
                break;

              case 400: {
                const headers = Object.entries(response.headers);
                let errorHeader = null;
                let entityKey = null;
                headers.forEach(([k, v]: [string, string]) => {
                  if (k.toLowerCase().endsWith('app-error')) {
                    errorHeader = v;
                  } else if (k.toLowerCase().endsWith('app-params')) {
                    entityKey = v;
                  }
                });
                if (errorHeader) {
                  const entityName = entityKey;
                  const ms = errorVN(errorHeader);
                  addErrorAlert(ms ? ms : errorHeader, errorHeader, { entityName });
                } else if (data !== '' && data.fieldErrors) {
                  const fieldErrors = data.fieldErrors;
                  for (i = 0; i < fieldErrors.length; i++) {
                    const fieldError = fieldErrors[i];
                    if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                      fieldError.message = 'Size';
                    }
                    // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                    const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                    const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                    addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`, { fieldName });
                  }
                } else if (data !== '' && data.message) {
                  addErrorAlert(data.message, data.message, data.params);
                } else {
                  addErrorAlert(data);
                }
                break;
              }
              case 404:
                addErrorAlert('Nội dung không tim thấy');
                break;
              case 403:
                addErrorAlert('Bạn không có quyền thực hiện hành động này');
                break;
              case 422:
                if (data && data.field_errors) {
                  const fieldErrors = data.field_errors;
                  for (i = 0; i < fieldErrors.length; i++) {
                    const fieldError = fieldErrors[i];
                    const ms = errorVN(fieldError.message);
                    const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                    const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                    addErrorAlert(`"${fieldName}":${ms ? ms : fieldError.message}`);
                  }
                }

                addErrorAlert(data);
                break;
              default:
                if (data !== '' && data.message) {
                  addErrorAlert(data.message);
                } else {
                  addErrorAlert(data);
                }
            }
          }
        } else if (error && error.config && error.config.url === 'api/account' && error.config.method === 'get') {
          /* eslint-disable no-console */
          console.log('Authentication Error: Trying to access url api/account with GET.');
        } else if (error && error.message) {
          toast.error(error.message);
        } else {
          toast.error('Unknown error!');
        }
        return Promise.reject(error);
      }
    });
};
