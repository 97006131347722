import React, { useEffect, useState, Fragment } from 'react';
import {
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';
import { AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import './DiscountCampaign.scss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import {
  createDicountCampaign,
  getDiscountCampaign,
  reset,
  activeCampaign,
  activeCampaignByCriteria,
  updateStatusCampaign
} from './DiscountCampaign.reducer';
import { getTemplate } from './../template/Template.reducer';
import { RouteComponentProps, Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import TextareaAutosize from 'react-textarea-autosize';
import { STATUS_CAMPAIGN } from 'app/config/constants';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import SWCheckbox from 'app/shared/SWCheckbox/sw-checkbox';
import SWRadio from 'app/shared/SWRadio/sw-radio';
import SWebButton from 'app/shared/SWebButton/SWebButton';
import { formatDateHHMM } from 'app/shared/util/date-utils';
import VerticalBar from './DiscountCampaignChart';

const breadcrumb: IBreadcrumbs = {
  link: '/admin/discount-campaign',
  title: 'Quay về trang danh sách'
};

export interface IDIscountCampaign extends StateProps, DispatchProps, RouteComponentProps<{ key: string; id: string }> {}
const DiscountCampaignActive = (props: IDIscountCampaign) => {
  const { discountCampaign, template } = props;

  const history = useHistory();
  const [file, setFile] = useState(null);
  const [time, setTime] = useState<boolean>(false);
  const [typeUpload, setTypeUpload] = useState<string>('');
  const [packageTypes, setPackageTypes] = useState<string>('');
  const [packageNames, setPackageNames] = useState<string>('');
  const [fromCreated, setFromCreated] = useState<Date>(null);
  const [toCreated, setToCreated] = useState<Date>(null);
  const [owners, setOwners] = useState<Array<string>>([]);
  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [restrictOperatingSystems, setRestrict] = useState([]);
  const [timeparam, setTimeParam] = useState<Date>(null);
  const [country, setCountry] = useState(['VIETNAM']);
  const [discountType, setDiscountType] = useState<string>(
    discountCampaign.price_rule ? discountCampaign.price_rule.discount_type : 'PERCENTAGE'
  );
  const [isScheduled] = useState<boolean>(props.match.params.key === 'detail' ? true : false);
  const [modal, setModal] = useState<boolean>(false);

  const [modalPackageName, setModalPackageName] = useState(false);
  let myVar = null;
  let myTimer = null;
  let myStopFunction = null;
  let getCheckedBoxes = null;
  const togglePackageName = () => {
    setModalPackageName(!modalPackageName);

    myVar = setInterval(myTimer, 100);
  };

  myTimer = () => {
    if (document.getElementById('modal-list-package-name')) {
      if (packageNames) {
        const checkedBoxes = packageNames.split(',');
        for (let i = 0; i < checkedBoxes.length; i++) {
          const cbo = document.getElementById(checkedBoxes[i]) as HTMLInputElement;
          cbo.checked = true;
        }
      }
      myStopFunction();
    }
  };

  myStopFunction = () => {
    clearInterval(myVar);
  };

  useEffect(() => {
    props.getDiscountCampaign(props.match.params.id);
  }, []);
  const handleOnChange = e => {
    setFile(e.target.files[0]);
  };

  const handleDiscounttype = e => {
    setDiscountType(e.target.value);
  };

  const handleSetCountry = e => {
    const countryAdd = e.target.value;
    if (country.includes(countryAdd)) {
      setCountry(country.filter(f => f !== countryAdd));
    } else {
      setCountry(country.concat(countryAdd));
    }
  };

  const handleFromCreated = e => {
    setFromCreated(e.target.value);
  };

  useEffect(() => {
    if (discountCampaign && discountCampaign.mobile_notification && discountCampaign.mobile_notification.template_id !== '') {
      props.getTemplate(discountCampaign.mobile_notification.template_id);
    }
  }, [discountCampaign]);

  const handleToCreated = e => {
    setToCreated(e.target.value);
  };

  const handleOwners = e => {
    const ownerAdd = e.target.value;
    if (owners.includes(ownerAdd)) {
      setOwners(owners.filter(f => f !== ownerAdd));
    } else {
      setOwners(owners.concat(ownerAdd));
    }
  };

  const validate = () => {
    if (typeUpload) {
      if (typeUpload === 'file') {
        if (!file) {
          toast.error('Bạn chưa nhập file.Vui lòng nhập file để kích hoạt');
          return false;
        }
        if (file.size / 1024 / 1024 > 20) {
          toast.error('Kích thước file không được lớn hơn 20mb');
          return false;
        }
        if (!file.name.endsWith('.csv')) {
          toast.error('Chỉ cho phép nhập file .csv');
          return false;
        }
      }
    } else {
      toast.error('Chọn kiểu push noti');
      return false;
    }

    return true;
  };
  const handleTime = () => {
    setTime(!time);
  };

  const handleTypeUpload = e => {
    setTypeUpload(e.target.value);
  };

  const handlePackageTypes = e => {
    const checkedBoxes = getCheckedBoxes('packageType');
    if (checkedBoxes != null) {
      setPackageTypes(checkedBoxes.join());
    } else {
      setPackageTypes('');
    }
  };

  const handlePackageNames = () => {
    const checkedBoxes = getCheckedBoxes('packageNames');
    setPackageNames(checkedBoxes.join());
    setModalPackageName(!modalPackageName);
  };
  const handleActiveModal = () => {
    setActiveModal(!activeModal);
  };
  const handTimeParamOnchange = e => {
    setTimeParam(e.target.value);
  };
  const handleCheckValue = () => {
    if (discountCampaign.mobile_notification !== null) {
      if (discountCampaign.mobile_notification.restrict_operating_systems === null) {
        return true;
      } else {
        return false;
      }
    }
    return true;
  };
  const handleonModalClick = () => {
    setModal(!modal);
  };
  const handleStatusCampaign = () => {
    props.updateStatusCampaign(discountCampaign.id, discountCampaign.status);
    handleonModalClick();
  };
  const handlePushNotification = () => {
    if (!validate()) {
      return;
    }
    if (time && !timeparam) {
      toast.error('Thời gian đặt lịch chưa hợp lệ');
      return;
    }
    if (typeUpload) {
      if (typeUpload === 'file') {
        props.activeCampaign(props.match.params.id, file, timeparam);
      } else {
        props.activeCampaignByCriteria(
          props.match.params.id,
          packageNames,
          packageTypes,
          country,
          fromCreated + ':00Z',
          toCreated + ':00Z',
          owners,
          timeparam
        );
      }
      history.push('/admin/discount-campaign');
    }
  };

  getCheckedBoxes = chkboxName => {
    const checkboxes = document.getElementsByName(chkboxName);
    const checkboxesChecked = [];
    for (let i = 0; i < checkboxes.length; i++) {
      const cbo = checkboxes[i] as HTMLInputElement;
      if (cbo.checked) {
        checkboxesChecked.push(cbo.value);
      }
    }
    return checkboxesChecked.length > 0 ? checkboxesChecked : null;
  };
  const buttons: IButton[] = [
    {
      text: discountCampaign.status === STATUS_CAMPAIGN.CLOSED ? 'Mở kịch bản' : 'Đóng kịch bản',
      color: discountCampaign.status === STATUS_CAMPAIGN.CLOSED ? 'primary' : 'danger',
      outLine: true,
      minor: true,
      onClick: handleonModalClick
    },
    {
      text: 'Kích hoạt',
      color: 'primary',
      onClick: handleActiveModal,
      disabled: discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? false : true
      // onDoubleClick: handlePushNotification
    }
  ];
  return (
    <div className="campaign-active-sw">
      <SWHeader title="Chi tiết kịch bản thông báo" breadcrumb={breadcrumb} button={buttons} />
      <div className="body-campaign">
        <div className="box-left">
          <div className="detail-discount-campaign">
            <div className="h5"> Kịch bản thông báo</div>
            <ul>
              <li>
                <div className="title">Tên kịch bản</div>
                <div className="content">{discountCampaign.title}</div>
              </li>
              <li>
                <div className="title">Nội dung gửi</div>
                <div className="content">
                  {discountCampaign.mobile_notification === null ? '' : discountCampaign.mobile_notification.template_id}
                </div>
              </li>
              <li>
                <div className="title">Danh mục</div>
                <div className="content">{!discountCampaign.mobile_notification ? '' : discountCampaign.mobile_notification.category}</div>
              </li>
              <li>
                <div className="title">App nhận thông báo</div>
                <div className="content">
                  {!discountCampaign.mobile_notification ? '' : discountCampaign.mobile_notification.restrict_app_ids}
                </div>
              </li>
              <li>
                <div className="title">Màn hình chi tiết thông báo</div>
                <div className="content">
                  {!discountCampaign.mobile_notification
                    ? ''
                    : !discountCampaign.mobile_notification.content_screen
                    ? ''
                    : discountCampaign.mobile_notification.content_screen}
                </div>
              </li>
              <li>
                <div className="title">Phạm vi hiển thị thông báo</div>
                <div className="content">
                  {discountCampaign.mobile_notification === null
                    ? 'Tất cả'
                    : discountCampaign.mobile_notification.visibility === 'ALERT_ONLY'
                    ? 'Ngoài app'
                    : discountCampaign.mobile_notification.visibility === 'PERSISTENT_ONLY'
                    ? 'Ngoài app'
                    : 'Tất cả'}
                </div>
              </li>
              <li>
                <div className="title">Hệ điều hành</div>
                <div className="d-flex justify-content-around">
                  <SWCheckbox
                    checked={!handleCheckValue() ? discountCampaign.mobile_notification.restrict_operating_systems.includes('IOS') : false}
                    label="IOS"
                    disabled
                  />
                  <SWCheckbox
                    disabled
                    checked={
                      !handleCheckValue() ? discountCampaign.mobile_notification.restrict_operating_systems.includes('ANDROID') : false
                    }
                    label="Android"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div className="down-left">
            <div className="content-detail">
              <div className="h5"> Nội dung thông báo</div>
              <div className="title">Tên thông báo</div>
              <div>{template === null ? '' : template.name}</div>
            </div>
            {!discountCampaign.price_rule ? null : (
              <div className="discount-detail">
                <div className="h5"> Cấu hình khuyến mãi</div>
                <ul>
                  <li>
                    <div className="title">Số lần sử dụng</div>
                    <div className="content">{discountCampaign.price_rule !== null ? discountCampaign.price_rule.usage_limit : ''}</div>
                  </li>
                  <li>
                    <div className="title">Thời gian sử dụng (giờ)</div>
                    <div className="content">{discountCampaign.price_rule !== null ? discountCampaign.price_rule.usage_in_hours : ''}</div>
                  </li>
                  <li>
                    <div className="title">Loại khuyến mãi</div>
                    <div className="content">
                      {discountCampaign.price_rule !== null && discountCampaign.price_rule.discount_type === 'PERCENTAGE'
                        ? 'Theo phần trăm'
                        : 'Theo số tiền'}
                    </div>
                  </li>
                  <li>
                    <div className="title">
                      Giá trị khuyến mãi{' '}
                      {discountCampaign.price_rule && discountCampaign.price_rule.discount_type === 'PERCENTAGE' ? (
                        <Label>(%)</Label>
                      ) : (
                        <Label>(đồng)</Label>
                      )}
                    </div>
                    <div className="content">{discountCampaign.price_rule !== null ? discountCampaign.price_rule.discount_value : '0'}</div>
                  </li>
                  <li>
                    <div className="title">Số tiền giảm tối đa</div>
                    <div className="content">
                      {discountCampaign.price_rule !== null ? discountCampaign.price_rule.discount_maximum_amount : ''}
                    </div>
                  </li>
                  <li>
                    <div className="title">Đơn vị vận chuyển</div>
                    <div className="content">
                      {discountCampaign.price_rule &&
                      discountCampaign.price_rule.shipping_providers &&
                      discountCampaign.price_rule.shipping_providers.length
                        ? discountCampaign.price_rule.shipping_providers.join(', ')
                        : 'Tất cả các đơn vị vận chuyển'}
                    </div>
                  </li>
                </ul>
              </div>
            )}
          </div>
          <div className="seen-noti-chart">
            <div className="chart-header">Báo cáo lượt đọc theo khung giờ</div>
            <div className="chart-content">
              <VerticalBar discountCampaignHistories={discountCampaign.discount_campaign_histories} />
            </div>
            <div className="chart-footer">
              <div className="script-title">Thông tin kịch bản: {discountCampaign.title}</div>
              <div className="row py-4">
                <div className="col-md-4 script-status">
                  Trạng thái:{' '}
                  {discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? (
                    <div className="d-inline status-cp-active">Đang hoạt động</div>
                  ) : (
                    <div className="d-inline status-cp-closed">Ngừng hoạt động</div>
                  )}
                </div>
                <div className="col-md-4 script-total-seen">
                  Lượt đọc: {discountCampaign.discount_campaign_histories.reduce((totalSeen, h) => totalSeen + h.total_seen, 0)}
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  Ngày tạo:{' '}
                  <div className="d-inline script-date">
                    {discountCampaign.created_date ? formatDateHHMM(discountCampaign.created_date.toString()) : '--'}
                  </div>
                </div>
                <div className="col-md-5">
                  Lần chỉnh sửa cuối:{' '}
                  <div className="d-inline script-date">
                    {discountCampaign.last_modified_date ? formatDateHHMM(discountCampaign.last_modified_date.toString()) : '--'}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="box-right">
          <div className="setting-push">
            <AvForm>
              <div className="box-file">
                <div className="title-time">
                  <SWCheckbox onChange={handleTime} label="Lên lịch gửi" />
                </div>
                {time ? (
                  <AvGroup row>
                    <Col>
                      <Label>Cài đặt giờ hẹn</Label>
                      <AvInput
                        className="input-group-prepend"
                        type="datetime-local"
                        onChange={handTimeParamOnchange}
                        name="time"
                        id="time"
                      />
                    </Col>
                  </AvGroup>
                ) : null}
                <div className="d-flex justify-content-between">
                  <SWRadio
                    name="rdo-type-upload"
                    defaultChecked={typeUpload === 'file'}
                    value="file"
                    onChange={handleTypeUpload}
                    label="Up file csv"
                  />
                  <SWRadio
                    name="rdo-type-upload"
                    defaultChecked={typeUpload === 'criteria'}
                    value="criteria"
                    onChange={handleTypeUpload}
                    label="Lọc theo contact"
                  />
                </div>

                {typeUpload === 'file' ? (
                  <AvGroup className="mt-4">
                    <AvInput hidden type="file" accept=".csv" onChange={handleOnChange} required id="file" name="file" />
                    <Label for="file" style={{ width: '100%' }}>
                      <InputGroup>
                        <InputGroupAddon required className="w-100" style={{ cursor: 'pointer' }} addonType="prepend">
                          <InputGroupText required className="choose-file">
                            {file ? file.name : 'Chọn file'}
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Label>
                  </AvGroup>
                ) : null}

                {typeUpload === 'criteria' ? (
                  <div>
                    <div className="note">
                      <b>Lưu ý:</b> Trường hợp không chọn tiêu chí nào, hệ thống sẽ mặc định chọn tất cả
                    </div>
                    <AvCheckboxGroup inline name="box-country" className="box-country">
                      <p>Chọn quốc gia</p>
                      <div className="d-flex">
                        <AvCheckbox
                          name="country"
                          checked={country.includes('VIETNAM')}
                          className="ck-box"
                          value="VIETNAM"
                          onChange={handleSetCountry}
                          label="Việt Nam"
                        />

                        <AvCheckbox
                          name="country"
                          checked={country.includes('THAILAND')}
                          className="ck-box"
                          value="THAILAND"
                          onChange={handleSetCountry}
                          label="Thái lan"
                        />
                      </div>
                    </AvCheckboxGroup>
                    <hr />
                    <AvCheckboxGroup inline name="box-packageType" className="box-packageType">
                      <p className="mt-3">Khách hàng</p>
                      <div className="d-flex">
                        <AvCheckbox
                          value="Trial,POS_TRIAL,EMART_TRIAL,OMNI_TRIAL,SAPO_GO_TRIAL"
                          onChange={handlePackageTypes}
                          className="ck-box"
                          label="Dùng thử"
                          name="packageType"
                        />
                        <AvCheckbox
                          name="packageType"
                          className="ck-box"
                          label="Trả phí"
                          onChange={handlePackageTypes}
                          value="WEB_PRO,SAPO_GO_ADVANCED,SPECIAL_PRO,eBronze,eGold,ePlatinum,eDiamond,AdvancedBizweb,Advanced,POS,OMNI,EWEB,ESOCIAL,EMART,POS_SM,START_UP,ENTERPRISE,SAPO_GO,SAPO_GO_BASIC,SPECIAL,POS_PROMO_01,STARTUP_PROMO_01,SAPO_UP"
                        />
                        <AvCheckbox
                          value="MOBILE_FREE"
                          onChange={handlePackageTypes}
                          className="ck-box"
                          label="Mobile free"
                          name="packageType"
                        />
                      </div>
                    </AvCheckboxGroup>
                    <hr />
                    <AvCheckboxGroup inline name="box-owner" className="box-owner">
                      <p>Đối tượng nhận noti</p>
                      <div className="d-flex">
                        <AvCheckbox
                          name="owner"
                          checked={owners.includes('OWNER')}
                          value="OWNER"
                          onChange={handleOwners}
                          label="Chủ cửa hàng"
                        />

                        <AvCheckbox
                          name="owner"
                          value="STAFF"
                          checked={owners.includes('STAFF')}
                          onChange={handleOwners}
                          label="Nhân viên"
                        />
                      </div>
                    </AvCheckboxGroup>
                    <hr />
                    <AvCheckboxGroup inline className="box-package-name" name="box-package-name">
                      <p>Gói dịch vụ</p>
                      <SWebButton fullWidth onClick={togglePackageName} color="primary">
                        Chọn gói dịch vụ
                      </SWebButton>
                      <Modal size="lg" isOpen={modalPackageName} id="modal-list-package-name">
                        <ModalHeader className="header-primary" toggle={togglePackageName}>
                          Danh sách gói dịch vụ
                        </ModalHeader>
                        <ModalBody>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  value="eBronze"
                                  name="packageNames"
                                  id="eBronze"
                                />
                                <label htmlFor={'eBronze'} className="form-check-label">
                                  eBronze
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="eGold"
                                  value="eGold"
                                  name="packageNames"
                                />
                                <label htmlFor={'eGold'} className="form-check-label">
                                  eGold
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="ePlatinum"
                                  value="ePlatinum"
                                  name="packageNames"
                                />
                                <label htmlFor={'ePlatinum'} className="form-check-label">
                                  ePlatinum
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="eDiamond"
                                  value="eDiamond"
                                  name="packageNames"
                                />
                                <label htmlFor={'eDiamond'} className="form-check-label">
                                  eDiamond
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="Trial"
                                  value="Trial"
                                  name="packageNames"
                                />
                                <label htmlFor={'Trial'} className="form-check-label">
                                  Trial
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="AdvancedBizweb"
                                  value="AdvancedBizweb"
                                  name="packageNames"
                                />
                                <label htmlFor={'AdvancedBizweb'} className="form-check-label">
                                  Advanced Bizweb
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="Advanced"
                                  value="Advanced"
                                  name="packageNames"
                                />
                                <label htmlFor={'Advanced'} className="form-check-label">
                                  Advanced
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="POS"
                                  value="POS"
                                  name="packageNames"
                                />
                                <label htmlFor={'POS'} className="form-check-label">
                                  POS
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="OMNI"
                                  value="OMNI"
                                  name="packageNames"
                                />
                                <label htmlFor={'OMNI'} className="form-check-label">
                                  OMNI
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="EWEB"
                                  value="EWEB"
                                  name="packageNames"
                                />
                                <label htmlFor={'EWEB'} className="form-check-label">
                                  eWeb
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="ESOCIAL"
                                  value="ESOCIAL"
                                  name="packageNames"
                                />
                                <label htmlFor={'ESOCIAL'} className="form-check-label">
                                  eSocial
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="EMART"
                                  value="EMART"
                                  name="packageNames"
                                />
                                <br />
                                <label htmlFor={'EMART'} className="form-check-label">
                                  eMart
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="POS_TRIAL"
                                  value="POS_TRIAL"
                                  name="packageNames"
                                />
                                <br />
                                <label htmlFor={'POS_TRIAL'} className="form-check-label">
                                  Pos Trial
                                </label>
                              </div>
                              <br />

                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SAPO_GO_ADVANCED"
                                  value="SAPO_GO_ADVANCED"
                                  name="packageNames"
                                />
                                <br />
                                <label htmlFor={'SAPO_GO_ADVANCED'} className="form-check-label">
                                  GO Advanced
                                </label>
                              </div>
                              <br />

                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SPECIAL_PRO"
                                  value="SPECIAL_PRO"
                                  name="packageNames"
                                />
                                <br />
                                <label htmlFor={'SPECIAL_PRO'} className="form-check-label">
                                  Special Pro
                                </label>
                              </div>
                              <br />
                            </div>
                            <div className="col-md-6">
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="EMART_TRIAL"
                                  value="EMART_TRIAL"
                                  name="packageNames"
                                />
                                <label htmlFor={'EMART_TRIAL'} className="form-check-label">
                                  Email Trial
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="OMNI_TRIAL"
                                  value="OMNI_TRIAL"
                                  name="packageNames"
                                />
                                <label htmlFor={'OMNI_TRIAL'} className="form-check-label">
                                  Onmi Trial
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="MOBILE_FREE"
                                  value="MOBILE_FREE"
                                  name="packageNames"
                                />
                                <label htmlFor={'MOBILE_FREE'} className="form-check-label">
                                  Mobile miễn phí
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="POS_SM"
                                  value="POS_SM"
                                  name="packageNames"
                                />
                                <label htmlFor={'POS_SM'} className="form-check-label">
                                  POS (SM)
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="START_UP"
                                  value="START_UP"
                                  name="packageNames"
                                />
                                <label htmlFor={'START_UP'} className="form-check-label">
                                  StartUp
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="ENTERPRISE"
                                  value="ENTERPRISE"
                                  name="packageNames"
                                />
                                <label htmlFor={'ENTERPRISE'} className="form-check-label">
                                  Enterprise
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SAPO_GO_TRIAL"
                                  value="SAPO_GO_TRIAL"
                                  name="packageNames"
                                />
                                <label htmlFor={'SAPO_GO_TRIAL'} className="form-check-label">
                                  Sapo Go Trial
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SAPO_GO"
                                  value="SAPO_GO"
                                  name="packageNames"
                                />
                                <label htmlFor={'SAPO_GO'} className="form-check-label">
                                  Sapo Go
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SAPO_GO_BASIC"
                                  value="SAPO_GO_BASIC"
                                  name="packageNames"
                                />
                                <label htmlFor={'SAPO_GO_BASIC'} className="form-check-label">
                                  Sapo Go Basic
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SPECIAL"
                                  value="SPECIAL"
                                  name="packageNames"
                                />
                                <label htmlFor={'SPECIAL'} className="form-check-label">
                                  Sapo Special
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="POS_PROMO_01"
                                  value="POS_PROMO_01"
                                  name="packageNames"
                                />
                                <label htmlFor={'POS_PROMO_01'} className="form-check-label">
                                  Sapo POS
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="STARTUP_PROMO_01"
                                  value="STARTUP_PROMO_01"
                                  name="packageNames"
                                />
                                <label htmlFor={'STARTUP_PROMO_01'} className="form-check-label">
                                  Sapo Startup
                                </label>
                              </div>
                              <br />
                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="SAPO_UP"
                                  value="SAPO_UP"
                                  name="packageNames"
                                />
                                <label htmlFor={'SAPO_UP'} className="form-check-label">
                                  Sapo UP
                                </label>
                              </div>
                              <br />

                              <div className="form-check form-check-inline">
                                <input
                                  type="checkbox"
                                  className="is-touched is-dirty av-valid form-check-input"
                                  id="WEB_PRO"
                                  value="WEB_PRO"
                                  name="packageNames"
                                />
                                <label htmlFor={'web_pro'} className="form-check-label">
                                  Web Pro
                                </label>
                              </div>
                              <br />
                            </div>
                          </div>
                        </ModalBody>
                        <ModalFooter className="footer-modal">
                          <SWebButton outLine onClick={togglePackageName}>
                            Thoát
                          </SWebButton>
                          <SWebButton color="primary" onClick={handlePackageNames}>
                            Lưu
                          </SWebButton>
                        </ModalFooter>
                      </Modal>
                    </AvCheckboxGroup>
                    <hr />
                    <p>Thời gian tạo site</p>
                    <AvGroup>
                      <AvField name="start-date" label="Từ ngày" type="datetime-local" onChange={handleFromCreated} />
                      <AvField name="end-date" label="Đến ngày" type="datetime-local" onChange={handleToCreated} />
                    </AvGroup>
                  </div>
                ) : null}
              </div>
            </AvForm>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={handleonModalClick} id="modal-list-package-name">
        <ModalHeader
          className={discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? 'header-danger' : 'header-primary'}
          toggle={handleonModalClick}
        >
          Cảnh báo
        </ModalHeader>
        <ModalBody>Bạn có chắc muốn {discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? 'đóng' : 'mở'} kịch bản này không?</ModalBody>
        <ModalFooter className="footer-modal">
          <SWebButton minor={true} outLine onClick={handleonModalClick}>
            Hủy
          </SWebButton>
          {discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? (
            <SWebButton onClick={handleStatusCampaign} color="danger">
              Đóng
            </SWebButton>
          ) : (
            <SWebButton onClick={handleStatusCampaign} color="primary">
              Mở
            </SWebButton>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={activeModal} toggle={handleActiveModal} id="modal-list-package-name">
        <ModalHeader toggle={handleActiveModal} className="header-primary">
          Thông báo
        </ModalHeader>
        <ModalBody>Bạn có chắc muốn gửi noti?</ModalBody>
        <ModalFooter className="footer-modal">
          <SWebButton minor outLine onClick={handleActiveModal}>
            Hủy
          </SWebButton>
          <SWebButton onClick={handlePushNotification} color="primary">
            Kích hoạt
          </SWebButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  discountCampaign: storeState.discountCampaign.discountCampaign,
  loading: storeState.discountCampaign.loading,
  template: storeState.template.template
});
const mapDispatchToProps = {
  createDicountCampaign,
  getDiscountCampaign,
  reset,
  activeCampaign,
  activeCampaignByCriteria,
  updateStatusCampaign,
  getTemplate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DiscountCampaignActive);
