import React, { useEffect, useState } from 'react';
import './update-script-fnb.scss';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import { Col, Modal, ModalHeader, ModalBody, ModalFooter, Label, InputGroup, InputGroupAddon, InputGroupText, Input } from 'reactstrap';
import { AvCheckboxGroup, AvCheckbox, AvForm, AvGroup, AvInput, AvField, AvFeedback, AvRadio } from 'availity-reactstrap-validation';
import SWCheckbox from 'app/shared/SWCheckbox/sw-checkbox';
import SWRadio from 'app/shared/SWRadio/sw-radio';
import SWebButton from 'app/shared/SWebButton/SWebButton';
import { toast } from 'react-toastify';
import scriptNotiFnb from 'app/services/script-noti-fnb';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const breadcrumb: IBreadcrumbs = {
  link: '/admin/script-fnb',
  title: 'Quay lại danh sách kịch bản thông báo'
};

interface IModel {
  campaign: IData;
}

export interface IData {
  action?: string;
  active?: boolean;
  actor_id?: string;
  actor_name?: string;
  app?: string;
  category?: string;
  subject?: string;
  client_id?: string;
  status?: string;
  client_time?: number;
  content?: string;
  content_id?: number;
  content_en?: string;
  csv_file_name?: string;
  content_type?: string;
  created_on?: number;
  creator?: string;
  data?: string;
  data_en?: string;
  icon?: string;
  level?: string;
  modified_on?: string;
  name?: string;
  os?: string[];
  package_name?: string[];
  csv_path?: any;
  screen_type?: string;
  start_time?: number;
  store_id?: number;
  target_screen?: string;
  type?: string;
  unread?: boolean;
}

export default function UpdateScriptFnb(props) {
  const history = useHistory();

  const [modelValue, setmodelValue] = useState<IData>();

  const [activeModal, setActiveModal] = useState<boolean>(false);
  const [closeModal, setCloseModal] = useState<boolean>(false);
  const [time, setTime] = useState<boolean>(false);
  const [typeUpload, setTypeUpload] = useState<string>('');
  const [file, setFile] = useState(null);
  const [dataSend, setdataSend] = useState<IModel>();
  const [buttons, setButtons] = useState<IButton[]>([]);

  const handleCloseModal = () => {
    setCloseModal(!closeModal);
  };

  useEffect(() => {
    if (modelValue) {
      if (modelValue.active) {
        setButtons([
          {
            text: modelValue ? (modelValue.active ? 'Đóng kịch bản' : 'Mở kịch bản') : '',
            color: 'danger',
            outLine: true,
            minor: true,
            type: 'button',
            onClick: handleCloseModal
          },
          {
            text: 'Kích hoạt',
            color: 'primary',
            type: 'submit'
          }
        ]);
      } else {
        setButtons([
          {
            text: 'Kích hoạt',
            color: 'primary',
            type: 'submit'
          }
        ]);
      }
    }
  }, [modelValue]);

  useEffect(() => {
    scriptNotiFnb.getOne(props.match.params.id).then(re => {
      setmodelValue(re.data.campaign);
    });
  }, []);

  const checkBoxOs = (str: string) => {
    let a = false;
    if (str && modelValue && modelValue.os) {
      modelValue.os.map(o => {
        if (o === str) {
          a = true;
        }
      });
    } else {
      a = false;
    }
    return a;
    // if (str && modelValue && modelValue.os) {
    //   return modelValue.os === str;
    // } else {
    //   return false;
    // }
  };

  function clean(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === 0) {
        delete obj[propName];
      }
    }
  }

  const toBase64 = f =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(f);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

  const onSubmit = (event, values) => {
    if (values.start_time) {
      values.start_time = Date.parse(values.start_time) / 1000;
    }

    setActiveModal(!activeModal);
    if (typeUpload === 'file') {
      delete values.package_name;
      toBase64(file).then(r => {
        values.csv_path = {
          file_name: file.name,
          base64: r
        };
        if (!time) {
          delete modelValue.start_time;
        }
        delete modelValue.client_id;
        clean(modelValue);
        setdataSend({ ...dataSend, campaign: Object.assign(modelValue, values) });
      });
    } else if (typeUpload === 'criteria') {
      delete modelValue.csv_path;
      if (!time) {
        delete modelValue.start_time;
      }
      delete modelValue.client_id;
      clean(modelValue);
      setdataSend({ ...dataSend, campaign: Object.assign(modelValue, values) });
    }
  };

  const handleActiveModal = () => {
    setActiveModal(!activeModal);
  };

  const handleOnChange = e => {
    setFile(e.target.files[0]);
  };
  const handleTypeUpload = e => {
    setTypeUpload(e.target.value);
  };

  const handleTime = () => {
    setTime(!time);
  };

  const validate = () => {
    if (typeUpload) {
      if (typeUpload === 'file') {
        if (!file) {
          toast.error('Bạn chưa nhập file.Vui lòng nhập file để kích hoạt');
          return false;
        }
        if (file.size / 1024 / 1024 > 20) {
          toast.error('Kích thước file không được lớn hơn 20mb');
          return false;
        }
        if (!file.name.endsWith('.xlsx') && !file.name.endsWith('.xls') && !file.name.endsWith('.xlsm')) {
          toast.error('Chỉ cho phép nhập file excel');
          return false;
        }
      }
    } else {
      toast.error('Chọn kiểu push noti');
      return false;
    }

    return true;
  };

  const handlePushNotification = () => {
    if (!validate()) {
      return;
    }
    if (time) {
      if (!dataSend.campaign.start_time) {
        toast.error('Thời gian đặt lịch chưa hợp lệ');
        return;
      }
    }
    if (typeUpload) {
      if (typeUpload === 'file') {
        dataSend.campaign.csv_path.base64 = dataSend.campaign.csv_path.base64.replace(/^data:.+;base64,/, '');
      }
      scriptNotiFnb
        .updateScriptNoti(dataSend, props.match.params.id)
        .then(re => {
          scriptNotiFnb
            .sendNoti('send', re.data.campaign.client_id)
            .then(r => {
              toast.success('Gửi thông báo thành công');
              history.push('/admin/script-fnb');
            })
            .catch(er => {
              toast.error(`Lỗi: ` + er.response.status + ` rồi :(`);
            });
        })
        .catch(e => {
          toast.error(`Lỗi: ` + e.response.status + ` rồi :(`);
        });
    }
  };

  const onClickClose = () => {};

  return (
    <div id="sw-update-script-fnb">
      <AvForm onValidSubmit={onSubmit}>
        <SWHeader title="Chi tiết kịch bản thông báo" breadcrumb={breadcrumb} button={buttons} />
        <div className="content-update-script-fnb">
          <div className="box-left">
            <AvGroup row>
              <Label for="name" sm={10}>
                Tên kịch bản <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="textarea"
                  name="name"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tên kịch bản không được để trống.'
                    },
                    maxLength: {
                      value: 255,
                      errorMessage: 'Số ký tự không được vượt quá 255.'
                    }
                  }}
                  value={modelValue ? (modelValue.name ? modelValue.name : '') : ''}
                  id="title"
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="template_id" sm={10}>
                Nội dung gửi <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="number"
                  name="content_id"
                  value={modelValue ? (modelValue.content_id ? modelValue.content_id : 0) : 0}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Nội dung gửi không được để trống.'
                    },
                    min: {
                      value: 0,
                      errorMessage: 'Mã nội dung phải lớn hơn 0'
                    }
                  }}
                  id="template_id"
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Danh mục <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  //   onChange={handleCategoryCase}
                  className="form-control select"
                  value={modelValue ? (modelValue.category ? modelValue.category : null) : null}
                  name="category"
                >
                  <option value="system">System</option>
                  <option value="onboarding">Onboarding</option>
                  <option value="new">New</option>
                  <option value="advertise">Advertise</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                App nhận thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="app"
                  value={modelValue ? (modelValue.app ? modelValue.app : null) : null}
                >
                  <option value={null}>Tất cả</option>
                  <option value="admin">App Admin</option>
                  <option value="staff">App Staff</option>
                  <option value="pos">App Pos</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="multiple_times_enabled" sm={12}>
                Phạm vi hiển thị thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="screen_type"
                  value={modelValue ? (modelValue.screen_type ? modelValue.screen_type : null) : null}
                >
                  <option value={null}>Tất cả</option>
                  <option value="in_app">Danh sách thông báo</option>
                  <option value="system_ui">Thông báo hệ thống</option>
                </AvField>
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="content_screen" sm={10}>
                Màn hình chi tiết thông báo <Label className="star">(*)</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  disabled
                  type="select"
                  className="form-control select"
                  name="content_screen"
                  value={modelValue ? (modelValue.target_screen ? modelValue.target_screen : '--') : '--'}
                >
                  <option value="--">{modelValue ? (modelValue.target_screen ? modelValue.target_screen : '--') : '--'}</option>
                  <option value="noti_default">Noti default</option>
                  <option value="noti_detail">Noti detail</option>
                </AvField>
              </Col>
            </AvGroup>
            <AvCheckboxGroup
              inline
              name="os"
              label={
                <div className="mb-2">
                  Hệ điều hành <span className="star">(*)</span>
                </div>
              }
            >
              <div className="d-flex justify-content-between">
                <AvCheckbox disabled label="Android" checked={checkBoxOs('android')} onChange={null} />
                <AvCheckbox disabled label="IOS" checked={checkBoxOs('ios')} onChange={null} />
                <AvCheckbox disabled label="PC Windows" checked={checkBoxOs('pc')} onChange={null} />
                <AvCheckbox disabled label="Website" checked={checkBoxOs('website')} onChange={null} />
              </div>
            </AvCheckboxGroup>
          </div>
          <div className="box-right">
            <div className="box-1">
              <InputGroup>
                <Label for="subject" sm={10}>
                  Chủ đề
                </Label>
                <Col sm={12}>
                  <Input
                    type="textarea"
                    name="subject"
                    value={modelValue ? (modelValue.subject ? modelValue.subject : '') : ''}
                    style={{ resize: 'none' }}
                    disabled
                  />
                </Col>
              </InputGroup>
              <InputGroup>
                <Label for="content" sm={10}>
                  Nội dung
                </Label>
                <Col sm={12}>
                  <Input
                    type="textarea"
                    rows={10}
                    name="content"
                    disabled
                    style={{ resize: 'none' }}
                    value={modelValue ? (modelValue.content ? modelValue.content : '') : ''}
                  />
                </Col>
              </InputGroup>
            </div>
            <div className="box-2">
              <div className="title-time mb-2">
                <SWCheckbox onChange={handleTime} label="Lên lịch gửi" />
              </div>
              {time ? (
                <AvGroup>
                  <AvField name="start_time" label="Cài đặt giờ hẹn" type="datetime-local" value={moment().format('YYYY-MM-DDTHH:MM')} />
                </AvGroup>
              ) : null}
              <div className="d-flex justify-content-between">
                <SWRadio name="rdo-type-upload" value="file" onChange={handleTypeUpload} label="Up file excel" />
                <SWRadio name="rdo-type-upload" value="criteria" onChange={handleTypeUpload} label="Lọc theo contact" />
              </div>

              {typeUpload === 'file' ? (
                <AvGroup className="mt-4">
                  <AvInput
                    hidden
                    type="file"
                    accept="application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={handleOnChange}
                    required
                    id="file"
                    name="csv_path"
                  />
                  <Label for="file" style={{ width: '100%' }}>
                    <InputGroup>
                      <InputGroupAddon required className="w-100" style={{ cursor: 'pointer' }} addonType="prepend">
                        <InputGroupText required className="choose-file">
                          {file ? file.name : 'Chọn file'}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Label>
                </AvGroup>
              ) : null}

              {typeUpload === 'criteria' ? (
                <div>
                  <div className="note">
                    <b>Lưu ý:</b> Trường hợp không chọn tiêu chí nào, hệ thống sẽ mặc định chọn tất cả
                  </div>
                  {/* <AvCheckboxGroup inline name="box-packageType">
                    <p className="mt-3">Khách hàng</p>
                    <div className="d-flex justify-content-around" style={{ marginLeft: '1%' }}>
                      <AvCheckbox value="Trial,POS_TRIAL,EMART_TRIAL,OMNI_TRIAL,SAPO_GO_TRIAL" label="Dùng thử" />
                      <AvCheckbox
                        label="Trả phí"
                        value="eBronze,eGold,ePlatinum,eDiamond,AdvancedBizweb,Advanced,POS,OMNI,EWEB,ESOCIAL,EMART,POS_SM,START_UP,ENTERPRISE,SAPO_GO,SAPO_GO_BASIC,SPECIAL,POS_PROMO_01,STARTUP_PROMO_01,SAPO_UP"
                      />
                    </div>
                  </AvCheckboxGroup> */}
                  <AvCheckboxGroup inline name="package_name">
                    <p>Gói dịch vụ</p>
                    <div className="d-flex justify-content-around" style={{ marginLeft: '1%' }}>
                      <AvCheckbox label="Trial" value="trial" onChange={null} />
                      <AvCheckbox label="Basic" value="basic" onChange={null} />
                      <AvCheckbox label="Pro" value="pro" onChange={null} />
                    </div>
                  </AvCheckboxGroup>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <Modal isOpen={activeModal} toggle={handleActiveModal} id="modal-list-package-name">
          <ModalHeader toggle={handleActiveModal} className="header-primary">
            Thông báo
          </ModalHeader>
          <ModalBody>Bạn có chắc muốn gửi noti?</ModalBody>
          <ModalFooter className="footer-modal">
            <SWebButton minor outLine onClick={handleActiveModal}>
              Hủy
            </SWebButton>
            <SWebButton onClick={handlePushNotification} color="primary">
              Kích hoạt
            </SWebButton>
          </ModalFooter>
        </Modal>
        <Modal isOpen={closeModal} toggle={handleCloseModal} id="modal-list-package-name">
          <ModalHeader toggle={handleCloseModal} className="header-primary">
            Thông báo
          </ModalHeader>
          <ModalBody></ModalBody>
          <ModalFooter className="footer-modal">
            <SWebButton minor outLine onClick={handleCloseModal}>
              Hủy
            </SWebButton>
            <SWebButton onClick={onClickClose} color="primary">
              Kích hoạt
            </SWebButton>
          </ModalFooter>
        </Modal>
      </AvForm>
    </div>
  );
}
