import React, { Fragment, useEffect, useState } from 'react';
import { Table, Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, Link, Router, Route, useHistory } from 'react-router-dom';
import './template.scss';
import { IRootState } from 'app/shared/reducers';
import { connect } from 'react-redux';
import { getTemplates, getTemplate, updatePageActive } from './Template.reducer';
import Pagination from 'react-js-pagination';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { DataTB } from '../content-fnb';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import { formatDateHHMM } from 'app/shared/util/date-utils';

export const dataTB: DataTB[] = [
  {
    width: '10%',
    title: 'ID'
  },
  {
    width: '30%',
    title: 'Tiêu đề gửi'
  },
  {
    width: '30%',
    title: 'Tên mẫu'
  },
  {
    width: '15%',
    title: 'Ngày tạo'
  },
  {
    width: '15%',
    title: 'Lần sửa cuối'
  }
];

export interface ITemplateProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}
const Template = (props: ITemplateProps) => {
  const { template, match, totalItems, activePage } = props;
  useEffect(() => {
    props.getTemplates(activePage - 1);
    window.scrollTo(0, 0);
  }, [activePage, totalItems]);
  const showDate = time => {
    if (!time) return '';
    const timeStamp = new Date(time);
    const date = timeStamp.getDate();
    const strDate = date < 10 ? '0' + date : date;
    const month = timeStamp.getMonth() + 1;
    const strMonth = month < 10 ? '0' + month : month;
    const year = timeStamp.getFullYear();
    const hours = timeStamp.getHours();
    const strHours = hours < 10 ? '0' + hours : hours;
    const minutes = timeStamp.getMinutes();
    const strMinutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = strHours + ':' + strMinutes + ' ';
    const dateString = strDate + '-' + strMonth + '-' + year + ' ' + strTime;
    return dateString;
  };

  const history = useHistory();

  const handleClick = () => {
    history.push('template/create/new');
  };

  const buttons: IButton[] = [
    {
      text: 'Thêm mới',
      color: 'primary',
      onClick: handleClick
    }
  ];

  return (
    <div id="sw-manage-content">
      <SWHeader title="Quản lý nội dung" button={buttons} />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th key={i} style={{ width: d.width }}>
                    {d.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {template.map((d, i) => (
                <tr key={i}>
                  <td>
                    <Link to={`${match.url}/${d.id}`}>
                      <p>{d.id}</p>
                    </Link>
                  </td>
                  <td>{d.name ? d.name : '--'}</td>
                  <td>{d.subject ? d.subject : '--'}</td>
                  <td>{d.created_date ? formatDateHHMM(d.created_date.toString()) : '--'}</td>
                  <td>{d.last_modified_date ? formatDateHHMM(d.last_modified_date.toString()) : '--'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {template.length <= 0 ? null : (
          // <div className="pagination-box mt-4">
          //   <Pagination
          //     firstPageText="Đầu trang"
          //     lastPageText="Cuối trang"
          //     hideNavigation
          //     activePage={activePage}
          //     itemsCountPerPage={ITEMS_PER_PAGE}
          //     totalItemsCount={totalItems}
          //     onChange={props.updatePageActive}
          //     itemClass="page-item"
          //     linkClass="page-link"
          //   />
          // </div>
          <SWebPagination page={activePage} totalItem={totalItems} setPage={props.updatePageActive} itemPerPage={ITEMS_PER_PAGE} />
        )}
      </div>
    </div>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  template: storeState.template.templates,
  totalItems: storeState.template.totalItems,
  activePage: storeState.template.activePage
});
const mapDispatchToProps = { getTemplates, getTemplate, updatePageActive };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(Template);
