import React, { Fragment } from 'react';
import { Switch, Router, Route, Redirect } from 'react-router-dom';

import Login from 'app/modules/login/login';
import Logout from 'app/modules/login/logout';
import PrivateRoute from 'app/shared/auth/private-route';
import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import PageNotFound from 'app/shared/error/page-not-found';
import { AUTHORITIES } from 'app/config/constants';
import Template from './entities/template/Template';
import TemplateUpdate from './entities/template/template-update';
import DiscountCampaign from './entities/DiscountCampaign/DiscountCampaign';
import DiscountCampaignUpdate from './entities/DiscountCampaign/DiscountCampaignUpdate';
import DiscountCampaignActive from './entities/DiscountCampaign/DiscountCampaignActive';
import TemplateCreate from './entities/template/TemplateCreate';
import forbidden from './shared/error/forbidden';
import ScheduledDiscountCampaign from './entities/DiscountCampaign/ScheduledDiscountCampaign/ScheduledDiscountCampaign';
import userManagement from './modules/administration/user-management/user-management';
import userManagementCreate from './modules/administration/user-management/user-management-create';
import userManagementUpdate from './modules/administration/user-management/user-management-update';
import ContentFnb from './entities/content-fnb';
import CreateContentFnb from './entities/content-fnb/create-content-fnb/create-content-fnb';
import ScriptFnb from './entities/script-fnb/script-fnb';
import { CreateScriptFnb } from './entities/script-fnb/create-script-fnb/create-script-fnb';
import UpdateScriptFnb from './entities/script-fnb/update-script-fnb/update-script-fnb';
import ContentFnbDetail from './entities/content-fnb/content-fnb-detail/content-fnb-detail';
import HistoryFnb from './entities/history-noti-fnb/history-noti-fnb';
import UpdateHistoryFnb from './entities/history-noti-fnb/update-noti-fnb/update-history-noti-fnb';
// import ErrorBoundaryRoute from './shared/error/error-boundary-route';


const Routess = () => (
    <Fragment>
        <Switch>
            <Redirect exact from="/"  to="/admin/template"/>
            <ErrorBoundaryRoute path="/login" component={Login} />
            <ErrorBoundaryRoute path="/logout" component={Logout} />
            <PrivateRoute exact path="/admin/template" component={Template} hasAnyAuthorities={[AUTHORITIES.TEMPLATE_READ]}/>
            <PrivateRoute exact path="/admin/discount-campaign" component={DiscountCampaign} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/discount-campaign/create/new" component={DiscountCampaignUpdate} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_WRITE]}/>
            <PrivateRoute exact path="/admin/template/:id" component={TemplateUpdate} />
            <PrivateRoute exact path="/admin/discount-campaign/detail/:id" component={DiscountCampaignActive} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/template/create/new" component={TemplateCreate} hasAnyAuthorities={[AUTHORITIES.TEMPLATE_WRITE]}/>
            <ErrorBoundaryRoute path="/admin/forbidden" component={forbidden}/>
            <PrivateRoute exact path="/admin/scheduled-discount-campaign" component={ScheduledDiscountCampaign} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/content-fnb" component={ContentFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/content-fnb" component={ContentFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/content-fnb/create" component={CreateContentFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/content-fnb/:id" component={ContentFnbDetail} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/script-fnb" component={ScriptFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/script-fnb/create-script-fnb" component={CreateScriptFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/script-fnb/:id" component={UpdateScriptFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/history-noti-fnb" component={HistoryFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/history-noti-fnb/:id" component={UpdateHistoryFnb} hasAnyAuthorities={[AUTHORITIES.CAMPAIGN_READ]}/>
            <PrivateRoute exact path="/admin/user-management" component={userManagement} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
            <PrivateRoute exact path="/admin/user-management/create" component={userManagementCreate} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
            <PrivateRoute exact path="/admin/user-management/update/:login" component={userManagementUpdate} hasAnyAuthorities={[AUTHORITIES.ADMIN]}/>
            <ErrorBoundaryRoute component={PageNotFound} /> 
        </Switch>
    </Fragment>
);

export default Routess;