export interface IUser {
  id?: any;
  login?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  activated?: boolean;
  lang_key?: string;
  authorities?: any[];
  created_by?: string;
  created_date?: Date;
  last_modified_by?: string;
  last_modified_date?: Date;
  password?: string;
}

export const defaultValue: Readonly<IUser> = {
  id: '',
  login: '',
  first_name: '',
  last_name: '',
  email: '',
  activated: null,
  lang_key: '',
  authorities: [],
  created_by: '',
  created_date: null,
  last_modified_by: '',
  last_modified_date: null,
  password: ''
};
