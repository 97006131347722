import { defaultValue, IDiscountCampaign } from 'app/shared/model/DiscountCampaign.model';
import { FAILURE, REQUEST, SUCCESS } from 'app/shared/reducers/action-type.util';
import axios from 'axios';
import { ICrudGetAction } from 'react-jhipster';
// import { Datetime } from "react-datetime";

export const ACTION_TYPES = {
  FETCH_DISCOUNT_CAMPAIGN: 'discount_campaign/FETCH_DISCOUNT_CAMPAIGN',
  FETCH_DISCOUNT_CAMPAIGNS: 'discount_campaign/FETCH_DISCOUNT_CAMPAIGNS',
  CREATE_DISCOUNT_CAMPAIGN: 'discount_campaign/CREATE_DISCOUNT_CAMPAIGN',
  UPDATE_DISCOUNT_CAMPAIGN: 'discount_campaign/UPDATE_DISCOUNT_CAMPAIGN',
  DELETE_DISCOUNT_CAMPAIGN: 'discount_campaign/DELETE_DISCOUNT_CAMPAIGN',
  RESET: 'discount_campaign/RESET',
  COUNT_DISCOUNT_CAMPAIGN: 'discount_campaign/COUNT_DISCOUNT_CAMPAIGN',
  ACTIVE_PAGE: 'discount_campaign/ACTIVE_PAGE',
  UPDATE_STATUS_CAMPAIGN: 'discount_campaign/UPDATE_STATUS_CAMPAIGN'
};
const initialState = {
  loading: false,
  errorMessage: null,
  discountCampaigns: [] as ReadonlyArray<IDiscountCampaign>,
  authorities: [] as any[],
  discountCampaign: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  activePage: 1
};
export type DiscountCampaignState = Readonly<typeof initialState>;
export default (state: DiscountCampaignState = initialState, action): DiscountCampaignState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.CREATE_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGNS):
    case REQUEST(ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.DELETE_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.COUNT_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.UPDATE_STATUS_CAMPAIGN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN):
    case FAILURE(ACTION_TYPES.CREATE_DISCOUNT_CAMPAIGN):
    case FAILURE(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGN):
    case FAILURE(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGNS):
    case FAILURE(ACTION_TYPES.COUNT_DISCOUNT_CAMPAIGN):
    case FAILURE(ACTION_TYPES.UPDATE_STATUS_CAMPAIGN):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        updateSuccess: false
      };
    case SUCCESS(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGNS):
      return {
        ...state,
        loading: false,
        discountCampaigns: action.payload.data.discount_campaigns
      };
    case SUCCESS(ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        discountCampaign: action.payload.data.discount_campaign
      };
    // case SUCCESS(ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN):
    case SUCCESS(ACTION_TYPES.CREATE_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        discountCampaign: action.payload.data.discount_campaign
      };
    case SUCCESS(ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false
      };
    case SUCCESS(ACTION_TYPES.COUNT_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.data.count
      };
    case SUCCESS(ACTION_TYPES.UPDATE_STATUS_CAMPAIGN):
      return {
        ...state,
        loading: false,
        discountCampaign: action.payload.data.discount_campaign
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };
    default:
      return state;
  }
};
export const count = () => ({
  type: ACTION_TYPES.COUNT_DISCOUNT_CAMPAIGN,
  payload: axios.get('/api/discount_campaigns/count')
});
export const getDiscountCampaigns = page => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGNS,
    payload: axios.get<IDiscountCampaign>(`/api/discount_campaigns?page=${page}`)
  });
  dispatch(count());
  return result;
};
export const getDiscountCampaign: ICrudGetAction<IDiscountCampaign> = (id: string) => ({
  type: ACTION_TYPES.FETCH_DISCOUNT_CAMPAIGN,
  payload: axios.get<IDiscountCampaign>(`/api/discount_campaigns/${id}`)
});
export const activeCampaign = (id: string, file: File, time: Date) => async dispatch => {
  const formData = new FormData();
  formData.append('file', file);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  let url = null;
  if (time) {
    // var dt = new Date(" UTC+07:00");
    const date = new Date(time);
    const s = date.toISOString();
    url = `/api/discount_campaigns/${id}/import_csv?scheduled_date=${s}`;
  } else {
    url = `/api/discount_campaigns/${id}/import_csv`;
  }
  return await dispatch({
    type: ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN,
    payload: axios.put(url, formData, config),
    meta: {
      successMessage: 'Gửi thông báo thành công'
    }
  });
};

export const activeCampaignByCriteria = (
  id: string,
  packageNames: string,
  packageTypes: string,
  country: Array<string>,
  fromCreatedDate: string,
  toCreatedDate: string,
  powners: Array<string>,
  time: Date
) => async dispatch => {
  let url = null;
  if (time) {
    // var dt = new Date(" UTC+07:00");
    const date = new Date(time);
    const s = date.toISOString();
    url = `/api/discount_campaigns/${id}/push_noti_by_criteria?scheduled_date=${s}`;
  } else {
    url = `/api/discount_campaigns/${id}/push_noti_by_criteria`;
  }
  if (fromCreatedDate === null || fromCreatedDate === '' || fromCreatedDate === 'null:00Z' || toCreatedDate === '00Z') {
    fromCreatedDate = '';
  }
  if (toCreatedDate === null || toCreatedDate === '' || toCreatedDate === 'null:00Z' || toCreatedDate === '00Z') {
    toCreatedDate = '';
  }
  // properties
  const criteria = {
    package_names: packageNames,
    package_types: packageTypes,
    from_created_date: fromCreatedDate,
    country,
    to_created_date: toCreatedDate,
    owners: powners
  };
  return await dispatch({
    type: ACTION_TYPES.UPDATE_DISCOUNT_CAMPAIGN,
    payload: axios.put(url, { criteria }),
    meta: {
      successMessage: 'Gửi thông báo thành công'
    }
  });
};
/* eslint-disable @typescript-eslint/camelcase */
export const createDicountCampaign = discount_campaign => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_DISCOUNT_CAMPAIGN,
    payload: axios.post('/api/discount_campaigns', { discount_campaign }),
    meta: {
      successMessage: 'Tạo kịch bản thành công'
    }
  });
  return result;
};
export const updatePageActive = page => ({
  type: ACTION_TYPES.ACTIVE_PAGE,
  payload: page
});
export const reset = () => ({
  type: ACTION_TYPES.RESET
});
export const updateStatusCampaign = (id, status) => async dispatch => {
  let url = '';
  if (status === 'ACTIVE') {
    url = `/api/discount_campaigns/${id}/close`;
  } else {
    url = `/api/discount_campaigns/${id}/open`;
  }
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_STATUS_CAMPAIGN,
    payload: axios.put(url)
  });
  return result;
};

export const getAllDataExport = async (totalItems: number) => {
  let data = [];
  const pages = Math.ceil(totalItems / 250);
  for (let i = 0; i < pages; i++) {
    const result = await axios.get(`/api/discount_campaigns?page=${i}&size=250`);
    data = data.concat(result.data.discount_campaigns);
  }
  return data;
};
