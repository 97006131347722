import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { Col, Label } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';
import React, { useEffect, useState } from 'react';
import '../create-content-fnb/style.scss';
import { toast } from 'react-toastify';
const breadcrumb: IBreadcrumbs = {
  link: '/admin/content-fnb',
  title: 'Quay lại danh sách kịch bản thông báo'
};
import notiFnb from 'app/services/noti-fnb';
import { useHistory } from 'react-router-dom';

interface IMetaFields {
  multiple: boolean;
  data_type: string;
  meta_key: string;
  meta_value: string;
  meta_values: any;
}

export interface IData {
  id: number;
  name: string;
  target_channel: string;
  subject: string;
  content: string;
  meta_fields: IMetaFields[];
  created_by: string;
  created_date: string;
  language: string;
  language_group_id: string;
  last_modified_by: string;
  last_modified_date: string;
  thumb_url: string;
  type: string;
}

interface ContentFnbDetailProps {}

export default function ContentFnbDetail(props) {
  const [displayBox, setDisplayBox] = useState<boolean>(false);

  const [dataModel, setdataModel] = useState<IData>(null);

  const [fileMessage] = useState<string>(null);

  const history = useHistory();

  useEffect(() => {
    if (displayBox) {
      const script2 = document.createElement('script');
      script2.text =
        'ClassicEditor.create(document.querySelector(".editor"),{heading:{options:[{model:"Title",view:{name:"h1",classes:"ck-text-title"},title:"Tiêu đề",class:"ck-text-title",converterPriority:"high"},{model:"Content",view:{name:"p",classes:"ck-text-content"},title:"Nội dung",class:"ck-text-content",converterPriority:"high"},{model:"ExtraContent",view:{name:"p",classes:"ck-text-extra-content"},title:"Nội dung phụ",class:"ck-text-extra-content",converterPriority:"high"}]},toolbar:{items:["heading","|","imageInsert","|","fontColor","|","bold","italic","link","bulletedList","numberedList","|","fontBackgroundColor","fontSize","removeFormat","|","indent","outdent","|","blockQuote","undo","redo"]},language:"vi",image:{toolbar:["imageStyle:alignLeft","imageStyle:alignCenter","imageStyle:alignRight","|","imageResize","|","imageTextAlternative","linkImage"],styles:["alignLeft","alignCenter","alignRight"],resizeOptions:[{name:"imageResize:original",label:"Original",value:null},{name:"imageResize:25",label:"25%",value:"25"},{name:"imageResize:50",label:"50%",value:"50"},{name:"imageResize:75",label:"75%",value:"75"}]},fontSize:{options:[10,14,16,18,20]},fontColor:{colors:[{color:"hsl(226, 12%, 23%)",label:"Đen"},{color:"hsl(231, 4%, 58%)",label:"Xám"},{color:" hsl(208, 100%, 50%)",label:"Xanh dương"}]},fontFamily:{options:["Regular","Ubuntu, Arial, sans-serif","Ubuntu Mono, Courier New, Courier, monospace"]},licenseKey:""}).then(e=>{window.editor=e,editor.model.document.on("change:data",e=>{sessionStorage.setItem("data_ckeditor",editor.getData())})}).catch(e=>{console.error("Oops, something went wrong!"),console.error("Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:"),console.warn("Build id: hdzm8em2f6q7-v1jpqlu9572o"),console.error(e)});';
      document.body.appendChild(script2);
      return () => {
        document.body.removeChild(script2);
      };
    }
  }, [displayBox]);

  useEffect(() => {
    notiFnb.getContent(props.match.params.id).then(re => {
      setdataModel(re.data.template);

      setDisplayBox(re.data.template ? (re.data.template.meta_fields !== null ? true : false) : false);
    });
  }, []);

  const handleCheckFile = type => {
    if (!type.endsWith('.jpg') && !type.endsWith('.jpeg') && !type.endsWith('.gif') && !type.endsWith('.png')) {
      return false;
    }
    return true;
  };

  const validate = request => {
    if (request.template.thumb_url && !handleCheckFile(request.template.thumb_url)) {
      toast.error('Ảnh giao diện thông báo không đúng định dạng');
      return false;
    } else return true;
  };

  const saveTemplate = (event, values) => {
    const valueCkeditor = sessionStorage.getItem('data_ckeditor');

    let mt = null;
    if (displayBox) {
      mt = [
        {
          multiple: false,
          data_type: 'STRING',
          meta_key: 'url_content',
          meta_value: values.url_content,
          meta_values: []
        },
        {
          multiple: false,
          data_type: 'STRING',
          meta_key: 'external_url',
          meta_value: values.external_url,
          meta_values: []
        },
        {
          multiple: false,
          data_type: 'STRING',
          meta_key: 'content_screen_2',
          meta_value: values.content_screen,
          meta_values: []
        },
        {
          multiple: false,
          data_type: 'STRING',
          meta_key: 'html_content',
          meta_value: valueCkeditor,
          meta_values: []
        }
      ];
    }
    const temp = {
      template: {
        name: values.name,
        target_channel: 'MOBILE_NOTIFICATION',
        subject: values.subject,
        content: values.content,
        meta_fields: mt,
        thumb_url: values.thumb_url ? values.thumb_url : null,
        type: 'FNB'
      }
    };

    if (!validate(temp)) {
      return;
    } else {
      notiFnb.updateContent(props.match.params.id, temp).then(() => {
        toast.success('Lưu thành công');
        history.push('/admin/content-fnb');
      });
    }
  };

  const buttons: IButton[] = [
    {
      text: 'Lưu',
      color: 'primary',
      type: 'submit'
    }
  ];

  const getValue = (str: string) => {
    let v = '';

    if (dataModel && dataModel.meta_fields) {
      dataModel.meta_fields.forEach(d => {
        if (d.meta_key === str) {
          v = d.meta_value;
        }
      });
    }

    return v;
  };

  return (
    <div id="sw-create-content-fnb">
      <AvForm onValidSubmit={saveTemplate} model={dataModel}>
        <SWHeader title="Chi tiết nội dung thông báo" breadcrumb={breadcrumb} button={buttons} />

        <div className="main-create">
          <div className="box-left">
            <div className="title">Nội dung thông báo</div>
            <AvGroup row>
              <Label for="name" sm={10}>
                Tên thông báo<Label className="star">*</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="text"
                  name="name"
                  id="name"
                  value={dataModel ? (dataModel.name ? dataModel.name : '') : ''}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tên thông báo không được để trống'
                    },
                    maxLength: {
                      value: 255,
                      errorMessage: 'số ký tự phải nhỏ hơn 255.'
                    }
                  }}
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label sm={10}>
                Tiều đề thông báo<Label className="star">*</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="textarea"
                  name="subject"
                  id="subject"
                  value={dataModel ? (dataModel.subject ? dataModel.subject : '') : ''}
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Tiêu đề không được để trống không được để trống'
                    },
                    maxLength: {
                      value: 1024,
                      errorMessage: 'số ký tự phải nhỏ hơn 1024.'
                    }
                  }}
                />
              </Col>
            </AvGroup>
            <AvGroup row>
              <Label sm={10} for="content">
                Nội dung<Label className="star">*</Label>
              </Label>
              <Col sm={12}>
                <AvField
                  type="textarea"
                  name="content"
                  rows={5}
                  value={dataModel ? (dataModel.content ? dataModel.content : '') : ''}
                  id="content"
                  validate={{
                    required: {
                      value: true,
                      errorMessage: 'Nội dung không được để trống'
                    },
                    maxLength: {
                      value: 65535,
                      errorMessage: 'số ký tự phải nhỏ hơn 65535.'
                    }
                  }}
                />
              </Col>
            </AvGroup>

            <AvGroup row>
              <Label for="thumb-url" sm={10}>
                Ảnh giao diện cho thông báo(jpg, jpeg, gif, png)
              </Label>
              <Col sm={12}>
                <AvField
                  type="text"
                  name="thumb_url"
                  value={dataModel ? (dataModel.thumb_url ? dataModel.thumb_url : '') : ''}
                  id="thumb-url"
                  validate={{
                    maxLength: {
                      value: 255,
                      errorMessage: 'số ký tự phải nhỏ hơn 255.'
                    }
                  }}
                />
                <p className="file-err">{fileMessage}</p>
              </Col>
            </AvGroup>
          </div>
          <div id="box-right" className="box-right">
            <div className="w-460">
              <label className="container-checkbox">
                Chi tiết màn hình
                <input onChange={e => setDisplayBox(e.target.checked)} checked={displayBox} type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>
            {displayBox ? (
              <>
                {/* <AvGroup row>
                  <Label for="content_screen" sm={10}>
                    Thông báo mở sang màn hình khác
                  </Label>
                  <Col sm={12}>
                    <AvField
                      type="select"
                      className="form-control select"
                      id="content_screen"
                      name="content_screen"
                      value={getValue('content_screen_2')}
                    >
                      <option hidden></option>
                      <option value="dashboard">dashboard</option>
                      <option value="order_dashboard">order_dashboard</option>
                      <option value="add_product">add_product</option>
                      <option value="order_detail">order_detail</option>
                      <option value="orders">orders</option>
                      <option value="shipments">shipments</option>
                      <option value="reports">reports</option>
                      <option value="webview">webview</option>
                      <option value="daily_revenue_report">daily_revenue_report</option>
                      <option value="upgrade_package">upgrade_package</option>
                      <option value="vouchers">vouchers</option>
                    </AvField>
                  </Col>
                </AvGroup> */}
                <AvGroup row>
                  <Label for="url_content" sm={10}>
                    Đường dẫn của thông báo được gửi
                  </Label>
                  <Col sm={12}>
                    <AvField type="text" name="url_content" id="url_content" value={getValue('url_content')} />
                  </Col>
                </AvGroup>

                <AvGroup row>
                  <Label for="html_content" sm={10} className="text-secondary">
                    Nội dung html
                  </Label>
                  <Col sm={12}>
                    <div id="url_content" className="editor">
                      {ReactHtmlParser(getValue('html_content'))}
                    </div>
                  </Col>
                </AvGroup>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </AvForm>
    </div>
  );
}
