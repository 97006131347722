import axios from 'axios';
import { IScheduledDiscountCampaign } from 'app/shared/model/ScheduledDiscountCampaign';
import { REQUEST, FAILURE, SUCCESS } from 'app/shared/reducers/action-type.util';
import { ICrudGetAction } from 'react-jhipster';
export const ACTION_TYPES = {
  FETCH_SCHEDULED_DISCOUNT_CAMPAIGN: 'scheduled_discount/FETCCH_SCHEDULED_DISCOUNT_CAMPAIGN',
  COUNT_SCHEDULED_DISCOUNT_CAMPAIGN: 'scheduled_discount/COUNT_DISCOUNT_CAMPAIGN',
  ACTIVE_PAGE: 'scheduled_discount/ACTIVE_PAGE'
};
const initialState = {
  loading: false,
  errorMessage: null,
  scheduledDiscountCampaigns: [] as ReadonlyArray<IScheduledDiscountCampaign>,
  authorities: [] as any[],
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  activePage: 1
};
export type scheduledDiscountCampaignState = Readonly<typeof initialState>;
export default (state: scheduledDiscountCampaignState = initialState, action): scheduledDiscountCampaignState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_SCHEDULED_DISCOUNT_CAMPAIGN):
    case REQUEST(ACTION_TYPES.COUNT_SCHEDULED_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.COUNT_SCHEDULED_DISCOUNT_CAMPAIGN):
    case FAILURE(ACTION_TYPES.FETCH_SCHEDULED_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        errorMessage: action.payload
      };
    case SUCCESS(ACTION_TYPES.FETCH_SCHEDULED_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        updateSuccess: true,
        scheduledDiscountCampaigns: action.payload.data.scheduled_discount_campaigns
      };
    case SUCCESS(ACTION_TYPES.COUNT_SCHEDULED_DISCOUNT_CAMPAIGN):
      return {
        ...state,
        loading: false,
        totalItems: action.payload.data.count
      };
    case ACTION_TYPES.ACTIVE_PAGE:
      return {
        ...state,
        activePage: action.payload
      };
    default:
      return state;
  }
};
export const count = () => ({
  type: ACTION_TYPES.COUNT_SCHEDULED_DISCOUNT_CAMPAIGN,
  payload: axios.get(`/api/discount_campaigns/scheduled/count`)
});
export const getScheduledDiscounts = (page, orderBy, orderByDesc) => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_SCHEDULED_DISCOUNT_CAMPAIGN,
    payload: axios.get(`api/discount_campaigns/scheduled?page=${page}&orderBy=${orderBy}&orderByDesc=${orderByDesc}`)
  });
  dispatch(count());
  return result;
};
export const updatePageActive = page => ({
  type: ACTION_TYPES.ACTIVE_PAGE,
  payload: page
});
