import moment from 'moment';

import { APP_LOCAL_DATETIME_FORMAT_Z, APP_DATE_FORMAT } from 'app/config/constants';

export const convertDateTimeFromServer = date => (date ? moment(date).format(APP_DATE_FORMAT) : null);

export const convertDateTimeToServer = date => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export function formatDate(date: string) {
  if (date) {
    return moment(date).format('DD/MM/yyyy');
  } else {
    return '--';
  }
}

export function formatDateHHMM(date: string) {
  if (date) {
    return moment(date).format('DD/MM/YYYY HH:mm');
  } else {
    return '--';
  }
}

export function formatDateTSHHMM(date: number) {
  if (date) {
    return moment.unix(date).format('DD/MM/YYYY HH:mm');
  } else {
    return '--';
  }
}
