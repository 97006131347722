import axios from 'axios';
/* eslint-disable @typescript-eslint/camelcase */
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

export const ACTION_TYPES = {
  UPDATE_PASSWORD: 'account/UPDATE_PASSWORD',
  RESET: 'account/RESET',
  SHOW_MODAL_PASSWORD: 'account/SHOW_MODAL_PASSWORD'
};

const initialState = {
  loading: false,
  errorMessage: null,
  updateSuccess: false,
  updateFailure: false,
  showModal: false
};

export type PasswordState = Readonly<typeof initialState>;

// Reducer
export default (state: PasswordState = initialState, action): PasswordState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case FAILURE(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: false,
        updateFailure: true
      };
    case SUCCESS(ACTION_TYPES.UPDATE_PASSWORD):
      return {
        ...initialState,
        loading: false,
        updateSuccess: true,
        updateFailure: false
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState
      };
    case ACTION_TYPES.SHOW_MODAL_PASSWORD:
      return {
        ...state,
        showModal: !state.showModal
      };
    default:
      return state;
  }
};

// Actions
const apiUrl = '/api/account';

export const savePassword = (current_password, new_password) => ({
  type: ACTION_TYPES.UPDATE_PASSWORD,
  payload: axios.post(`${apiUrl}/change-password`, { current_password, new_password }),
  meta: {
    successMessage: 'Thay đổi password thành công',
    errorMessage: 'Thay đổi mật khẩu thất bại'
  }
});

export const reset = () => ({
  type: ACTION_TYPES.RESET
});
export const changeShowModal = () => ({
  type: ACTION_TYPES.SHOW_MODAL_PASSWORD
});
