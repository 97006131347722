import { IDataCreateFnb } from 'app/entities/content-fnb/data';
import axios from 'axios';
class NotiFnb {
  getList(page: number) {
    return axios.get(`/api/templates?page=${page}&type=FNB`);
  }
  createNotiFnb(data: IDataCreateFnb) {
    return axios.post(`/api/templates`, data);
  }
  page() {
    return axios.get(`/api/templates/count?type=FNB`);
  }
  getContent(id: number) {
    return axios.get(`/api/templates/${id}`);
  }
  updateContent(id: number, data) {
    return axios.put(`/api/templates?id=${id}&type=FNB`, data);
  }
}
export default new NotiFnb();
