import 'react-toastify/dist/ReactToastify.css';
import './app.scss';

import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'reactstrap';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { hot } from 'react-hot-loader';

import { IRootState } from 'app/shared/reducers';
import { getSession } from 'app/shared/reducers/authentication';
import { getProfile } from 'app/shared/reducers/application-profile';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import ErrorBoundary from 'app/shared/error/error-boundary';
import { AUTHORITIES, AUTH_TOKEN_KEY } from 'app/config/constants';
import AppRoutes from 'app/routess';
import Menu from './shared/layout/menus/Menu';
import { parseJwt } from './shared/util/jwt-utils';
import { Storage } from 'react-jhipster';
import Axios from 'axios';

const instanceAxios = Axios.create();

const baseHref = document
  .querySelector('base')
  .getAttribute('href')
  .replace(/\/$/, '');

export interface IAppProps extends StateProps, DispatchProps {}

export const App = (props: IAppProps) => {
  const { menuAction } = props;

  useEffect(() => {
    props.getSession();
  }, []);
  const { isAuthenticated } = props;
  useEffect(() => {
    const token = Storage.local.get(AUTH_TOKEN_KEY);
    if (token && props.account.authorities && JSON.stringify(parseJwt(token).authorities) !== JSON.stringify(props.account.authorities)) {
      instanceAxios
        .post('/auth/refresh')
        .then((result: any) => {
          Storage.local.set(AUTH_TOKEN_KEY, result.data.access_token);
        })
        .catch(function(error) {});
    }
  }, [props.account]);
  return (
    <Router basename={baseHref}>
      {isAuthenticated ? (
        <div className="app-container">
          <ToastContainer
            limit={1}
            position={'top-center'}
            closeOnClick={false}
            autoClose={3000}
            hideProgressBar={true}
            className="toastify-container"
            toastClassName="toastify-toast"
          />
          <div className="view-container" id="app-view-container">
            <div className={`box-menu ${menuAction ? 'open-menu' : 'close-menu'}`}>
              <Menu />
            </div>
            <Card className="jh-card">
              <ErrorBoundary>
                <AppRoutes />
              </ErrorBoundary>
            </Card>
          </div>
        </div>
      ) : (
        <div className="app-container">
          <ToastContainer
            limit={1}
            position={'top-center'}
            closeOnClick={false}
            autoClose={3000}
            hideProgressBar={true}
            className="toastify-container"
            toastClassName="toastify-toast"
          />
          <AppRoutes />
        </div>
      )}
    </Router>
  );
};

const mapStateToProps = ({ authentication, applicationProfile }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  ribbonEnv: applicationProfile.ribbonEnv,
  isInProduction: applicationProfile.inProduction,
  isSwaggerEnabled: applicationProfile.isSwaggerEnabled,
  account: authentication.account,
  menuAction: authentication.menuAction
});

const mapDispatchToProps = { getSession, getProfile };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(hot(module)(App));
