import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import Checkbox, { CHECKBOX_MODE } from '../Checkbox/checkbox';
import './bulkActionTable.scss';

export interface IBulkActionTable {
  /**
   * total width: 95%
   */
  colums?: IColumn[];
  actions?: IBulkAction[];
  itemName?: string;
  itemIds?: string[] | number[];
  selectedIds?: string[] | number[];
  className?: string;
  checked?: boolean;
  onChange?: Function;
}

export interface IColumn {
  title: string;
  width: string;
}

export interface IBulkAction {
  key: string;
  title: string;
  onClick: Function;
}

function useOutsideClick(ref, handle) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        handle();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function BulkActionTable(props: IBulkActionTable) {
  const { onChange = () => {}, colums = [], actions = [], itemName = '', itemIds = [], selectedIds = [], className = '' } = props;
  const selectedMode = selectedIds.length > 0;
  const selectedAnyMode = selectedMode && selectedIds.length < itemIds.length;
  const selectedAllMode = selectedMode && !selectedAnyMode;
  const checkboxMode = selectedAllMode ? CHECKBOX_MODE.All : CHECKBOX_MODE.SEMI;
  const colSpan = selectedMode ? colums.length + 1 : 1;
  const label = selectedMode ? `Đã chọn ${selectedIds.length} ${itemName}` : '';
  const [displayBulkAction, setDisplayBulkAction] = useState<boolean>(false);
  const ref = useRef();
  useOutsideClick(ref, () => setDisplayBulkAction(false));

  return (
    <tr className="bulk-action-table">
      <th key="bulk-action" style={{ width: '5%' }} colSpan={colSpan} className={className}>
        {selectedMode ? (
          <div className="bulk-action-wrap">
            <div className="checkbox-wrap">
              <Checkbox onChange={onChange} itemId="select-all" mode={checkboxMode} checked={selectedMode} />
              <div>{label}</div>
            </div>
            <div className="action-wrap" ref={ref}>
              <div className="actions" onClick={() => setDisplayBulkAction(!displayBulkAction)}>
                <div className="action-text">Thao tác</div>
                <div className="caret-down-gray">
                  <FontAwesomeIcon icon={faCaretDown} size="1x" />
                </div>
              </div>
              {displayBulkAction && (
                <div className="bulk-action">
                  <ul>
                    {actions.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => {
                          item.onClick();
                          setDisplayBulkAction(false);
                        }}
                      >
                        {item.title}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="checkbox-unselect-wrap" onClick={() => document.getElementById('select-all').click()}>
            <Checkbox onChange={onChange} itemId="select-all" mode={checkboxMode} checked={selectedMode} />
            <div className="caret-down">
              <FontAwesomeIcon icon={faCaretDown} size="1x" />
            </div>
          </div>
        )}
      </th>
      {!selectedMode &&
        colums.map((colum, i) => (
          <th key={i} style={{ width: colum.width }}>
            {colum.title}
          </th>
        ))}
    </tr>
  );
}
