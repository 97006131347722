import React from 'react';
import './SWebButton.scss';

interface SWebButtonProps {
  color?: 'primary' | 'danger' | any;
  children: string;
  onClick?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  fullWidth?: boolean;
  outLine?: boolean;
  onDoubleClick?: any;
  minor?: boolean;
  preIcon?: any;
}

export default function SWebButton(props: SWebButtonProps) {
  const { color, children, onClick, type, disabled, fullWidth, outLine, onDoubleClick, minor, preIcon } = props;

  const changeColor = () => {
    if (outLine && color === 'primary') return 'sw-btn-outline-primary';
    if (outLine && color === 'danger') return 'sw-btn-outline-danger';
    if (outLine && !color) return 'sw-btn-outline';
    if (!outLine && color === 'primary') return 'sw-btn-primary';
    if (!outLine && color === 'danger') return 'sw-btn-danger';
    if (!outLine && !color) return 'sw-btn-primary';
  };

  return (
    <button
      onDoubleClick={onDoubleClick}
      style={{ width: fullWidth ? '100%' : null }}
      type={type}
      className={`sw-btn ${changeColor()} ${minor ? 'sw-btn-minor' : null}`}
      onClick={onClick}
      disabled={disabled}
    >
      {preIcon}
      {children}
    </button>
  );
}
