import React, { useEffect, useState } from 'react';
import NextArrowIcon from '../../../content/images/next-arrow-icon.svg';
import './sweb-pagination.scss';
interface SWebPaginationProps {
  page: number;
  listPage?: number;
  setPage: any;
  itemPerPage?: number;
  totalItem?: number;
}

const switchPage = (num: number) => {
  switch (num) {
    case 0:
      return 6;
    case 1:
      return 5;
    case 2:
      return 4;
    case 3:
      return 3;
    default:
      break;
  }
};

export default function SWebPagination(props: SWebPaginationProps) {
  const { page, listPage, setPage, itemPerPage, totalItem } = props;

  const pages = listPage ? listPage : Math.ceil(totalItem / itemPerPage);

  const [pagePer, setPagePer] = useState<number>(0);
  const [pageNext, setPageNext] = useState<number>(0);

  useEffect(() => {
    setPagePer(pages - page <= 2 ? page - switchPage(pages - page) : page - 3);
    setPageNext((page + 2 < pages ? (page <= 3 ? page + switchPage(page) : page + 2) : pages) + 1);
  }, [page]);

  const getArrPage = () => {
    const arrPage: number[] = [];
    for (
      let index = page - 3 > 0 ? (pages - page <= 2 ? page - switchPage(pages - page) : page - 3) : 0;
      index < (page + 2 < pages ? (page <= 3 ? page + switchPage(page) : page + 2) : pages);
      index++
    ) {
      if (index + 1 > 0 && index + 1 <= pages) {
        arrPage.push(index);
      }
    }
    return arrPage;
  };

  const handleChangPage = (num: number) => {
    setPage(num);
  };

  const handleClickPer = () => {
    setPage(1);
  };

  const handleClickNext = () => {
    setPage(pages);
  };

  return (
    <div id="sw-pagination">
      <div onClick={handleClickPer} className={`${page === 1 ? 'disabled':''}`}>
        <NextArrowIcon className="mr-3 icon-per" />
        Đầu trang
      </div>
      {page > 3 && pages > 6 ? <div onClick={() => setPage(pagePer)}>...</div> : null}
      {getArrPage().map(p => (
        <div key={p} onClick={() => handleChangPage(p + 1)} className={page === p + 1 ? 'active' : ''}>
          {p + 1}
        </div>
      ))}
      {page < pages - 2 && pages > 6 ? <div onClick={() => setPage(pageNext)}>...</div> : null}
      <div onClick={handleClickNext} className={`${page === pages ? 'disabled':''}`} >
        Cuối trang
        <NextArrowIcon className="ml-3 icon-next" />
      </div>
    </div>
  );
}
