import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { STATUS_CAMPAIGN } from 'app/config/constants';
import BulkActionTable, { IBulkAction, IColumn } from 'app/shared/BulkAction/bulkActionTable';
import Checkbox from 'app/shared/Checkbox/checkbox';
import { IRootState } from 'app/shared/reducers';
import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { formatDateHHMM } from 'app/shared/util/date-utils';
import { exportToCSV, EXPORT_MODE } from 'app/shared/util/function';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useHistory } from 'react-router-dom';
import { Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { getAllDataExport, getDiscountCampaigns, updatePageActive } from './DiscountCampaign.reducer';
import './DiscountCampaign.scss';

export interface IDiscountCapaignProps extends StateProps, DispatchProps, RouteComponentProps<{}> {}

const DiscountCampaign = (props: IDiscountCapaignProps) => {
  const { discountCampaigns, match, activePage, totalItems } = props;
  const itemIds = discountCampaigns.map(discountCampaign => discountCampaign.id);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [exportMode, setExportMode] = useState<EXPORT_MODE>(EXPORT_MODE.ANY);

  const onItemSelect = itemId => {
    if (selectedIds.includes(itemId)) {
      setSelectedIds(selectedIds.filter(id => id !== itemId));
    } else {
      setSelectedIds(selectedIds.concat(itemId));
    }
  };

  const onSelectAll = () => {
    if (selectedIds.length === 0 || selectedIds.length < itemIds.length) {
      setSelectedIds(itemIds);
    } else {
      setSelectedIds([]);
    }
  };

  const getExportDataFromCampaigns = campaigns => {
    return campaigns.map(discountCampaign => {
      const initData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      discountCampaign.discount_campaign_histories.forEach(
        discountCampaignHistory =>
          (initData[Math.floor(discountCampaignHistory.campaign_history_id.time_label / 2)] += discountCampaignHistory.total_seen)
      );
      return {
        'Mã kịch bản': discountCampaign.id,
        'Tiêu đề': discountCampaign.title ? discountCampaign.title : '--',
        'Mã thông báo': discountCampaign.mobile_notification ? discountCampaign.mobile_notification.template_id : '--',
        'Trạng thái': discountCampaign.status === STATUS_CAMPAIGN.ACTIVE ? 'Đang hoạt động' : 'Ngừng hoạt động',
        'Ngày tạo': discountCampaign.created_date ? formatDateHHMM(discountCampaign.created_date.toString()) : '--',
        'Lần chỉnh sửa cuối': discountCampaign.last_modified_date ? formatDateHHMM(discountCampaign.last_modified_date.toString()) : '--',
        'Lượt đọc 0-2h': initData[0],
        'Lượt đọc 2-4h': initData[1],
        'Lượt đọc 4-6h': initData[2],
        'Lượt đọc 6-8h': initData[3],
        'Lượt đọc 8-10h': initData[4],
        'Lượt đọc 10-12h': initData[5],
        'Lượt đọc 12-14h': initData[6],
        'Lượt đọc 14-16h': initData[7],
        'Lượt đọc 16-18h': initData[8],
        'Lượt đọc 18-20h': initData[9],
        'Lượt đọc 20-22h': initData[10],
        'Lượt đọc 22-24h': initData[11]
      };
    });
  };

  const columns: IColumn[] = [
    {
      width: '22%',
      title: 'Mã kịch bản'
    },
    {
      width: '18%',
      title: 'Tiều đề'
    },
    {
      width: '10%',
      title: 'Mã thông báo'
    },
    {
      width: '15%',
      title: 'Trạng thái'
    },
    {
      width: '10%',
      title: 'Ngày tạo'
    },
    {
      width: '10%',
      title: 'Lần chỉnh sửa cuối'
    }
  ];

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const download = async () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0');
    const yyyy = today.getFullYear();
    const todayString = dd + '/' + mm + '/' + yyyy;
    let data = [];
    if (exportMode === EXPORT_MODE.ALL) {
      const allData = await getAllDataExport(totalItems);
      data = getExportDataFromCampaigns(allData);
    } else {
      data = getExportDataFromCampaigns(discountCampaigns.filter(discountCampaign => selectedIds.includes(discountCampaign.id)));
    }
    setModal(false);
    setSelectedIds([]);
    exportToCSV(data, 'count-seen-by-hour-of-scripts-' + todayString);
  };

  const onChangeExportAll = () => {
    setExportMode(EXPORT_MODE.ALL);
  };
  const onChangeExportAny = () => {
    setExportMode(EXPORT_MODE.ANY);
  };

  const actions: IBulkAction[] = [
    {
      key: 'export',
      title: 'Tải xuống báo cáo thống kê lượt đọc',
      onClick() {
        setModal(true);
        setExportMode(EXPORT_MODE.ANY);
      }
    }
  ];
  useEffect(() => {
    props.getDiscountCampaigns(activePage - 1);
    setSelectedIds([]);
    window.scrollTo(0, 0);
  }, [activePage, totalItems]);

  const history = useHistory();

  const handleClick = () => {
    history.push('discount-campaign/create/new');
  };

  const buttons: IButton[] = [
    {
      text: 'Báo cáo lượt đọc theo khung giờ',
      color: 'secondary',
      onClick() {
        setModal(true);
        setExportMode(EXPORT_MODE.ALL);
      },
      preIcon: <FontAwesomeIcon className="mr-2" icon={faDownload} size="1x" />
    },
    {
      text: '+ Thêm mới',
      color: 'primary',
      onClick: handleClick
    }
  ];

  return (
    <div className="discount-dampaign-list">
      <SWHeader title="Kịch bản thông báo" button={buttons} />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <BulkActionTable
                itemName="kịch bản"
                onChange={onSelectAll}
                itemIds={itemIds}
                colums={columns}
                className="check-box-all"
                selectedIds={selectedIds}
                actions={actions}
              />
            </thead>
            <tbody>
              {discountCampaigns.map((d, i) => (
                <tr key={i}>
                  <td className="check-box-item">
                    <Checkbox itemId={d.id} onChange={onItemSelect} checked={selectedIds.includes(d.id)} />
                  </td>
                  <td>{<Link to={`${match.url}/detail/${d.id}`}>{d.id}</Link>}</td>
                  <td>{d.title ? d.title : '--'}</td>
                  <td>{d.mobile_notification ? d.mobile_notification.template_id : '--'}</td>
                  <td>
                    {d.status === STATUS_CAMPAIGN.ACTIVE ? (
                      <div className="status-cp-active">Đang hoạt động</div>
                    ) : (
                      <div className="status-cp-closed">Ngừng hoạt động</div>
                    )}
                  </td>
                  <td>{d.created_date ? formatDateHHMM(d.created_date.toString()) : '--'}</td>
                  <td>{d.last_modified_date ? formatDateHHMM(d.last_modified_date.toString()) : '--'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {discountCampaigns.length <= 0 ? null : (
          <SWebPagination page={activePage} totalItem={totalItems} setPage={props.updatePageActive} itemPerPage={ITEMS_PER_PAGE} />
        )}
      </div>
      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle} style={{ backgroundColor: '#0088FF', border: 'none', color: 'white', paddingLeft: 30 }}>
          Tải xuống báo cáo theo lượt đọc
        </ModalHeader>
        <ModalBody style={{ border: 'none' }}>
          <Form>
            <FormGroup check>
              <Label check>
                <Input onChange={onChangeExportAll} type="radio" name="radio1" checked={exportMode === EXPORT_MODE.ALL} /> Tất cả kịch bản
              </Label>
            </FormGroup>
            {selectedIds.length > 0 && (
              <FormGroup check>
                <Label check>
                  <Input onChange={onChangeExportAny} type="radio" name="radio1" checked={exportMode === EXPORT_MODE.ANY} /> Các kịch bản
                  được chọn
                </Label>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter style={{ border: 'none' }}>
          <Button
            onClick={() => setModal(false)}
            color="secondary"
            style={{ color: 'black', backgroundColor: 'white', border: '1px solid #C4CDD5', borderRadius: 3 }}
          >
            Hủy
          </Button>
          <Button
            onClick={download}
            color="primary"
            style={{ color: 'white', backgroundColor: 'rgb(0, 136, 255)', border: 'none', borderRadius: 3 }}
          >
            Tải xuống
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
const mapStateToProps = (storeState: IRootState) => ({
  discountCampaigns: storeState.discountCampaign.discountCampaigns,
  activePage: storeState.discountCampaign.activePage,
  totalItems: storeState.discountCampaign.totalItems
});
const mapDispatchToProps = { getDiscountCampaigns, updatePageActive };
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DiscountCampaign);
