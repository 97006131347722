import React, { useEffect, useState } from 'react';
import { createTemplate, getTemplate, updateTemplate, deleteTemplate, reset } from './Template.reducer';
import { Modal,
  ModalHeader,
  ModalBody,
  ModalFooter ,Label ,Col} from 'reactstrap';
import { AvForm, AvGroup, AvField,  } from 'availity-reactstrap-validation';
import { TemplateModel } from './TemplateModel';
import ReactHtmlParser from 'react-html-parser';
import SWebButton from 'app/shared/SWebButton/SWebButton';

export  interface ILanguageFormProps {
  type : string,
  remove : any,
  listLanguage : Array<string>,
  data? : any
}

const LanguageForm= (props: ILanguageFormProps) =>{
    const [fileMessage, setFileMessage] = useState<string>(null);
    const [template , setTemplate] = useState(null);
    const [modal,setModal] = useState(false)
    
    useEffect(()=>{
      if(props.data){
        const temp = props.data.filter(f =>{
          if(props.type === "vn"){
           return f.language === "VIETNAMESE" 
          }
          if(props.type === "th"){
            return f.language === "THAI" 
           }
           if(props.type === "en"){
            return f.language === "ENGLISH" 
           }
        })
        if(temp.length > 0){
          setTemplate(temp[0]);
        }
      }
      
    },[props.data])
    
    const hanldeToggle = () =>{
      setModal(!modal)
    }

    // useEffect(() => {
    //   if (template && template.meta_fields !== null && template.meta_fields !== undefined) {
    //     setCkeditorValue(getValueMetaFields('html_content'));
    //   }
    // }, [template]);
    const getValueMetaFields = value => {
      let metaValue = '';
      if(template ==  null)
      return null;
      if (template.meta_fields !== null && template.meta_fields !== undefined) {
        template.meta_fields.forEach(data => {
          if (data.meta_key === value) {
            metaValue = data.meta_value;
          }
        });
      }
  
      return metaValue;
    };

    useEffect(() => {
      if(template){
        sessionStorage.setItem("data_ckeditor_"+props.type,getValueMetaFields("html_content"))
      }
    }, [template]);

    return(
        <div className="main-create">
        <div className="box-left">
          <div className="h5">Chi tiết nội dung</div>
          
          <AvGroup row>
            <Label sm={10}>
              Tiều đề thông báo<Label className="star">*</Label>
            </Label>
            <Col sm={12}>
              <AvField
                type="textarea"
                name={"subject_"+ props.type}
                id={"subject_"+ props.type}
                value={template ? (template.subject ? template.subject : '') : null}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Tiêu đề không được để trống không được để trống'
                  },
                  maxLength: {
                    value: 1024,
                    errorMessage: 'số ký tự phải nhỏ hơn 1024.'
                  }
                }}
              />
            </Col>
          </AvGroup>
          <AvGroup row>
            <Label sm={10} for="content">
              Nội dung<Label className="star">*</Label>
            </Label>
            <Col sm={12}>
              <AvField
                type="textarea"
                name={"content_" + props.type }
                rows={5}
                id={"content_" + props.type }
                value={template ? (template.content ? template.content : '') : null}
                validate={{
                  required: {
                    value: true,
                    errorMessage: 'Nội dung không được để trống'
                  },
                  maxLength: {
                    value: 65535,
                    errorMessage: 'số ký tự phải nhỏ hơn 65535.'
                  }
                }}
              />
            </Col>
          </AvGroup>

          <AvGroup row style={{marginBottom:"25px"}}>
            <Label for="thumb-url" sm={10}>
              Ảnh giao diện cho thông báo(jpg, jpeg, gif, png)
            </Label>
            <Col sm={12}>
              <AvField
                type="text"
                value={template ? (template.thumb_url ? template.thumb_url : '') : null}
                name={"thumb_url_" + props.type}
                id={"thumb_url_" + props.type}
                validate={{
                  maxLength: {
                    value: 255,
                    errorMessage: 'số ký tự phải nhỏ hơn 255.'
                  }
                }}
              />
              <p className="file-err">{fileMessage}</p>
            </Col>
          </AvGroup>
         {
           props.listLanguage && props.listLanguage.length > 1 &&  <p className={"delete-language delete-language_"+props.type} id={"delete-language_"+props.type} onClick={hanldeToggle}>Xóa ngôn ngữ</p>
         }
        </div>
        <div id="box-right" className="box-right">
            <div className="h5">Chi tiết màn hình</div>
            <>
              <AvGroup row>
                <Label for="content_screen" sm={10}>
                  Thông báo mở sang màn hình khác
                </Label>
                <Col sm={12}>
                  <AvField
                    type="select"
                    className="form-control select"
                    value={getValueMetaFields('content_screen_2')}
                    id={"content_screen_"+ props.type}
                    name={"content_screen_"+ props.type}
                  >
                    <option hidden></option>
                    <option value="dashboard">dashboard</option>
                    <option value="order_dashboard">order_dashboard</option>
                    <option value="add_product">add_product</option>
                    <option value="order_detail">order_detail</option>
                    <option value="orders">orders</option>
                    <option value="shipments">shipments</option>
                    <option value="reports">reports</option>
                    <option value="webview">webview</option>
                    <option value="daily_revenue_report">daily_revenue_report</option>
                    <option value="upgrade_package">upgrade_package</option>
                    <option value="vouchers">vouchers</option>
                  </AvField>
                </Col>
              </AvGroup>
              <AvGroup row>
                <Label for="url_content" sm={10}>
                  Đường dẫn của thông báo được gửi
                </Label>
                <Col sm={12}>
                  <AvField  name={"url_content_"+ props.type} value={getValueMetaFields('url_content')} id={"url_content"+ props.type} />
                </Col>
              </AvGroup>

              <AvGroup row>
                <Label for="html_content" sm={10} className="text-secondary">
                  Nội dung html
                </Label>
                <div className="w-100 mw-500">
                  <Col sm={12}>
                    <div className={"editor_"+props.type} id={"html_content_"+ props.type}>{ReactHtmlParser(getValueMetaFields('html_content'))}</div>
                  </Col>
                </div>
              </AvGroup>
            </>
        </div>
        <Modal isOpen={modal} toggle={hanldeToggle} id="modal-list-package-name">
          <ModalHeader >
            Cảnh báo
          </ModalHeader>
          <ModalBody>Bạn có chắc muốn xóa ngôn ngữ này không?</ModalBody>
          <ModalFooter className="footer-modal">
            <SWebButton minor={true} outLine onClick={hanldeToggle}>
              Hủy
            </SWebButton>
            <SWebButton onClick={(e) =>props.remove(e,props.type)} color="danger">
                Xóa
              </SWebButton>
          </ModalFooter>
        </Modal>
      </div> 
    );
}
export default LanguageForm;