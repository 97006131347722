import SWebPagination from 'app/shared/SWebPagination/sweb-pagination';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './script-fnb.scss';
import { DataTB } from '../content-fnb';
import { IDataScript } from './data-script-fnb';
import { STATUS_CAMPAIGN } from 'app/config/constants';
import scriptNotiFnb from 'app/services/script-noti-fnb';
import { formatDateTSHHMM } from 'app/shared/util/date-utils';

const dataTB: DataTB[] = [
  {
    width: '25%',
    title: 'Mã kịch bản'
  },
  {
    width: '30%',
    title: 'Tiêu đề'
  },
  {
    width: '10%',
    title: 'Mã thông báo'
  },
  {
    width: '15%',
    title: 'Trạng thái'
  },
  {
    width: '10%',
    title: 'Ngày tạo'
  },
  {
    width: '10%',
    title: 'Chỉnh sửa cuối'
  }
];

export default function ScriptFnb() {
  const [page, setPage] = useState<number>(1);
  const [listPage, setlistPage] = useState<number>(0);
  const history = useHistory();
  const [data, setdata] = useState<IDataScript[]>([]);

  useEffect(() => {
    scriptNotiFnb.getList(page).then(re => {
      setdata(re.data.campaigns);
      setlistPage(re.data.metadata.total);
    });
  }, [page]);

  const handleClick = () => {
    history.push('/admin/script-fnb/create-script-fnb');
  };
  const buttons: IButton[] = [
    {
      text: '+ Thêm kịch bản',
      color: 'primary',
      onClick: handleClick
    }
  ];
  return (
    <div id="sw-script-fnb">
      <SWHeader title="Kịch bản thông báo FnB" button={buttons} />
      <div className="main">
        <div className="table-wapper">
          <table id="sw-table">
            <thead>
              <tr>
                {dataTB.map((d, i) => (
                  <th key={i} style={{ width: d.width }}>
                    {d.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((d, i) => (
                <tr key={i}>
                  <td>
                    <Link to={`/admin/script-fnb/${d.client_id}`}>{d.client_id}</Link>
                  </td>
                  <td>{d.name ? d.name : '--'}</td>
                  <td>{d.content_id ? d.content_id : '--'}</td>
                  <td>
                    {d.active ? (
                      <div className="status-cp-active">Đã kích hoạt</div>
                    ) : (
                      <div className="status-cp-closed">Chưa kích hoạt</div>
                    )}
                  </td>
                  <td>{d.created_on ? formatDateTSHHMM(d.created_on) : '--'}</td>
                  <td>{d.modified_on ? formatDateTSHHMM(d.modified_on) : '--'}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <SWebPagination page={page} itemPerPage={20} totalItem={listPage} setPage={setPage} />
      </div>
    </div>
  );
}
