import React from 'react';
import './sw-radio.scss';

interface SWRadioProps {
  label?: string;
  onChange?: any;
  defaultChecked?: boolean;
  name?: string;
  value?: string;
  id?: string;
  disabled?: boolean;
}
export default function SWRadio(props: SWRadioProps) {
  const { label, onChange, defaultChecked, name, value, id, disabled } = props;

  return (
    <div id="sw-radio">
      <label className={`container ${disabled ? 'disabled' : ''}`}>
        {label}
        <input disabled={disabled} type="radio" checked={defaultChecked} name={name} value={value} id={id} onChange={onChange} />
        <span className="checkmark"></span>
      </label>
    </div>
  );
}
