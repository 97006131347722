import { DiscountCampaignHistory } from 'app/shared/model/DiscountCampaign.model';
import React from 'react';
import { Bar } from 'react-chartjs-2';

const VerticalBar = (props: { discountCampaignHistories: Array<DiscountCampaignHistory> }) => {
  const { discountCampaignHistories } = props;
  const initData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  discountCampaignHistories.forEach(
    discountCampaignHistory =>
      (initData[Math.floor(discountCampaignHistory.campaign_history_id.time_label / 2)] += discountCampaignHistory.total_seen)
  );
  const data = {
    labels: ['0-2h', '2-4h', '4-6h', '6-8h', '8-10h', '10-12h', '12-14h', '14-16h', '16-18h', '18-20', '20-22h', '22-24h'],
    datasets: [
      {
        label: '',
        data: initData,
        backgroundColor: ['#33A0FF'],
        borderColor: ['#33A0FF'],
        borderWidth: 0
      }
    ]
  };
  const options = {
    scales: {
      x: {
        grid: {
          display: false
        }
      },
      y: {
        grid: {
          drawBorder: false
        },
        ticks: {
          stepSize: 1
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        displayColors: false,
        backgroundColor: '#fff',
        titleColor: '#000',
        bodyColor: '#000',
        borderColor: '#E8EAEB',
        borderWidth: 1,
        titleFont: { weight: 'normal' },
        callbacks: {
          title: title => 'Khung giờ: ' + title[0].label,
          label: label => label.formattedValue + ' lượt đọc'
        }
      }
    }
  };
  return discountCampaignHistories.length ? (
    <Bar type data={data} options={options} />
  ) : (
    <div className="py-5 text-center" style={{ opacity: 0.7, userSelect: 'none' }}>
      Kịch bản chưa có lượt đọc nào nên không có biểu đồ
    </div>
  );
};

export default VerticalBar;
