import React from 'react';
import SWBreadcrumbs, { IBreadcrumbs } from '../SWBreadcrumbs/sw-breadcrumbs';
import SWebButton from '../SWebButton/SWebButton';
import './sw-header.scss';

export interface IButton {
  text: any;
  color?: 'primary' | 'danger' | 'secondary';
  onClick?: any;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  outLine?: boolean;
  onDoubleClick?: any;
  minor?: boolean;
  preIcon?: any;
}

interface SWHeaderProps {
  title: string;
  breadcrumb?: IBreadcrumbs;
  button?: IButton[];
}
export default function SWHeader(props: SWHeaderProps) {
  const { title, breadcrumb, button } = props;

  return (
    <div id="sw-header">
      {breadcrumb ? <SWBreadcrumbs data={breadcrumb} /> : null}
      <div className="header">
        <div className="title">{title}</div>
        {button ? (
          <div className="button">
            {button.map((b, i) => (
              <SWebButton
                type={b.type}
                key={i}
                color={b.color}
                onClick={b.onClick}
                disabled={b.disabled}
                outLine={b.outLine}
                onDoubleClick={b.onDoubleClick}
                minor={b.minor}
                preIcon={b.preIcon}
              >
                {b.text}
              </SWebButton>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
}
