import axios from 'axios';
class scriptNotiFnb {
  getList(page: number) {
    return axios.get(`/api/campaign_fnb?limit=20&page=${page}`);
  }
  getOne(id: string) {
    return axios.get(`/api/campaign_fnb/${id}`);
  }
  createScriptNoti(data) {
    return axios.post(`/api/campaign_fnb`, data);
  }
  updateScriptNoti(data, id: string) {
    return axios.put(`/api/campaign_fnb/${id}`, data);
  }
  sendNoti(action: string, id: string) {
    return axios.put(`/api/campaign_fnb/send/${id}/${action}`);
  }
}
export default new scriptNotiFnb();
