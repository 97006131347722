import React, { useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './user-management.scss';
import { getUsers, updateUser, getUser, deleteUser } from './user-management.reducer';
import { IRootState } from 'app/shared/reducers';
import { Button, Label, Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, Container } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvField, AvFeedback } from 'availity-reactstrap-validation';
import { IUser } from 'app/shared/model/user.model';
import Select from 'react-dropdown-select';
import { ROLES } from 'app/config/constants';
import { width } from '@fortawesome/free-solid-svg-icons/faSort';
import SWHeader, { IButton } from 'app/shared/SWHeader/sw-header';
import { IBreadcrumbs } from 'app/shared/SWBreadcrumbs/sw-breadcrumbs';

export interface IUserManagementUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ login: string }> {}

const UserManagementUpdate = (props: IUserManagementUpdateProps) => {
  const { user } = props;
  const [selected, setSelected] = useState(user.authorities);
  const [modal, setModal] = useState<boolean>(false);
  useEffect(() => {
    props.getUser(props.match.params.login);
  }, []);
  const handleOnclick = selecteds => {
    const listitem = [];
    selecteds.map((data, key) => {
      if (data.value !== 'ROLE_ADMIN') {
        listitem.push(data.value);
      }
    });
    if (user.authorities.includes('ROLE_ADMIN')) {
      listitem.push('ROLE_ADMIN');
    }
    setSelected(listitem);
  };
  const handleonModalClick = () => {
    setModal(!modal);
  };
  const handleDelete = () => {
    props.deleteUser(user.login);
    handleonModalClick();
  };
  const handleSaveUser = (event, values) => {
    const userSave: IUser = {
      id: user.id,
      login: values.login,
      first_name: values.first_name,
      last_name: values.last_name,
      activated: user.authorities.includes('ROLE_ADMIN') ? true : values.activated,
      authorities: selected,
      email: values.email,
      lang_key: null
    };
    props.updateUser(userSave);
  };

  const handleValueDefaultDropdown = () => {
    const values = [];
    ROLES.map((role: any) => {
      if (user.authorities && user.authorities.includes(role.value)) {
        values.push(role);
      }
    });
    return values;
  };
  const contentRenderer = ({ state }) => {
    return <div>{state.values.length === 0 ? 'Không quyền nào được chọn' : `${state.values.length} quyền được chọn`}</div>;
  };

  const breadcrumb: IBreadcrumbs = {
    link: '/admin/user-management',
    title: 'Quay lại danh sách người dùng'
  };

  <Button color="primary" type="submit">
    &nbsp; Lưu
  </Button>;
  const buttons: IButton[] = [
    {
      text: (
        <>
          <FontAwesomeIcon icon="save" className="mr-2" />
          Lưu
        </>
      ),
      color: 'primary',
      type: 'submit'
    }
  ];
  return (
    <div>
      <AvForm onValidSubmit={handleSaveUser}>
        <SWHeader title="Cập nhật người dùng" breadcrumb={breadcrumb} button={buttons} />
        <div className="update-create-user-box">
          <div className="form-user-create">
            <AvGroup>
              <Label for="login">Tài khoản</Label>
              <AvField disabled value={user.login} type="text" className="form-control" name="login" />
            </AvGroup>
            <AvGroup>
              <Label for="last_name">Họ</Label>
              <AvField
                value={user.last_name}
                type="text"
                className="form-control"
                name="last_name"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: 'số ký tự phải nhỏ hơn 50.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="first_name">Tên</Label>
              <AvField
                value={user.first_name}
                type="text"
                className="form-control"
                name="first_name"
                validate={{
                  maxLength: {
                    value: 50,
                    errorMessage: 'số ký tự phải nhỏ hơn 50.'
                  }
                }}
              />
            </AvGroup>
            <AvGroup>
              <Label for="email">Email</Label>
              <AvField disabled value={user.email} name="email" type="email" />
            </AvGroup>

            <AvGroup check className="mt-4 mb-3">
              <Label>
                {user.authorities && user.authorities.includes('ROLE_ADMIN') ? null : (
                  <Fragment>
                    <AvInput type="checkbox" value={user.activated} checked={user.activated} name="activated" /> Kích hoạt
                  </Fragment>
                )}
              </Label>
            </AvGroup>
            <AvGroup className="form-group">
              <Label for="authorities">Phân quyền</Label>
              <Select
                values={handleValueDefaultDropdown()}
                options={ROLES}
                multi={true}
                labelField={'value'}
                onChange={handleOnclick}
                placeholder="Chọn ít nhất một quyền"
                contentRenderer={contentRenderer}
              />
            </AvGroup>
            <div className="btn-pl"></div>
          </div>
        </div>
        <Modal isOpen={modal} toggle={handleonModalClick}>
          <ModalHeader toggle={handleonModalClick}>Xác nhận xóa</ModalHeader>
          <ModalBody>Bạn có chắc muốn xóa người dùng này không?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleDelete}>
              Xóa
            </Button>{' '}
            <Button color="secondary" onClick={handleonModalClick}>
              Hủy
            </Button>
          </ModalFooter>
        </Modal>
      </AvForm>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  user: storeState.userManagement.user,
  updateSuccess: storeState.userManagement.updateSuccess
});

const mapDispatchToProps = { getUsers, updateUser, getUser, deleteUser };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserManagementUpdate);
